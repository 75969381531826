import React, { Fragment, useContext } from "react";
import { useState, useEffect } from "react";
import { useSwipeable } from "react-swipeable";
import { useCookies } from "react-cookie";
import { useNavigate, useSearchParams } from "react-router-dom";

import { CheckLatin, CommunityLogo, DataLayerPush, DescriptionLevel, GetLocationByIP, Industries, RequestColors, RequestColors2, RequestExamples, RequestsLevel, ScreenColors, Strings } from "./elements/Data";
import { EmailAuth, EmailAuthConfirm, GoogleAuth, LinkedInAuth, NewOffer, ProfileUpdate, RefreshId } from "./Requests";
import { 
    Loading,   
    TopBar,
    FooterDesktop,
    NewAvatar,
    Edit, 
} from "./elements/Elements.jsx";
import {  
    InfoErrorPopup, 
    InfoPopup,  
    PopupBecomeAMemberMobile,  
    PopupCenter,  
} from "./elements/Popups";

import { 
    AngleRightBlack,
    AngleRightWhite2, 
    GoogleLogo,     
    LinkedInLogo,  
    OkWhite,
    PlusBlack,
    PlusCoral,
    PlusGreen,
    PlusWhite,
    TrashBlack,  
} from "./elements/Imgs";

import posthog from "posthog-js";
import Smartlook from 'smartlook-client';
import { FeedRequestCardMobile } from "./Profile/RequestCards";
import { GlobalContext } from "./elements/Contexts";

const RegistrationMobile = (props: any) => { 
    const navigate = useNavigate();
    const [ cookie ] = useCookies(['user_id_cookie']);
    const globalContext = useContext(GlobalContext);
   
    const [ searchParams, setSearchParams ] = useSearchParams();
    const [ userId, setUserId ] = useState<string>(cookie['user_id_cookie'] ?? searchParams.get("user_id")); 
    const [ regScreenColor, setScreenColor ] = useState<number | null>(null);  
    const [ invalidForm, setInvalidForm ] = useState<boolean>(false); 
    const [ sampleRequestNumber, setSampleRequestNumber ] = useState<number>(2);
    
    const [ step, setStep ] = useState<number>(Number(
        searchParams.get("reg_step") ?? (searchParams.get("new_user") === 'true' ? (searchParams.get("new_request") === 'true' ? 2 : 1) : 0)
    ));
    const [ onboardingStep, setOnboardingStep ] = useState<number>(0);
    
    const languages: string[] = [`English`]  
    const [ ipCity, setIPCity ] = useState<{city: string} | null>(null);
    const [ city, setCity ] = useState<string>(searchParams.get('city') ?? "");
    const [ email, setEmail ] = useState<string>(searchParams.get('email') ?? "");  
    const [ fname, setFName ] = useState<string>(((searchParams.get('name') ?? "") + ' ').split(' ')[0] ?? "");
    const [ lname, setLName ] = useState<string>(((searchParams.get('name') ?? "") + ' ').split(' ')[1] ?? "");
    const [ avatar_, setAvatar_ ] = useState<File | null>(null);
    const [ avatarUrl, setAvatarUrl ] = useState<string>(searchParams.get('avatar') ?? "");    
    // const [ linkedIn, setLinkedIn ] = useState<string>(searchParams.get('linkedin') ?? "");
    const [ occupation, setOccupation ] = useState<string>(searchParams.get('job') ?? "");
    const [ company, setCompany ] = useState<string>(searchParams.get('company') ?? "");
    const [ industry, setIndustry ] = useState<string[]>((searchParams.get('industry') ?? "").split(/\s{0,},\s{0,}/).filter(val => !!val));
    const [ addIndustry, setAddIndustry ] = useState<boolean>(false);
    const [ newIndustry, setNewIndustry] = useState<string>('');
    const [ description, setDescription ] = useState<string>(searchParams.get('description') ?? "");

    const [ remindCalendar, setRemindCalendar ] = useState<boolean>(true);
    const [ agreeProcessData, setAgreeProcessData ] = useState<boolean>(false);
    const [ needAgree, setNeedAgree ] = useState<boolean>(false);
    const redirect: string = (searchParams.get('redirect') ?? "");

    const [ request, setRequest ] = useState<number | null>(null);
    const [ requestDescription, setRequestDescription ] = useState<string>(``);
    // const [ requestHint, setRequestHint ] = useState<boolean>(false);

    const [ mailOkPopup, setMailOkPopup ] = useState<boolean>(false);
    const [ mailErrorPopup, setMailErrorPopup ] = useState<boolean>(false);
    const [ mailResendPopup, setMailResendPopup ] = useState<boolean>(false);
    const [ confirmEmailPopup, setConfirmEmailPopup ] = useState<boolean>(false);
    const [ becomeAMemberPopup, setBecomeAMemberPopup ] = useState<boolean>(false);

    const [ emailExist, setEmailExist ] = useState<boolean>(false);
    const [ mailbox, setMailbox ] = useState<string | null>(null);

    const [ waitRegistration, setWaitRegistration ] = useState<boolean>(false);
    const [ waitEmail, setWaitEmail ] = useState<boolean>(false);
    const [ requestCreated, setRequestCreated ] = useState<boolean>(false);

    const [ selectBackground, setSelectBackground ] = useState<boolean>(false);
    const [ backgroundType, setBackgroundType ] = useState<number>(0);

    const [ isFilled, setIsFilled ] = useState<boolean>(
        lname.length > 1 && !CheckLatin(fname) &&
        fname.length > 1 && !CheckLatin(lname) && 
        !!description && !CheckLatin(description) && 
        !!city && !CheckLatin(city) && 
        !!occupation && !CheckLatin(occupation) && 
        // !!linkedIn && !CheckLatin(linkedIn) && 
        !!company && !CheckLatin(company) &&
        !!industry?.length
    )

    const hideIndustryList = () => {
        setNewIndustry(''); 
        setAddIndustry(false);
    }
  
    const fontSize = (text: string) => {
        if (text?.length > 145) {
            return 18;
        } 
        if (text?.length > 75) {
            return 20;
        } 
        if (text?.length > 55) {
            return 22;
        } 
        return 24;
    }

    useEffect(() => {
        const input = document.querySelector(`#input-industry`) as HTMLInputElement;
        if (input && addIndustry) {
            input.focus();
        }
    }, [addIndustry])

    useEffect(() => {
        setIsFilled(
            lname.length > 1 && !CheckLatin(fname) &&
            fname.length > 1 && !CheckLatin(lname) && 
            !!description && !CheckLatin(description) && 
            !!city && !CheckLatin(city) && 
            !!occupation && !CheckLatin(occupation) && 
            // !!linkedIn && !CheckLatin(linkedIn) && 
            !!company && !CheckLatin(company) &&
            !!industry?.length
        );
    }, [
        fname, 
        lname, 
        description, 
        city, 
        occupation,
        // linkedIn, 
        industry,
        avatarUrl,
        company,
    ])

    const handlers= useSwipeable({
        onSwipedLeft: e => {
            setSampleRequestNumber(val => (val + 1) % 6);
        },
        onSwipedRight: e => {
            setSampleRequestNumber(val => (val + 5) % 6); 
        },   
    })

    async function finishRegistration() {
        setWaitRegistration(true);

        const data = {
            name: `${fname.trim()} ${lname.trim()}`,
            fname: fname.trim(),
            lname: lname.trim(),
            links: {
                email: email,
                // linkedin: linkedIn ? linkedin : null,
            },
            location: {
                city: city,
            },
            city: city,
            occupation: occupation,
            company: company,
            industry: industry,
            description: description,
            about: description,
            languages: languages,
            emails_matches_calendar_reminders: remindCalendar,
            emails_matches_calendar_reminders_on_registration: remindCalendar,
        }

        ProfileUpdate(userId ?? cookie[`user_id_cookie`], data, avatar_, () => {
            if (searchParams.get('can_help_text') && searchParams.get('can_help_request_id')) {
                NewOffer({
                    user_id: cookie[`user_id_cookie`],
                    pair_id: searchParams.get('can_help_pair_id') ?? '',
                    card_id: searchParams.get('can_help_request_id') ?? '',
                    text: searchParams.get('can_help_text') ?? "",
                })
            }
            setWaitRegistration(false);            
            if (remindCalendar) {                
                // setConfirmEmailPopup(true);
                // setStep(2);
                if (globalContext?.community) {
                    navigate("/profile/match");
                } else {
                    setBecomeAMemberPopup(true);
                }
            } else {
                // setStep(2);
                if (globalContext?.community) {
                    navigate("/profile/match");
                } else {
                    setBecomeAMemberPopup(true);
                }
            }
        }, () => {
            setWaitRegistration(false);
            setMailErrorPopup(true);
        }, () => {}, () => {}, () => {
            setWaitRegistration(false);
            RefreshId(() => {
                setMailErrorPopup(true);
            }, () => {
                setStep(0);
            })
        })
    }

    useEffect(() => {
        if (cookie['user_id_cookie'] && 
            searchParams.get("force_logout") !== 'true' && 
            searchParams.get("new_user") !== 'true') {
            navigate('/profile');
            setUserId(cookie[`user_id_cookie`]);
        }
    }, [])

    useEffect(() => {
        if (becomeAMemberPopup) {
            setSearchParams({});
        }
    }, [becomeAMemberPopup])

    useEffect(() => {
        if (avatar_) {
            setAvatarUrl(URL.createObjectURL(avatar_));
        }
    }, [avatar_])  
    
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: `instant` as ScrollBehavior,
        });         
        
        if (step !== 0) {
            RefreshId(() => {}, () => {
                setStep(0);
            })
        }

        if (step === 1) {
            DataLayerPush({'event': 'profile_fillup'});
        } 
        
        if (step === 2) {
            DataLayerPush({'event': 'request_fillup'});
        }
    }, [step])

    useEffect(() => {
        if (ipCity?.city) {
            setCity(val => !val ? ipCity.city : val)
        }
    }, [ipCity])

    useEffect(() => {
        // const imgText = document.querySelector(`#img-text-input`) as HTMLDivElement;
        // if (imgText) {
        //     imgText.textContent = requestDescription;
        // }
        const selectBgBlock = document.querySelector(`#block-select-background`) as HTMLDivElement;
        if (selectBgBlock) {
            selectBgBlock.scrollTo({
                left: selectBackground ? window.innerWidth - 76 - 56 - 48 - (9 - backgroundType) * 44 : 0,
                behavior: selectBackground ? `instant` : `instant`,
            });
        }

        // const form = document.querySelector(`#${props.request?.id ? 'existed' : 'new'}-request-form`) as HTMLFormElement;
        // if (selectBackground && form) {
        //     form.scrollTo({
        //         top: form.scrollTop + 57.2,
        //         behavior: `instant`,
        //     })
        // }
    }, [selectBackground])
    
    useEffect(() => {
        setScreenColor(Math.floor(Math.random() * 6));

        Smartlook.init('9e37423949b71c5128d4aa917a1d241c9bba305b');  
        
        GetLocationByIP(setIPCity);

        window.addEventListener("click", hideIndustryList);

        return () => {
            window.removeEventListener("click", hideIndustryList);
        }
    }, []);

    useEffect(() => {
        if (userId && (searchParams.get('email') || searchParams.get('linkedin'))) {
            Smartlook.identify(userId, {
                email: searchParams.get('email'),
                uuid: userId,
                linkedin: searchParams.get('linkedin'),
            })
            posthog.identify(userId, { 
                email: searchParams.get('email'), 
                uuid: userId,
                linkedin: searchParams.get('linkedin'),
            })
        }
    }, [userId])

    useEffect(() => {
        setInvalidForm(false);
    }, [onboardingStep])

    return (regScreenColor !== undefined && <>
        <div className={`body top`}>
            {!step && <div className={`background`}>
                <TopBar func={() => {
                    const newWindow: WindowProxy | null = window.open(
                        CommunityLogo[globalContext?.community]?.url ?? `https://osmos.social?nologin=true`, '_blank');
                    if (newWindow) {
                        newWindow.focus();
                    }
                }} toTop={false} hideSettings={true} setProfile={() => {}} hideLogIn={true} community={globalContext.community}
                className={`w-flex`} hideNotifications={true} color={!!searchParams.get('code') || true ? 'transparent' : ScreenColors[regScreenColor ?? 0][0]}/>

                <div className={`block p-3 w-flex`} data-color={!!searchParams.get('code') || true ? 'green' : ScreenColors[regScreenColor ?? 0][0]}
                    style={{marginTop: -78, paddingTop: 102, backgroundSize: "cover", backgroundPosition: "center",
                        backgroundImage: `url("${CommunityLogo[globalContext?.community]?.bg}")`,
                    }}>
                    {!!searchParams.get('code') ?
                    <div className={`w-flex`} style={{overflow: 'hidden'}}>
                        {/* <div className={`w-flex h-7 neg-mb-7 w-flex pt-2`}>
                            <div className={`w-flex neg-ml-6`}>
                                <NewAvatar size={6} type={['white', 'light-pink']} name={`C I`} className={`ml-2`}/></div>
                            <div className={`w-flex row right neg-mt-6 pt-3`}>
                                <NewAvatar size={5} type={['green', 'light-blue']} name={`B M`} className={`mr-3`}/></div>
                            <div className={`w-flex row right mt-3`}>
                                <NewAvatar size={5} type={['light-yellow', 'blue']} name={`O M`} className={`neg-mr-3 mt-7`}/></div>
                        </div> */}
                        <div className={`block b-3`} data-color={`transparent`} style={{zIndex: 1}}>
                            <p data-size={4} className={`semibold mb-1 mt-5`} style={{lineHeight: '120%'}}>
                                {`At Osmos, we believe`}<br/>{`that the right people`}<br/>{`can change your life`}
                            </p>
                            <p data-size={6}>
                                {`Let us connect you with them`}<br/>{`right now`}
                            </p>
                            <a className={`btn-circled-24 mobile p-left mt-2 row left w-7`} href={`https://osmos.social?nologin=true`} target={`_blank`}>
                                <p data-size={6}>{`Learn more`}</p>
                                <div className={`btn-symbol centered h-3 w-3`} data-color={`white`}>
                                    <img src={AngleRightWhite2} alt={`>`} className={`not-scale w-3 neg-m`}></img>
                                </div>
                            </a>
                        </div> 
                    </div> :
                    <div className={`column left bottom min-h-7`}>                      
                        {CommunityLogo[globalContext?.community]?.text ? 
                        <p data-size={5}>
                            {(CommunityLogo[globalContext?.community]?.text ?? "").split("\n")
                            .map((str: string, index: number) => <Fragment key={index}>
                                {str}<br/>
                            </Fragment>)}
                        </p> : <>
                        <p data-size={4} className={` semibold seminarrow mb-1`}>
                            {`Let`}
                            <span data-color={`blue`}>&rsquo;</span>
                            {`s meet`}
                            <span data-color={`blue`}>{`!`}</span>
                        </p>
                            
                        <p data-size={5}>
                            {`There’s a lot of new friendships ahead! Try to fill out your profile carefully so that interesting people can find you`}
                        </p>
                        <a className={`btn-circled-24 mobile p-left mt-2 row left w-7`} href={`https://osmos.social?nologin=true`} target={`_blank`}>
                            <p data-size={5}>{`Learn more`}</p>
                            <div className={`btn-symbol centered h-3 w-3`} data-color={`white`}>
                                <img src={AngleRightWhite2} alt={`>`} className={`not-scale w-3 neg-m`}></img>
                            </div>
                        </a>
                        </>}
                    </div>}
                </div>

                <div className={`w-flex px-2 pt-3 pb-5`}>
                    <div className={`block block b-2 p-3 mb-3`}>
                        <p data-size={5} className={`semibold mb-2`}>
                            {`Continue`}
                        </p>
                        {[
                            {title: `Google`, logo: GoogleLogo, onClick: () => {
                                GoogleAuth(
                                    searchParams.get('code'), 
                                    searchParams.get('community'), 
                                    searchParams.get('redirect'), 
                                    (searchParams.get('can_help') && searchParams.get('request_id')) ? {
                                        text: searchParams.get('can_help'),
                                        requestId: searchParams.get('request_id'),
                                    } : null,
                                    {
                                        200: () => {                                     
                                            DataLayerPush({'event': 'registration_start'});
                                        }, 
                                        0: () => {
                                            setMailErrorPopup(true)
                                        }, 
                                        203: () => {
                                            navigate('/notfound');
                                        }
                                    },
                                );
                            }, text: '', shown: true},
                            {title: `LinkedIn`, logo: LinkedInLogo, onClick: () => {
                                LinkedInAuth(
                                    searchParams.get('code'), 
                                    searchParams.get('community'), 
                                    searchParams.get('redirect'), 
                                    (searchParams.get('can_help') && searchParams.get('request_id')) ? {
                                        text: searchParams.get('can_help'),
                                        requestId: searchParams.get('request_id'),
                                    } : null,
                                    {
                                        200: () => {                                     
                                            DataLayerPush({'event': 'registration_start'});
                                        }, 
                                        0: () => {
                                            setMailErrorPopup(true)
                                        }, 
                                        203: () => {
                                            navigate('/notfound');
                                        }
                                    },
                                );
                            }, text: '', shown: false},
                        ].map((elem, index) => {return (
                            <button className={`btn-google mobile w-flex mt-1 row center`} key={elem.title} 
                                    data-color={`tp-black`} onClick={() => {elem.onClick()}}
                                    hidden={!elem.shown} id={`continue-with-${elem.title.toLowerCase()}-button`}>
                                <img src={elem.logo} alt={`li`}></img>
                                <p>{`Continue with ${elem.title}`}</p>
                            </button>
                        )})}
                        
                        <form className={`mt-4`} onSubmit={e => {
                                e.preventDefault();
                                if (email) {
                                    setWaitEmail(true); 
                                    setTimeout(() => { setWaitEmail(false) }, 5000);
                                    EmailAuth(
                                        email, setEmailExist, setMailbox, 
                                        searchParams.get('code'), 
                                        searchParams.get('community'), 
                                        searchParams.get('redirect'),
                                        (searchParams.get('can_help') && searchParams.get('request_id')) ? {
                                            text: searchParams.get('can_help'),
                                            requestId: searchParams.get('request_id'),
                                        } : null,
                                        {
                                            200: () => {                                     
                                                setWaitEmail(false);
                                                setMailOkPopup(true);
                                                DataLayerPush({'event': 'registration_start'});
                                            }, 
                                            0: () => {
                                                setMailErrorPopup(true);
                                            }, 
                                            203: () => {
                                                navigate('/notfound');
                                            }
                                        },
                                    )
                                } 
                            }} id={`continue-with-email-form`}>
                            <p data-size={5} className={`semibold mb-2`}>
                                {`Continue with email`}
                            </p>

                            <div className={`row input-block mobile mb-1`}>
                                <input className={`mobile`} placeholder={`Your e-mail`} type={`email`} data-size={6}
                                    value={email} onChange={e => {
                                        setEmail(e.target.value);
                                    }} required={true}></input> 
                                    <p data-size={4} className={`semibold`} style={{marginTop: '-0.1em'}}>{`@`}</p>
                            </div>
                            
                            <button className={`block btn-flex mobile w-flex row`}
                                    data-color={`black`} type={`submit`}>
                                <p data-size={6}>{`Send the magic link`}</p>
                                {waitEmail ? 
                                <Loading color={`white`} width={24} height={24}/> : 
                                <div className={`btn-symbol w-3 h-3 centered hover`} data-color={`white`}>
                                    <img className={`w-3 neg-m not-scale`} src={AngleRightWhite2} alt={`li`}></img>
                                </div>}
                            </button>
                        </form>
                    </div>
                </div>

                <FooterDesktop links={true} color={CommunityLogo[globalContext?.community]?.color ?? `green`} isMobile={true} agreement={true}/>
            </div>}

            {step === 1 && <div className={`column top pb-4 px-3`}>
                <TopBar func={() => {
                    const newWindow: WindowProxy | null = window.open(
                        CommunityLogo[globalContext?.community]?.url ?? `https://osmos.social?nologin=true`, '_blank');
                    if (newWindow) {
                        newWindow.focus();
                    }
                }} toTop={false} hideSettings={true} setProfile={() => {}} hideLogIn={true}
                className={`w-flex-p-3 neg-mx-3 mb-3`} hideNotifications={true} color={`light-gray`}/>
                {onboardingStep === 0 && 
                <form className={`${invalidForm && 'invalid-form'} w-flex column`}
                    onSubmit={async e => {
                    e.preventDefault();
                    if (agreeProcessData) {
                        if (
                            lname.length > 1 && !CheckLatin(fname) &&
                            fname.length > 1 && !CheckLatin(lname)
                        ) {
                            setOnboardingStep(val => val + 1);
                        } else {
                            const invalidInput = document.querySelector(`.invalid, .only-latin`);
                            if (invalidInput) {
                                window.scrollTo({
                                    top: invalidInput.getBoundingClientRect().top + window.scrollY - 300,
                                    behavior: 'smooth',
                                });
                            }
                        }
                    } else {
                        setNeedAgree(true);
                        const agreeProcessBtn = document.querySelector(`#agree-process-data-btn`);
                        if (agreeProcessBtn) {
                            window.scrollTo({
                                top: agreeProcessBtn.getBoundingClientRect().top + window.scrollY - 300,
                                behavior: 'smooth',
                            });
                        }
                    }
                    }} id={`registration-form`}>
                    <div className={`w-flex`}>
                        <div className={`row top w-flex nowrap mb-5`}>
                            <button className={`btn desktop row left min-w-4`} onClick={() => { 
                                setStep(0);
                            }}>
                                <img src={AngleRightBlack} alt={`>`} className={`w-3`} style={{transform: `rotate(180deg)`}}></img>
                            </button>
                            <p data-size={4} className={`center semibold seminarrow`}>
                                {globalContext.community ? "Introduce yourself" : `Introduce yourself to\u00A0Osmos people`}
                            </p>
                            <p data-size={5} data-color={`black-40-opacity`} className={`min-w-4 pt right semibold seminarrow noshrink`}>
                                {`${onboardingStep + 1}/5`}
                            </p>
                        </div>

                        <p data-size={8} className={`px semibold semibold mb-1`}>
                            {`First name`}
                        </p>
                        <input autoComplete={`off`} className={`desktop mb-3 ${fname.length < 2 ? 'in' : ''}valid ${CheckLatin(fname) ? 'only-latin' : ''}`} 
                            placeholder={`Enter your first name`} type={`text`} required={true} data-color={"white"}
                            value={fname} id={`fname-input`} onChange={e => {setFName(e.target.value)}} minLength={2} autoFocus={true}></input> 
                        <p className={`after neg-mt-3 mb-3 pt-1`} data-color={`coral`}></p>

                        <p data-size={8} className={`px semibold semibold mb-1`}>
                            {`Last name`}
                        </p>
                        <input autoComplete={`off`} className={`desktop mb-2 ${lname.length < 2 ? 'in' : ''}valid ${CheckLatin(lname) ? 'only-latin' : ''}`} 
                            placeholder={`Enter your last name`} type={`text`} required={true} data-color={"white"}
                            value={lname} id={`lname-input`} onChange={e => {setLName(e.target.value)}} minLength={2}></input> 
                        <p className={`after neg-mt-2 mb-2 pt-1`} data-color={`coral`}></p>

                        <button className={`btn-checkbox desktop row left top nowrap custom-style px-1 pt-2 pb-2`} type={`button`}
                                onClick={() => {setRemindCalendar(val => !val)}}>
                            <div className={`checkbox-radio w-3 h-3 b centered noshrink`} data-checked={remindCalendar}>
                                <img src={OkWhite} alt={`v`}></img>
                            </div>
                            <p data-size={5} data-color={`black`}>{`Notify me about my matches in my email`}</p>
                        </button>
                        
                        <button className={`btn-checkbox desktop row left top nowrap custom-style px-1 pt-2 pb-2`} type={`button`}
                                onClick={() => {setAgreeProcessData(val => !val); setNeedAgree(false);}} id={`agree-process-data-btn`}>
                            <div className={`checkbox-radio w-3 h-3 b centered noshrink`} data-checked={agreeProcessData} data-error={needAgree}>
                                <img src={OkWhite} alt={`v`}></img>
                            </div>
                            <p data-size={5} data-color={needAgree && !agreeProcessData ? `coral` :`black`} className={``} 
                                style={{whiteSpace: `normal`}} onClick={e => { e.stopPropagation() }}>
                                {`I consent to the`}&nbsp;	
                                <a href={`${window.location.origin}/privacy`}>
                                    <span data-color={`green`}>{`processing of my personal data`}</span>
                                </a>
                            </p>
                        </button>
                    </div>

                    <button className={`block btn-flex circled desktop w-flex row center mt-5`} type={`submit`} 
                            data-color={(
                                lname.length > 1 && !CheckLatin(fname) &&
                                fname.length > 1 && !CheckLatin(lname)
                            ) ? `black` : `light-gray`} onClick={() => { setInvalidForm(true) }}>
                        <p data-color={(
                                lname.length > 1 && !CheckLatin(fname) &&
                                fname.length > 1 && !CheckLatin(lname)
                            ) ?null : `white`}>{`Next`}</p>
                    </button>
                </form>}
                
                {onboardingStep === 1 && 
                <form className={`${invalidForm && 'invalid-form'} w-flex column`}
                    onSubmit={async e => {
                        e.preventDefault();
                        if (avatarUrl) {
                            setOnboardingStep(val => val + 1);
                        } else {
                            const invalidInput = document.querySelector(`.invalid, .only-latin`);
                            if (invalidInput) {
                                window.scrollTo({
                                    top: invalidInput.getBoundingClientRect().top + window.scrollY - 300,
                                    behavior: 'smooth',
                                });
                            }
                        }
                    }} id={`registration-form`}>
                    <div className={`w-flex column top`}>
                        <div className={`row top w-flex nowrap mb-5`}>
                            <button className={`btn desktop row left min-w-4`} onClick={() => { 
                                setOnboardingStep(val => val - 1);
                            }}>
                                <img src={AngleRightBlack} alt={`>`} className={`w-3`} style={{transform: `rotate(180deg)`}}></img>
                            </button>
                            <p data-size={4} className={`center semibold seminarrow`}>
                                {`Add your photo`}
                            </p>
                            <p data-size={5} data-color={`black-40-opacity`} className={`min-w-4 pt right semibold seminarrow noshrink`}>
                                {`${onboardingStep + 1}/5`}
                            </p>
                        </div>
                        
                        <div className={`row top center mb-3`} data-color={`light-gray`}
                                id={`registration-image-field`} style={{position: `relative`}}>
                            <input autoComplete={`off`} id={`input-img`} className={``} type={`file`} accept={`image/png, image/jpeg, image/gif`}
                                onChange={e => {
                                    if (e.target.files?.length) {
                                        setAvatar_(e.target.files[0]);
                                    }
                                }} name={`preview`}></input>
                            {!!avatarUrl ? <>
                                <NewAvatar size={9} avatar={avatarUrl} type={0} className={`circled`}/>
                                <div className={`block b-2 p-2 row right top appear-on-hover`} data-color={`black-10-opacity`}
                                    style={{position: `absolute`, top: 0, bottom: 0, left: 0, right: 0}}>
                                    <button className={`block btn w-4 h-4 b-1 centered`}
                                            onClick={() => { 
                                                // setAvatar_(null); 
                                                // setAvatarUrl(``);
                                                // (document.querySelector(`#input-img`) as HTMLInputElement).value = '';
                                                (document.querySelector(`#input-img`) as HTMLInputElement).click();
                                            }} type={`button`}>
                                        <Edit className={'h-3 w-3'} color={`#000000`}/>
                                    </button>  
                                </div>                                                             
                            </> : <NewAvatar size={9} avatar={avatarUrl} type={['gray', 'light-gray', 'light-gray', 'gray']} 
                                        className={`circled`} name={`${fname.trim()} ${lname.trim()}`}/>}
                        </div>
                        <p data-size={5} className={`center`}>
                            {`People prefer to build connections`}<br/>{`with those who have a photo.`}
                        </p>
                    </div>

                    <button className={`block btn-flex circled desktop w-flex row center mt-5`} type={`submit`} 
                            data-color={`black`} onClick={() => { 
                                if (avatarUrl) {
                                    setInvalidForm(true) 
                                } else {
                                    (document.querySelector(`#input-img`) as HTMLInputElement).click();
                                }
                            }}>
                        <p>{avatarUrl ? `Next` : `Add from library`}</p>
                    </button>
                </form>}
                
                {onboardingStep === 2 && 
                <form className={`${invalidForm && 'invalid-form'} w-flex column`}
                    onSubmit={async e => {
                        e.preventDefault();
                        if (description) {
                            setOnboardingStep(val => val + 1);
                        } else {
                            const invalidInput = document.querySelector(`.invalid, .only-latin`);
                            if (invalidInput) {
                                window.scrollTo({
                                    top: invalidInput.getBoundingClientRect().top + window.scrollY - 300,
                                    behavior: 'smooth',
                                });
                            }
                        }
                    }} id={`registration-form`}>
                    <div className={`w-flex`}>
                        <div className={`row top w-flex nowrap mb-5`}>
                            <button className={`btn desktop row left min-w-4`} onClick={() => { 
                                setOnboardingStep(val => val - 1);
                            }}>
                                <img src={AngleRightBlack} alt={`>`} className={`w-3`} style={{transform: `rotate(180deg)`}}></img>
                            </button>
                            <p data-size={4} className={`center semibold seminarrow`}>
                                {`Introduce yourself in a way that makes other professionals interested to connect with you.`}
                            </p>
                            <p data-size={5} data-color={`black-40-opacity`} className={`min-w-4 pt right semibold seminarrow noshrink`}>
                                {`${onboardingStep + 1}/5`}
                            </p>
                        </div>
                        
                        <textarea className={`p-2 b-1 h-8 ${!DescriptionLevel(description)?.isValid ? 'in' : ''}valid`} 
                                        placeholder={`What are you working on right now? What do you know better than anyone else? What can you help others with?`} 
                                        required={false} id={`description-input`} data-color={"white"}
                                        value={description} onChange={e => {setDescription(e.target.value)}}></textarea> 
                        <p className={`after`} data-color={`coral`}></p>
                    </div>

                    <button className={`block btn-flex circled desktop w-flex row center mt-5`} type={`submit`} 
                            data-color={`black`} onClick={() => { setInvalidForm(true) }}>
                        <p>{`Next`}</p>
                    </button>
                </form>}
                
                {onboardingStep === 3 && 
                <form className={`${invalidForm && 'invalid-form'} w-flex column`}
                    onSubmit={async e => {
                        e.preventDefault();
                        if (industry?.length) {
                            setOnboardingStep(val => val + 1);
                        } else {
                            const invalidInput = document.querySelector(`.invalid, .only-latin`);
                            if (invalidInput) {
                                window.scrollTo({
                                    top: invalidInput.getBoundingClientRect().top + window.scrollY - 300,
                                    behavior: 'smooth',
                                });
                            }
                        }
                    }} id={`registration-form`}>
                    <div className={`w-flex`}>
                        <div className={`row top w-flex nowrap mb-5`}>
                            <button className={`btn desktop row left min-w-4`} onClick={() => { 
                                setOnboardingStep(val => val - 1);
                            }}>
                                <img src={AngleRightBlack} alt={`>`} className={`w-3`} style={{transform: `rotate(180deg)`}}></img>
                            </button>
                            <p data-size={4} className={`center semibold seminarrow`}>
                                {`Choose industry you're in`}
                            </p>
                            <p data-size={5} data-color={`black-40-opacity`} className={`min-w-4 pt right semibold seminarrow noshrink`}>
                                {`${onboardingStep + 1}/5`}
                            </p>
                        </div>

                        <input  value={newIndustry} onChange={e => {
                                setNewIndustry(e.target.value);
                            }} onKeyDown={e => {
                                if (e.key === "Enter") {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (newIndustry) {
                                        setIndustry((val: any) => val.includes(newIndustry.trim()) ? val : val.concat([newIndustry.trim()]));
                                        hideIndustryList();
                                    }
                                }
                            }} className={`desktop mb-3`} placeholder={`Start typing`} data-color={"white"}
                            id={`input-industry`}></input>
                        <div className={`row left mb-3`}>
                            {((
                                Industries.filter((val: string) => val.toLowerCase().includes(newIndustry.toLocaleLowerCase().trim()))?.length &&
                                newIndustry.trim()?.length
                            ) ?
                            Industries.filter((val: string) => val.toLowerCase().includes(newIndustry.toLocaleLowerCase().trim())) : industry)
                            .map((elem: string, index: number) => 
                                <button className={`block btn-circled-32 desktop row`} 
                                    data-color={industry.includes(elem) ? 'transparent' : `white`}
                                    data-border={industry.includes(elem) ? `green-1` : null}
                                    onClick={() => {
                                        setIndustry((val: any) => 
                                            val.includes(elem) ? val.filter((val_: string) => val_ !== elem) : val.concat([elem])
                                        )
                                        const input = document.querySelector(`#input-tag`) as HTMLInputElement;
                                        if (input) {
                                            input.focus();
                                        }
                                    }} type={`button`} id={`select-btn`} key={index}>
                                <p data-size={8} data-color={industry.includes(elem) ? 'green' : `black-40-opacity`}
                                    className="ellipsis max-w-8">
                                    {elem}
                                </p>
                                {industry.includes(elem) &&
                                <img src={PlusGreen} alt={`+`} style={{transform: `rotate(45deg)`}} className={` neg-mr w-2`}></img>}
                            </button>)}
                        </div>
                    </div>

                    <button className={`block btn-flex circled desktop w-flex row center`} type={`submit`} 
                            data-color={`black`} onClick={() => { setInvalidForm(true) }} disabled={!industry?.length}>
                        <p>{`Next`}</p>
                    </button>
                </form>}

                {onboardingStep === 4 && 
                <form className={`${invalidForm && 'invalid-form'} w-flex column`}
                    onSubmit={async e => {
                        e.preventDefault();
                        if (
                            !!city && !CheckLatin(city) && 
                            !!occupation && !CheckLatin(occupation) &&  
                            !!company && !CheckLatin(company)
                        ) {
                            finishRegistration();
                        } else {
                            const invalidInput = document.querySelector(`.invalid, .only-latin`);
                            if (invalidInput) {
                                window.scrollTo({
                                    top: invalidInput.getBoundingClientRect().top + window.scrollY - 300,
                                    behavior: 'smooth',
                                });
                            }
                        }
                    }} id={`registration-form`}>
                    <div className={`w-flex`}>
                        <div className={`row top w-flex nowrap mb-5`}>
                            <button className={`btn desktop row left min-w-4`} onClick={() => { 
                                setOnboardingStep(val => val - 1);
                            }}>
                                <img src={AngleRightBlack} alt={`>`} className={`w-3`} style={{transform: `rotate(180deg)`}}></img>
                            </button>
                            <p data-size={4} className={`center semibold seminarrow`}>
                                {`Add more details`}<br/>{`in your profile`}
                            </p>
                            <p data-size={5} data-color={`black-40-opacity`} className={`min-w-4 pt right semibold seminarrow noshrink`}>
                                {`${onboardingStep + 1}/5`}
                            </p>
                        </div>

                        <p data-size={8} className={`px semibold semibold mb-1`}>
                            {`Company`}
                        </p>
                        <input autoComplete={`off`} className={`mb-3 ${!company ? 'in' : ''}valid ${CheckLatin(company) ? 'only-latin' : ''} desktop`} 
                            placeholder={`Enter the name of your company`} type={`text`} required={true} data-color={"white"}
                            value={company} id={`job-input`} onChange={e => {setCompany(e.target.value)}}></input> 
                        <p className={`after neg-mt-3 mb-3 pt-1`} data-color={`coral`}></p>

                        <p data-size={8} className={`px semibold semibold mb-1`}>
                            {`Role`}
                        </p>
                        <input autoComplete={`off`} className={`mb-3 ${!occupation ? 'in' : ''}valid ${CheckLatin(occupation) ? 'only-latin' : ''} desktop`} 
                            placeholder={`Enter your role in the company`} type={`text`} required={true} data-color={"white"}
                            value={occupation} id={`role-input`} onChange={e => {setOccupation(e.target.value)}}></input> 
                        <p className={`after neg-mt-3 mb-3 pt-1`} data-color={`coral`}></p>

                        <p data-size={8} className={`px semibold semibold mb-1`}>
                            {`Your city`}
                        </p>
                        <input autoComplete={`off`} className={`desktop mb-3 ${!city ? 'in' : ''}valid ${CheckLatin(city) ? 'only-latin' : ''}`} 
                            placeholder={`Enter the city you live in`} type={`text`} required={true} data-color={"white"}
                            value={city} id={`city-input`} onChange={e => {setCity(e.target.value)}}></input> 
                        <p className={`after neg-mt-3 mb-3 pt-1`} data-color={`coral`}></p>
                    </div>

                    <button className={`block btn-flex circled desktop w-flex row center`} type={`submit`} 
                            data-color={(
                                !!city && !CheckLatin(city) && 
                                !!occupation && !CheckLatin(occupation) &&  
                                !!company && !CheckLatin(company)
                            ) ? `black` : `light-gray`} onClick={() => { setInvalidForm(true) }}>
                        {waitRegistration ? 
                        <Loading color={`white`} width={24} height={24}/> :
                        <p data-color={(
                                !!city && !CheckLatin(city) && 
                                !!occupation && !CheckLatin(occupation) &&  
                                !!company && !CheckLatin(company)
                            ) ? null : `white`}>{`Next`}</p>}
                    </button>
                </form>}

                <FooterDesktop links={true} agreement={true}/>
            </div>}

            {step === 100 && <>            
            <TopBar func={() => {
                const newWindow: WindowProxy | null = window.open(
                    CommunityLogo[globalContext?.community]?.url ?? `https://osmos.social?nologin=true`, '_blank');
                if (newWindow) {
                    newWindow.focus();
                }
            }} toTop={false} hideSettings={true} setProfile={() => {}} hideLogIn={true}
            className={`w-flex mb-3`} hideNotifications={true} color={`light-gray`}/>

            <form className={`background ${invalidForm && 'invalid-form'}`}
                    onSubmit={e => {
                    e.preventDefault();
                    if (agreeProcessData) {
                        if (isFilled) {
                            finishRegistration();
                        } else {
                            const invalidInput = document.querySelector(`.invalid`);
                            if (invalidInput) {
                                window.scrollTo({
                                    top: invalidInput.getBoundingClientRect().top + window.scrollY - 300,
                                    behavior: 'smooth',
                                });
                            }
                        }
                    } else {
                        setNeedAgree(true);
                        const agreeProcessBtn = document.querySelector(`#agree-process-data-btn`);
                        if (agreeProcessBtn) {
                            window.scrollTo({
                                top: agreeProcessBtn.getBoundingClientRect().top + window.scrollY - 300,
                                behavior: 'smooth',
                            });
                        }
                    }
                    }} id={`registration-form`}>    

                <div className={`w-flex px-3`}>
                    <p data-size={4} className={`semibold seminarrow px-1 mb-2`}>
                        {globalContext.community ? "Introduce yourself" : <>
                        {`Introduce yourself`}<br/>{`to Osmos people`}</>}
                    </p>

                    <div className={`block mb-2 b-3 p-3`}>
                        <p data-size={5} className={`semibold mb-2 px`}>
                            {`First name*`}
                        </p>
                        <input className={`mobile mb-3 ${fname.length < 2 ? 'in' : ''}valid ${CheckLatin(fname) ? 'only-latin' : ''}`} placeholder={`Enter your first name`} type={`text`} required={true} data-size={6}
                            value={fname} onChange={e => {setFName(e.target.value)}} minLength={2} id={`fname-input`}></input> 
                        <p className={`after neg-mt-3 mb-3 pt px`} data-color={`coral`}></p>

                        <p data-size={5} className={`semibold mb-2 px`}>
                            {`Last name*`}
                        </p>
                        <input className={`mobile mb-3 ${lname.length < 2 ? 'in' : ''}valid ${CheckLatin(lname) ? 'only-latin' : ''}`} placeholder={`Enter your last name`} type={`text`} required={true} data-size={6}
                            value={lname} onChange={e => {setLName(e.target.value)}} minLength={2} id={`lname-input`}></input> 
                        <p className={`after neg-mt-3 mb-3 pt px`} data-color={`coral`}></p>

                        {/* <p data-size={5} className={`semibold mb-2 px`}>
                            {`LinkedIn*`}
                        </p>
                        <div className={`row input-block mobile ${!linkedIn ? 'in' : ''}valid`}>
                            <input className={`mobile`} placeholder={`Enter your profile link`} type={`text`} data-size={6} id={`linkedin-input`}
                                value={linkedIn} onChange={e => {setLinkedIn(e.target.value)}}></input> 
                            <img className={`w-3`} src={LinkedInLogo} alt={`li`}></img>
                        </div>
                        <p className={`after pt px`} data-color={`coral`}>{`This is a required field`}</p> */}
                        
                        <p data-size={5} className={`semibold mb-2 px`}>
                            {`Industry*`}
                        </p>
                        <div className={`block row left ${!industry?.length ? 'in' : ''}valid p-1 b-${(addIndustry || industry?.length === 0) ? 1 : 3}`} 
                            data-color={`light-gray`} onClick={e => { e.stopPropagation() }} style={{overflow: `hidden`}}>
                            {(addIndustry || industry?.length === 0) &&                    
                            <input  onChange={e => {
                                        setNewIndustry(e.target.value);
                                    }} onKeyDown={e => {
                                        if (e.key === "Enter") {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            if (newIndustry) {
                                                setIndustry((val: any[]) => val.includes(newIndustry.trim()) ? val : val.concat([newIndustry.trim()]));
                                                hideIndustryList();
                                            }
                                        }
                                    }} onFocus={e => {
                                        setAddIndustry(true);
                                    }} className={`block w-flex`} placeholder={`Enter an industry you work in and press Enter`}
                                    style={{
                                        lineHeight: `32px`, paddingLeft: 6, paddingRight: 6,
                                    }} data-color={`transparent`} id={`input-industry`} data-size={6}></input>}
                            {(industry ?? []).map((elem: string, index: number) => {return (
                                <button className={`block btn-circled-32 mobile p-left row noshrink`} onClick={() => {
                                    setIndustry((val: any[]) => val.filter((val_: string) => val_ !== elem));
                                }} data-color={`black`} key={index} type={`button`}>
                                    <div className={`btn-symbol close w-3 h-3 centered`} data-color={`white`}>
                                        <img src={PlusWhite} alt={`+`}></img>
                                    </div>
                                    <p>{elem}</p>
                                </button>
                            )})}
                            {!addIndustry ? 
                            <button className={`btn-circled-32 mobile p-left row left noscroll nowrap`} onClick={() => {
                                setAddIndustry(true);
                            }} hidden={industry?.length === 0}>
                                <div className={`btn-symbol w-3 h-3 centered`} data-color={!industry?.length ? `coral` : 'black'}>
                                    <img src={!industry?.length ? PlusCoral : PlusBlack} alt={`+`}></img>
                                </div>
                                <p data-color={!industry?.length ? `coral` : 'dark-gray'}>{'Add'}</p>
                            </button> :
                            <div className={`block w-flex max-h-7 neg-mb-1 y-auto`} data-color={`light-gray`} style={{
                                paddingLeft: 6, paddingRight: 6,
                            }}>
                                {Industries.filter((val: string) => val.toLowerCase().includes(newIndustry.toLocaleLowerCase().trim()))
                                .map((elem: string, index: number) => {return (
                                    <button className={`btn mobile row ${!index ? `my-2` : `mb-2`}`} 
                                            onClick={() => {
                                                // setNewIndustry("");
                                                setIndustry((val: any) => 
                                                    val.includes(elem) ? val.filter((val_: string) => val_ !== elem) : val.concat([elem])
                                                )
                                                // setIndustryAutoFill(false);
                                                // setAddIndustry(false);
                                                const input = document.querySelector(`#input-industry`) as HTMLInputElement;
                                                if (input) {
                                                    input.focus();
                                                }
                                            }} type={`button`} id={`select-btn`} key={index}>
                                        <p data-size={8} className={industry.includes(elem) ? `semibold` : ``}>{elem}</p>
                                    </button>
                                )})}
                            </div>}
                        </div>
                        <p className={`after pt px`} data-color={`coral`}></p>

                        <div className={`row w-flex left mt-2`}>
                            {[
                                `AI`, `Vc`, `DeepTech`, `E-commerce`, `Education`, `EnergyTech`, `FinTech`,  
                                `Future of Work`, `Games`, `MarketingTech`, `Media/Content`, 
                                `Real Estate/PropTech`, `Sales/CRM`, `Social Networks`, `Web3`,
                            ].map((elem: string, index: number) => {return (
                                <button className={`block btn-circled-32 mobile`} key={index}
                                        data-color={industry.includes(elem) ? `green` : `light-gray`} 
                                        onClick={() => {
                                            setIndustry(val => val.includes(elem) ? val.filter(ind => ind !== elem) : val.concat([elem]))
                                        }} type={`button`}>
                                    <p data-color={industry.includes(elem) ? null : `black-40-opacity`}>{elem}</p>
                                </button>
                            )})}
                        </div>

                        <button className={`btn-checkbox mobile row left top nowrap custom-style mt-3 p`}
                            onClick={() => { setRemindCalendar(val => !val) }} type={`button`}>
                            <div className={`checkbox-radio w-3 h-3 b centered noshrink`} data-checked={remindCalendar}>
                                <img src={OkWhite} alt={`v`}></img>
                            </div>
                            <p data-size={6} data-color={`black`} className={``} style={{marginTop: 2}}>
                                {`Notify me about my matches in my email`}
                            </p>
                        </button>

                        <button className={`btn-checkbox mobile row left top nowrap custom-style p mt`}
                            onClick={() => {setAgreeProcessData(val => !val); setNeedAgree(false);}} type={`button`} id={`agree-process-data-btn`}>
                            <div className={`checkbox-radio w-3 h-3 b centered noshrink`} data-checked={agreeProcessData} data-error={needAgree}>
                                <img src={OkWhite} alt={`v`}></img>
                            </div>
                            <p data-size={6} data-color={needAgree && !agreeProcessData ? `coral` :`black`} className={``} 
                               style={{marginTop: 2, whiteSpace: `normal`}} onClick={e => { e.stopPropagation() }}>
                                {`I consent to the`}&nbsp;	
                                <a href={`${window.location.origin}/privacy`}>
                                    <span data-color={`green`}>{`processing of my personal data`}</span>
                                </a>
                            </p>
                        </button>
                    </div>

                    <div className={`block mb-2 b-3 p-3`}>
                        <p data-size={5} className={`semibold mb-2 px`}>
                            {`Company*`}
                        </p>
                        <input className={`mobile mb-3 ${!company ? 'in' : ''}valid ${CheckLatin(company) ? 'only-latin' : ''}`} placeholder={`Enter the name of your company`} type={`text`} required={true} data-size={6}
                            value={company} id={`job-input`} onChange={e => {setCompany(e.target.value)}}></input> 
                        <p className={`after neg-mt-3 mb-3 pt px`} data-color={`coral`}></p>

                        <p data-size={5} className={`semibold mb-2 px`}>
                            {`Role*`}
                        </p>
                        <input className={`mobile mb-3 ${!occupation ? 'in' : ''}valid ${CheckLatin(occupation) ? 'only-latin' : ''}`} placeholder={`Enter your role in the company`} type={`text`} required={true} data-size={6}
                            value={occupation} id={`role-input`} onChange={e => {setOccupation(e.target.value)}}></input> 
                        <p className={`after neg-mt-3 mb-3 pt px`} data-color={`coral`}></p>
                        
                        <p data-size={5} className={`semibold mb-2 px`}>
                            {`Your city*`}
                        </p>
                        <input className={`mobile mb-3 ${!city ? 'in' : ''}valid ${CheckLatin(city) ? 'only-latin' : ''}`} id={`city-input`} placeholder={`Enter the city you live in`} type={`text`} required={true} data-size={6}
                            value={city} onChange={e => {setCity(e.target.value)}}></input> 
                        <p className={`after neg-mt-3 mb-3 pt px`} data-color={`coral`}></p>

                        <p data-size={5} className={`semibold mb-2 px`}>{`Describe yourself and your work to get the attention of people you're looking for*`}</p>
                        <textarea className={`p-2 b-1 h-7 ${!DescriptionLevel(description)?.isValid ? 'in' : ''}valid`} data-size={6} 
                                placeholder={`What are you working on right now? What do you know better than anyone else? What can you help others with?`} 
                                required={false} id={`description-input`}
                                value={description} onChange={e => {setDescription(e.target.value)}}></textarea>  
                        {/* <div className={`block h w-flex mt b row left`} data-color={`light-gray`}>
                            <div className={`h-flex b`} style={DescriptionLevel(description)?.style}></div>
                        </div> */}
                        {(DescriptionLevel(description)?.isText && !DescriptionLevel(description)?.isValid) ?
                        <p data-size={6}
                            data-color={!DescriptionLevel(description)?.isValid ? `coral` : `black-40-opacity`}>
                            {DescriptionLevel(description)?.text}
                        </p> :
                        <p className={`after neg-mt pt px`} data-color={`coral`}></p>}
                    </div>

                    <div className={`block block p-3 mb-3 b-3 w-flex`} hidden={true}>
                        <p className={`text-5 semibold mb-2 px`}>
                            {`Your photo`}
                        </p>
                        <div className={`row top nowrap nogap block b-2 w-flex`} data-color={`light-gray`} style={{
                            height: `auto`, aspectRatio: 1,
                        }} id={`registration-image-field`}>
                            <input id={`input-img`} className={``} type={`file`} accept={`image/png, image/jpeg, image/gif`}
                                onChange={e => {
                                    if (e.target.files?.length) {
                                        setAvatar_(e.target.files[0]);
                                    }
                                }} name={`preview`}></input>
                            {!!avatarUrl ? <>
                                <div className={`w-flex h-flex b-2`} style={{
                                        backgroundImage: `url(${avatarUrl})`,
                                    }}></div> 
                                <button className={`block btn mobile neg-ml-5 mt-2 mr-2 w-4 h-4 b-1 centered`}
                                        onClick={() => { 
                                            setAvatar_(null);
                                            setAvatarUrl(``);
                                            (document.querySelector(`#input-img`) as HTMLInputElement).value = '';
                                        }} type={`button`}>
                                    <img src={TrashBlack} alt={``} className={`w-3 h-3`}></img>
                                </button>                                                               
                            </> :
                            <div className={`w-flex h-flex centered`}>
                                <button className={`block btn-flex mobile centered`} data-color={`gray`} type={`button`}
                                        onClick={() => { (document.querySelector(`#input-img`) as HTMLInputElement).click() }}>
                                    <p>{`Upload your photo`}</p>
                                </button>
                            </div>}
                        </div> 
                    </div>
                </div>
                 
                <div className={`sticky-bottom-`}>
                    <div className={`row visible pb-3 px-3 nogap`}>
                        <div></div>

                        <button className={`block btn-flex mobile w-flex row center`} type={`submit`} 
                                data-color={isFilled ? `black` : 'gray'} 
                            onClick={() => { 
                                setInvalidForm(true);
                            }}>
                            {waitRegistration ? 
                            <Loading color={`white`} width={24} height={24}/> : 
                            <p data-color={isFilled ? null : `white`}>{`Create profile`}</p>}
                        </button>
                    </div>
                </div>
            </form>
            </>}

            {!(step - 2) && <>            
            <TopBar func={() => {
                        const newWindow: WindowProxy | null = window.open(
                            CommunityLogo[globalContext?.community]?.url ?? `https://osmos.social?nologin=true`, '_blank');
                        if (newWindow) {
                            newWindow.focus();
                        }
                    }} toTop={false} hideSettings={true} setProfile={() => {}} hideLogIn={true}
                            className={`w-flex mb-3`} hideNotifications={true} color={`light-gray`}/>
            <p data-size={4} className={`mb-4 px-4 semibold center`}>{`Check out queries from other professionals `}</p>

            <div className={`row top left w-flex gap-2 nowrap noshrink px-3 pb-5`} style={{
                overflow: `hidden`,
            }} {...handlers}>                
                {RequestExamples.map((elem, index) => {return (
                    <div className={`w-flex b-2`} style={{
                        transition: 'margin 0.5s ease-in-out',
                        marginLeft: !index ? `calc(${-sampleRequestNumber * 100}% + ${-sampleRequestNumber * 14}px)` : 0,
                        boxShadow: `0px 11px 53px 0px #00000014`,
                    }} key={index}>                        
                        <FeedRequestCardMobile request={elem} key={index} onboardingV={true}/>
                    </div>
                )})}
            </div>

            <div className={`w-flex centered mb-5 py-1`} onClick={e => {
                    window.scroll({
                        top: (e.target as HTMLDivElement).getBoundingClientRect().bottom + window.scrollY,
                        behavior: `smooth`,
                    })
                }}>
                <img src={AngleRightBlack} style={{
                    height: 64, width: 64, opacity: 0.15,
                }} alt={``} className={`angle-moving`}></img>
            </div>

            <p data-size={4} className={`mb-4 px-4 semibold center`}>{`Get inspired and create your first request`}</p>
            <form className={`px-3 w-flex`} onSubmit={e => {
                e.preventDefault();
                (e.target as Element).classList.add(`invalid-form`);

                if (RequestsLevel(((e.target as HTMLFormElement).elements.namedItem(`answer`) as HTMLTextAreaElement)?.value)?.isValid) {
                    const data = {
                        requests: [{
                            title: Strings().requests[request ?? 0].title,
                            answers: [
                                ((e.target as HTMLFormElement).elements.namedItem(`answer`) as HTMLTextAreaElement)?.value,
                                '',
                                '',
                            ],
                            background_type: selectBackground ? backgroundType : null,
                        }]
                    }

                    if (!requestCreated) {
                        setWaitRegistration(true);
                
                        ProfileUpdate(userId ?? cookie[`user_id_cookie`], data, null, () => {
                            setWaitRegistration(false);
                            DataLayerPush({'event': 'complete_registration'});
                            setRequestCreated(true);
                            if (globalContext?.community) {
                                navigate("/profile/match");
                            } else {
                                setBecomeAMemberPopup(true);
                            }
                        }, () => {
                            setWaitRegistration(false);
                        }, () => {}, () => {}, () => {
                            setWaitRegistration(false);
                            setMailErrorPopup(true);
                        })
                    } else {
                        if (globalContext?.community) {
                            navigate("/profile/match");
                        } else {
                            setBecomeAMemberPopup(true);
                        }
                    }
                }
            }} id={`new-request-form`}>    
                <div className={`block p-3 b-3 mb-3`}>       
                    <p data-size={5} className={`semibold seminarrow mb-3`}>{`Choose one tag that best describes your expectations for the meeting`}</p>
                    
                    <div className={`row left mb-3`}>
                        {[
                            `share experience`,
                            `find a job`,
                            `hire`,
                            `find clients`,
                            `raise funds`,
                            `invest`,
                            `find experts`,
                            `find partners`,
                            `network`,
                        ].map((elem, index) => {return (
                            <button className={`block btn-circled-32 mobile`} key={index} data-color={(index === request) ? `green` : `light-gray`} 
                                    type={`button`} onClick={() => { setRequest(index) }} hidden={(/::hidden/).test(elem)}>
                                <p data-color={(index === request) ? null : `black-40-opacity`}>{elem}</p>
                            </button>
                        )})}
                    </div>

                    <p data-size={5} className={`semibold px mb-1`}>{`Describe your request`}</p>
                    <p data-size={6} className={`px mb-2`} data-color={`black-40-opacity`}>
                        {`What kind of person would you like to meet for your business? The more specific the request, the better your matches will be.`}
                    </p>


                    <div className={`block w-flex mt-2`} style={{
                        position: `relative`, height: selectBackground ? 284 : 164, flexDirection: `column`,
                    }}>
                        <textarea className={`p-2 mobile h-flex valid ${selectBackground ? "b-3" : "b-1"}`} data-size={6} placeholder={Strings().requests[request ?? 0]?.placeholder}
                                    name={`answer`} onChange={e => {
                                    // setRequestHint(e.target.value?.length < 50);
                                    setRequestDescription(e.target.value);
                                    if (!RequestsLevel(e.target.value)?.isValid) {
                                        e.target.classList.replace(`valid`, `invalid`);
                                    } else {
                                        e.target.classList.replace(`invalid`, `valid`);
                                    }
                                }} value={requestDescription} style={{
                                    wordBreak: `break-word`,
                                    ...(selectBackground ? {
                                        fontSize: fontSize(requestDescription), 
                                        fontWeight: 600, lineHeight: `120%`,
                                    } : {
                                        paddingTop: 14, marginBottom: -28,
                                        fontSize: 14, lineHeight: `140%`,
                                    }),
                                }} data-color={selectBackground ? RequestColors2[backgroundType]?.color : `light-gray`}
                                data-text-color={selectBackground ? RequestColors2[backgroundType]?.textColor : `black`}></textarea>
                    
                        <div className={`block row nowrap noshrink gap-0`} style={{
                            position: `absolute`, 
                            right: 14, bottom: 14, 
                            maxWidth: selectBackground ? `calc(100% - 28px)` : 40,
                            transition: `max-width 0.2s ease-in-out, padding 0.2s ease-in-out`,
                            borderRadius: selectBackground ? 11.5 : 5.5, 
                            padding: selectBackground ? 8 : 2,
                            backgroundColor: `lin`, 
                            overflow: selectBackground ? `auto` : `hidden`,
                            flexDirection: `row-reverse`, boxShadow: `0px 1px 3px 0px #00000024`
                        }} id={`block-select-background`}>
                            <button style={{width: 36, height: 36, borderRadius: 3.5}} 
                            className={`centered block`} onClick={() => { 
                                setSelectBackground(val => !val);
                            }} type={`button`} data-color={selectBackground ? `light-gray` : (requestDescription?.length > 220 ? `gray` : `green`)} 
                            disabled={requestDescription?.length > 220}>
                                <p data-size={5} data-color={selectBackground ? `black` : `white`} className={`semibold seminarrow`}>{`Aa`}</p>
                            </button>
                            <div className={`row left noshrink nogap neg-my neg-ml`} style={{flexDirection: `row`}}>
                                {RequestColors.map((elem: any, index: number) => {return (
                                    <div className={`block`} style={{padding: 2, borderRadius: 7.5}} key={index}
                                            data-color={index === backgroundType ? 'black-40-opacity' : null}>
                                        <div className={`block`} style={{padding: 2, borderRadius: 5.5}}>
                                            <button className={`row block`} type={`button`} style={{
                                                // background: `linear-gradient(${elem.gradient.join(", ")})`,
                                                width: 36, height: 36, borderRadius: 3.5,
                                            }} onClick={() => { setBackgroundType(index) }} data-color={elem[0]}></button>
                                        </div>
                                    </div>
                                )})}
                            </div>
                        </div>
                    </div>
                    {/* <div className={`block h w-flex mt b row left`} data-color={`light-gray`}>
                        <div className={`h-flex b`} style={{...RequestsLevel(requestDescription)?.style}}></div>
                    </div> */}
                    {RequestsLevel(requestDescription)?.isText &&
                    <p data-size={6} className={`mt`}
                        data-color={!RequestsLevel(requestDescription)?.isValid ? `coral` : `black-40-opacity`}>
                        {RequestsLevel(requestDescription)?.text}
                    </p>}
                </div>                

                <button className={`block btn-flex mobile w-flex mb-3`} data-color={`black`} type={`submit`}
                        disabled={request === null || !RequestsLevel(requestDescription)?.isValid}>
                    <p className={`center`}>{`Create my first request`}</p>
                </button>
                {/* <button className={`block btn-flex mobile w-flex mb-3`} data-color={`transparent`} style={{paddingLeft: 0, paddingRight: 0,}}
                        type={`button`} onClick={() => { setBecomeAMemberPopup(true) }}>
                    <p className={`center`} data-color={`blue`}>{`I don't have a request yet, show me Osmos`}</p>
                </button> */}
            </form>
            </>}
        </div>

        {mailOkPopup &&
        <InfoPopup show={mailOkPopup} setShow={setMailOkPopup}
                   title={`Check your email. We sent the magic link for authorization`}
                   buttonTitle={mailbox ? `Open Mailbox` : `Ok, I got it!`} onOk={() => {
                        if (mailbox) {
                            window.location.href = mailbox;
                        } else {
                            EmailAuthConfirm(email, setUserId, 0, () => {
                                    setMailOkPopup(false);
                                    if (emailExist) {
                                        navigate('/profile');
                                    } else {
                                        setStep(1);
                                    }
                                }, () => {                                            
                                    setMailOkPopup(false);
                                }, () => {
                                    setMailResendPopup(true);
                                }
                            );
                        }
                   }} mobile={true}/>}

        {mailResendPopup &&        
        <InfoPopup show={mailResendPopup} setShow={setMailResendPopup}
                   title={`Mail authorization time is out`}
                   buttonTitle={`Send another link`} onOk={() => {
                        EmailAuth(
                            email, setEmailExist, setMailbox, 
                            searchParams.get('code'), 
                            searchParams.get('community'), 
                            searchParams.get('redirect'), 
                            (searchParams.get('can_help') && searchParams.get('request_id')) ? {
                                text: searchParams.get('can_help'),
                                requestId: searchParams.get('request_id'),
                            } : null,
                            {
                                200: () => {                                     
                                    setMailOkPopup(true);
                                setMailResendPopup(false);
                                }, 
                                0: () => {
                                    setMailErrorPopup(true);
                                    setMailResendPopup(false);
                                },
                            },
                        )
                   }} mobile={true}/>}

        {mailErrorPopup &&
        <InfoErrorPopup show={mailErrorPopup} setShow={setMailErrorPopup}
                        title={`Something went wrong`}
                        buttonTitle={`Please, try again`} onOk={() => {
                            setMailErrorPopup(false);
                            setStep(0);
                        }} mobile={true}/>}

        {confirmEmailPopup &&
        <PopupCenter show={confirmEmailPopup} setShow={setConfirmEmailPopup} mobile={true} mailbox={mailbox} onClose={() => { setStep(2) }}/>}        

        {becomeAMemberPopup &&
        <PopupBecomeAMemberMobile show={becomeAMemberPopup} setShow={setBecomeAMemberPopup} redirect={redirect}/>}
    </>)
}

export default RegistrationMobile;