import React, { HTMLAttributes, useContext, useEffect, useState } from "react";
import { 
    NewAvatar, 
    AnimatedLogo,
    PreloaderLogo, 
} from "./elements/Elements.jsx";
import { Industries, SetDict, Sticky } from "./elements/Data";
import { 
    LensBlack, 
    LinkedInBlack, 
    LinkedInWhite, 
    OkWhite, 
    PlusBlack, 
    SettingsBlack, 
    XBlack, 
    XWhite 
} from "./elements/Imgs";
import { SearchRequest } from "./Requests";
import { FeedRequestCardDesktop, FeedRequestCardMobile } from "./Profile/RequestCards";
import { GlobalContext } from "./elements/Contexts";
import { useCookies } from "react-cookie";
import { useSearchParams } from "react-router-dom";

export const LandingDesktop = (props: any) => {
    const globalContext = useContext(GlobalContext);
    const [ cookie ] = useCookies([`user_id_cookie`]);
    const [ searchParams ] = useSearchParams();

    const [ page, setPage ] = useState<number>(0)
    const [ searchTag, setSearchTag ] = useState<string>(``);
    const [ openRequestTypes, setOpenRequestTypes ] = useState<boolean>(false);
    const [ filterByRequest, setFilterByRequest ] = useState<number[]>([]);
    const [ filterByIndustry, setFilterByIndustry ] = useState<string[]>([]);
    const [ filterOpenIndustryList, setFilterOpenIndustryList ] = useState<boolean>(false);

    const [ timer, setTimer ] = useState<NodeJS.Timeout>();
    const [ searchData, setSearchData ] = useState<any>();

    const [ preloader, setPreloader ] = useState<boolean>(true);

    useEffect(() => {
        setFilterOpenIndustryList(
            Industries.filter((val: string) => val.toLowerCase().includes(searchTag.toLocaleLowerCase().trim()))?.length > 0 &&
            searchTag?.length > 0
        )
    }, [searchTag])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: `instant`,
        })
    }, [page])

    const random = (min: number, max: number) => {
        return Math.floor(Math.random() * Math.abs(min - max) + Math.min(max, min))
    }

    const avatarStyles = [
        [`green`, `white`, `white`, `green`], [`white`, `green`, `green`, `white`], 
        [`blue`, `white`, `white`, `blue`], [`white`, `blue`, `blue`, `white`], 
        [`bright-pink`, `white`, `white`, `bright-pink`], [`white`, `bright-pink`, `bright-pink`, `white`,], 
        [`yellow`, `white`, `white`, `yellow`], [`white`, `yellow`, `yellow`, `white`], 
        [`pink`, `white`, `white`, `pink`], [`white`, `pink`, `pink`, `white`], 
        [`bright-blue`, `white`, `white`, `bright-blue`], [`white`, `bright-blue`, `bright-blue`, `white`], 
        [`black-40-opacity`, `white`, `white`, `black-40-opacity`], [`white`, `black-40-opacity`, `black-40-opacity`, `white`], 
        [`coral`, `white`, `white`, `coral`], [`white`, `coral`, `coral`, `white`], 
    ]

    // const hiddenPositions = [
    //     {top: `-50vw`, left: `25vw`},
    //     {top: `-50vw`, left: `50vw`},
    //     {top: `-50vw`, left: `0vw`},
    //     {top: `10vw`, left: `150vw`},
    //     {top: `-50vw`, left: `80vw`},
    //     {top: `30vw`, left: `150vw`},
    //     {top: `-50vw`, left: `25vw`},
    //     {top: `5vw`, left: `150vw`},
    // ]

    const iconStyles = [
        {width: 94, borderRadius: 19, fontSize: 40},
        {width: 78, borderRadius: 16, fontSize: 33},
        {width: 58, borderRadius: 12, fontSize: 24},
        {width: 48, borderRadius: 10, fontSize: 20},
        {width: 40, borderRadius: 8, fontSize: 17},
    ]

    const [ animation1, setAnimation1 ] = useState<any[]>([]);

    const [ animation1_2, setAnimation1_2 ] = useState<any[]>([]);

    const [ animation2, setAnimation2 ] = useState<any[]>([]);

    const [ colorTopBar, setColorTopBar ] = useState<boolean>(false);
    const [ scroll, setScroll ] = useState<number>(window.scrollY);
    const [ scroll1, setScroll1 ] = useState<number>(window.scrollY);
    const [ scroll2, setScroll2 ] = useState<number>(window.scrollY);
    const [ scroll3, setScroll3 ] = useState<number>(window.scrollY);

    const onScroll = () => {
        const whiteBlock = document.querySelector("#white-block") as HTMLDivElement;
        if (whiteBlock && whiteBlock.getBoundingClientRect().y < 100) {
            setColorTopBar(true)
        } else {
            setColorTopBar(false)
        }
        setScroll(window.scrollY);

        const videoAnimation1 = document.querySelector(`#video-animation-1`) as HTMLDivElement; 
        if (videoAnimation1) {
            setScroll1(window.innerHeight - videoAnimation1.getBoundingClientRect().bottom);
        }
        const videoAnimation2 = document.querySelector(`#video-animation-2`) as HTMLDivElement; 
        if (videoAnimation2) {
            setScroll2(window.innerHeight - videoAnimation2.getBoundingClientRect().bottom);
        }
        const videoAnimation3 = document.querySelector(`#video-animation-3`) as HTMLDivElement; 
        if (videoAnimation3) {
            setScroll3(window.innerHeight - videoAnimation3.getBoundingClientRect().bottom);
        }
    }

    const setDefaultSearchData = () => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}top_requests`, {
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors' as RequestMode,
            method: 'GET',
        }).then(res => {
            if (res.status === 200) {
                res.json().then(data => {
                    setSearchData({
                        user_requests: (data?.user_requests ?? []).map((elem: any) => {return (
                            SetDict(
                                elem,
                                ["card", "style", "background"],
                                elem?.card?.answers[0]?.length > 220 ? elem?.card?.style?.background : random(0, elem?.card?.answers[0]?.length) % 9
                            )
                        )})
                    })
                })
            }
        })
    }

    const merge = (a: any[], b: any[]) => {
        return b.reduce((array: any, next: any) => {
            if (!array.includes(next)) {
                return array.concat([next]);
            } else {
                return array;
            }
        }, a)
    }

    useEffect(() => {    
        document.querySelectorAll("video").forEach((elem: HTMLVideoElement) => {
            elem.play().catch(() => {});
        })
    }, [page])

    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        if (cookie[`user_id_cookie`] && searchParams.get("nologin") !== "true") {
            setTimeout(() => {
                window.location.href = "https://my.osmos.social";
            }, 1000)
        } else {            
            setTimeout(() => {
                setPreloader(false);
            }, 4000)
        }

        setAnimation1([0, 1, 2, 3, 4, 5, 6, 7].map((elem: number) => {return ({
            type: random(1, 20), topPosition: random(-50, 500), leftPosition: random(0, 600),
            size: random(0, 5), avatarType: avatarStyles[2 * elem + Math.floor(Math.random() + 0.7)], animationDuration: random(30, 60),
            name: `${'abcdefghijklmnopqrstuvwxyz'.split('')[Math.floor(Math.random() * 26)]} ${'abcdefghijklmnopqrstuvwxyz'.split('')[Math.floor(Math.random() * 26)]}`,
        })}));
    
        setAnimation1_2([0, 1, 2, 3, 4, 5, 6, 7].map((elem: number) => {return ({
            type: random(1, 20), topPosition: random(-50, 500), leftPosition: random(10, 500),
            size: random(0, 5), avatarType: avatarStyles[2 * elem + Math.floor(Math.random() + 0.7)], animationDuration: random(30, 60),
            name: `${'abcdefghijklmnopqrstuvwxyz'.split('')[Math.floor(Math.random() * 26)]} ${'abcdefghijklmnopqrstuvwxyz'.split('')[Math.floor(Math.random() * 26)]}`,
        })}));
    
        setAnimation2([0, 1, 2, 3, 4, 5, 6, 7, 0, 1, 2, 3, 4, 5, 6, 7].map((elem: number) => {return ({
            type: random(1, 20), topPosition: random(-100, 1000), leftPosition: random(360 + window.innerWidth / 2, 2500),
            opacity: Math.random() + 0.5,
            size: random(0, 5), avatarType: avatarStyles[2 * elem + Math.floor(Math.random() + 0.7)], animationDuration: random(60, 120),
            name: `${'abcdefghijklmnopqrstuvwxyz'.split('')[Math.floor(Math.random() * 26)]} ${'abcdefghijklmnopqrstuvwxyz'.split('')[Math.floor(Math.random() * 26)]}`,
        })})
        .concat([0, 1, 2, 6, 7].map((elem: number) => {return ({
            type: random(1, 20), topPosition: random(-0, 100), leftPosition: random(window.innerWidth / 2 - 640, 460 + window.innerWidth / 2),
            opacity: Math.random() + 0.5,
            size: random(0, 5), avatarType: avatarStyles[2 * elem + Math.floor(Math.random() + 0.7)], animationDuration: random(60, 120),
            name: `${'abcdefghijklmnopqrstuvwxyz'.split('')[Math.floor(Math.random() * 26)]} ${'abcdefghijklmnopqrstuvwxyz'.split('')[Math.floor(Math.random() * 26)]}`,
        })}))
        .concat([3, 4, 5, 0, 1].map((elem: number) => {return ({
            type: random(1, 20), topPosition: random(-100, 1000), leftPosition: random(0, window.innerWidth / 2 - 640),
            opacity: Math.random() + 0.5,
            size: random(0, 5), avatarType: avatarStyles[2 * elem + Math.floor(Math.random() + 0.7)], animationDuration: random(60, 120),
            name: `${'abcdefghijklmnopqrstuvwxyz'.split('')[Math.floor(Math.random() * 26)]} ${'abcdefghijklmnopqrstuvwxyz'.split('')[Math.floor(Math.random() * 26)]}`,
        })})));

        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    }, [])

    useEffect(() => {
        clearTimeout(timer);
        if (!!filterByIndustry?.length || !!filterByRequest?.length) {
            setTimer(setTimeout(() => {
                SearchRequest(
                    "", 30, 
                    (data: any) => {
                        setSearchData({
                            user_requests: (data?.user_requests ?? []).filter(
                                (request: any, index: number, array: any[]) => 
                                    array.findIndex((val: any) => val?.user?.id === request?.user?.id) === index                                
                            ).map((elem: any) => {return (
                                SetDict(
                                    elem,
                                    ["card", "style", "background"],
                                    elem?.card?.answers[0]?.length > 220 ? elem?.card?.style?.background : random(0, elem?.card?.answers[0]?.length) % 9
                                )
                            )})
                        })
                    }, 
                    filterByIndustry?.length > 0 ? filterByIndustry : null,
                    filterByRequest?.length > 0 ? filterByRequest : null,
                );
            }, 500))
        } else {
            setDefaultSearchData()
        }
    }, [filterByIndustry, filterByRequest])

    return (
    <div className={`body-container row center desktop nogap block w-flex`} data-color={`blue`}>
        <PreloaderLogo shown={preloader}/>
        <div className={`body top desktop`}>
            <div className={`block min-h-screen w-flex px-5`} data-color={`blue`} style={{position: `relative`}}>
                <div className={`w-flex h-6 neg-mt-6 block`} data-color={`blue`}></div>
                {animation2.map((elem: any, index: number) => {return (
                    <div className={`landing-animated-elem-1-${elem?.type}`} key={index}
                        style={{
                            position: `fixed`,  
                            animationDuration: `${elem?.animationDuration}s`,  
                            top: elem?.topPosition - scroll * Math.abs(elem?.topPosition + 200) / 400, 
                            left: elem?.leftPosition + (elem?.leftPosition - 1000) * scroll * Math.abs(elem?.leftPosition) / 300000,
                        }}>
                        <NewAvatar customStyle={iconStyles[elem?.size]} name={elem?.name} type={elem?.avatarType} className={`circled`}
                                style={{
                                        boxShadow: `0px 0px 50px #1033AF`, 
                                        animationDuration: `${elem?.animationDuration}s`,
                                    }}/>
                    </div>
                )})}
                <div className={`w-flex-p-5 neg-mx-5`}
                    style={{
                        ...Sticky(0, 2) as HTMLAttributes<HTMLDivElement>,
                        pointerEvents: `none`,
                    }}>
                    <div className={`block w-screen px-5 row py-2`} data-color={(colorTopBar) ? `blue` : `blue`} 
                        style={{
                            pointerEvents: `auto`, transitionDuration: `0s`, 
                            marginLeft: `calc(50% - 50vw)`, paddingLeft: `calc(50vw - 50% + 58px)`,
                            marginRight: `calc(50% - 50vw)`, paddingRight: `calc(50vw - 50% + 58px)`,
                        }}>
                        <AnimatedLogo color={`white`} shadowColor={`#1033AF`} onClick={() => { setPage(0) }}/>
                        <div className={`row left nowrap`}>
                            <div className={`row left block b-4 nogap nowrap`} data-color={`black`}>
                                <button className={`block btn-circled-40 desktop row`} data-color={page === 1 ? `white-20-opacity` : `black`}
                                        style={{paddingLeft: 14, paddingRight: 14}} onClick={() => { setPage(val => val === 0 ? 1 : 0) }}>
                                    <p data-color={`white`}>{`About`}</p>    
                                </button> 
                                <a className={`block btn-circled-40 desktop row neg-ml-1`} data-color={`transparent`}
                                        style={{paddingLeft: 14, paddingRight: 14}} href={`/blog`}>
                                    <p data-color={`white`}>{`Blog`}</p>    
                                </a> 
                            </div>
                            <a className={`block btn-circled-40 desktop row`} style={{paddingLeft: 14, paddingRight: 14}} 
                            href={`https://my.osmos.social/`}>
                                <p>{`Sign in`}</p>    
                            </a> 
                        </div>
                    </div>

                    <div className={`w-flex neg-mt-2 row`} style={{paddingBottom: 30, paddingLeft: 1, paddingRight: 1.5, opacity: colorTopBar ? 1 : 0}}>
                        <svg width="123" height="75" viewBox="0 0 123 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M0,75h19V52c0-21,17-38,38-38h66V0H0V75z" fill="#1033AF"/>
                        </svg>
                        <svg width="123" height="75" viewBox="0 0 123 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M123,75h-19V52c0-21-17-38-38-38H0L0,0l123,0V75z" fill="#1033AF"/>
                        </svg>
                    </div>
                </div>

                {page === 0 && <>
                <div className={`w-flex`} style={{
                        ...(Sticky(159) as HTMLAttributes<HTMLDivElement>), 
                        transition: `transform .7s ease-in-out`,
                        transform: `translate(0, ${preloader ? 30 : 0}px)`,              
                    }}>
                    <h2 data-color={`white`} style={{
                        fontSize: 78, lineHeight: `100%`,
                        paddingTop: 49,
                        textShadow: `0px 0px 50px #1033AF`, 
                    }} className={`mb-3`}>
                        {`Meet people who`}<br/>{`need you the most`}
                    </h2>
                    <div className={`block mb-1 max-w-flex`} style={{width: 711, height: 2}}></div>
                    <h2 style={{fontWeight: 400, fontSize: 18, maxWidth: 684, textShadow: `0px 0px 50px #1033AF`, }} className={`mb-3`}>
                        {`Connect directly with thousands of founders, VCs, professionals, clients, and contractors.`}
                        <span data-color={`green`}>
                            {`They're all here with their business requests. And so should you.`}
                        </span>
                    </h2>
                    <a className={`block btn-flex mobile`} href={`https://my.osmos.social/?force_logout=true`} style={{boxShadow: `0px 0px 50px #1033AF`}}>
                        <p className={`px-1`}>{`Create my request`}</p>
                    </a>
                </div>

                <div className={`block w-flex-p-4 neg-mx-4 b-4 p-4`} style={{
                    marginTop: 80,
                    position: `relative`, zIndex: 1,
                    transition: `transform .7s cubic-bezier(.17,.67,.43,.99)`, 
                    transform: `translate(0, ${preloader ? 400 : 0}px)`, 
                }} data-color={`light-gray`} id={`white-block`}>
                    <div className={`row nowrap left mb-1`}>
                        <div className={`block w-flex p-3 b-4 row nowrap`}>
                            <input style={{backgroundColor: `transparent`}} placeholder={`Enter the industry you're in`} 
                                onChange={e => { setSearchTag(e.target.value) }} onBlur={e => { 
                                        if (e.relatedTarget?.id !== `select-btn`) {
                                            setFilterOpenIndustryList(false);
                                        }
                                    }}></input>
                            <img src={LensBlack} alt={``} className={`w-3`}></img>
                        </div>
                        <button className={`centered block btn desktop p-3 b-4`} onClick={() => { setOpenRequestTypes(val => !val) }}>
                            <img src={SettingsBlack} alt={``} className={`w-3`} style={{opacity: openRequestTypes ? 0.4 : 1}}></img>
                        </button>
                    </div>
                    <div className={`h-8 neg-mb-8`}>
                        <div className={`block w-flex max-h-flex px-2 pt-1 b-2 y-auto box-shadow`}
                            style={{position: `relative`, zIndex: 2}} hidden={!filterOpenIndustryList}>
                                {Industries.filter((val: string) => val.toLowerCase().includes(searchTag.toLocaleLowerCase().trim()))
                                .map((elem: string, index: number) => {return (
                                    <button className={`btn desktop row mb-2 w-flex`} style={{marginTop: !index ? 6 : undefined}}
                                            onClick={() => {
                                                // setNewIndustry("");
                                                setFilterByIndustry((val: any) => 
                                                    val.includes(elem) ? val.filter((val_: string) => val_ !== elem) : val.concat([elem])
                                                )
                                                setFilterOpenIndustryList(false);
                                            }} type={`button`} id={`select-btn`} key={index} tabIndex={index}>
                                        <p data-size={8} className={filterByIndustry.includes(elem) ? `semibold` : ``}>
                                            {elem}
                                        </p>
                                    </button>
                                )})}
                        </div>
                    </div>
                    {openRequestTypes && 
                    <div className={`row center pb-1`}>
                        {[
                            `share experience`,
                            `find a job`,
                            `hire`,
                            `find clients`,
                            `raise funds`,
                            `invest`,
                            `find experts`,
                            `find partners`,
                            `network`,
                        ].map((elem: string, index: number) => {return (
                            <button key={index} className={`block btn-circled-40 desktop row left ${filterByRequest.includes(index) ? 'p-right' : ''}`}
                                    type={`button`} onClick={() => { 
                                        setFilterByRequest(
                                            val => val.includes(index) ? val.filter(elem => elem !== index) : val.concat([index])) 
                                    }}>
                                <p data-color={!filterByRequest.includes(index) ? `black-40-opacity` : null}>{elem}</p>
                                <div className={`centered w-3 h-3 btn-symbol close`} hidden={!filterByRequest.includes(index)}>
                                    <img src={PlusBlack} alt={`+`} className={`w-1`}></img>
                                </div>
                            </button>
                        )})}
                    </div>}
                    <div className={`row center pt-1 pb-3`}>
                        {merge([
                            "Impact", 
                            "Tech", 
                            "AI", 
                            "Media/Content", 
                            "Future of Work", 
                            "Education", 
                            "FinTech", 
                            "E-commerce", 
                            "MarketingTech", 
                            "Web3", 
                            "Social Networks", 
                            "Consulting", 
                        ], filterByIndustry).map((elem: string, index: number) => {return (
                            <button key={index} className={`block btn-circled-40 desktop row left ${filterByIndustry.includes(elem) ? 'p-right' : ''}`}
                                    type={`button`} onClick={() => { 
                                        setFilterByIndustry(
                                            val => val.includes(elem) ? val.filter(elem_ => elem !== elem_) : val.concat([elem])) 
                                    }}>
                                <p data-color={!filterByIndustry.includes(elem) ? `black-40-opacity` : null}>{elem}</p>
                                <div className={`centered w-3 h-3 btn-symbol close`} hidden={!filterByIndustry.includes(elem)}>
                                    <img src={PlusBlack} alt={`+`} className={`w-1`}></img>
                                </div>
                            </button>
                        )})}
                    </div>
                    <div className={`row nogap top`} style={{minHeight: 500}}>
                        {!!searchData?.user_requests?.length && searchData.user_requests.slice(0, 8).map((elem: any, index: number) => {return (
                        <div className={`clickable mb-3 landing-w-request`} onClick={() => { 
                                const newWindow: WindowProxy | null = window.open(`/request/${elem.card?.id}?open_request=true`, '_blank');
                                if (newWindow) {
                                    newWindow.focus();
                                }
                            }} key={index}>
                            <FeedRequestCardDesktop className={`w-flex`} landingV={true} 
                                                    request={elem.card} onLandingCanHelp={() => {
                                                        globalContext.setCanHelpRequest({
                                                            ...elem.card,                
                                                            onSend: () => {  }
                                                        });
                                                        globalContext.setCanHelpPopup(true);
                                                    }}
                                                    desktop={true} onlyValid={true} index={index} setFilterByIndustry={setFilterByIndustry}/>
                        </div>
                        )})}
                    </div>
                    <div className={`neg-mt-3`}></div>
                </div></>}
                
                {page === 1 && <>
                <div className={`w-flex`} style={{...(Sticky(159) as HTMLAttributes<HTMLDivElement>)}}>
                    <h2 data-color={`white`} style={{
                        fontSize: 58, lineHeight: `100%`,
                        paddingTop: 49,
                        textShadow: `0px 0px 50px #1033AF`,
                    }} className={`mb-3`}>
                        {`Fed up with the endless`}<br/>{`networking on LinkedIn?`}
                    </h2>
                    <div className={`block mb-1 max-w-flex`} style={{width: 834, height: 2}}></div>
                    <h2 style={{fontWeight: 400, fontSize: 18, maxWidth: 684, textShadow: `0px 0px 50px #1033AF`}} className={`mb-3`}>
                        {`Say goodbye to irrelevant and noisy feeds, along with cold DMs. Connect directly with thousands of founders, VCs, professionals, clients, and contractors.`}<br/>
                        <span data-color={`yellow`}>
                            {`They're all here with their own requests. And so should you.`}
                        </span>
                    </h2>
                    <a className={`block btn-flex mobile`} href={`https://my.osmos.social/?force_logout=true`} style={{boxShadow: `0px 0px 50px #1033AF`}}>
                        <p className={`px-1`}>{`Create my request`}</p>
                    </a>
                </div>

                <div className={`block w-flex-p-4 neg-mx-4 bt-4 px-4 pt-6 column top`} style={{
                    marginTop: 94,
                    position: `relative`, zIndex: 1
                }} data-color={`light-gray`} id={`white-block`}>
                    <p data-size={3} className={`semibold seminarrow center mb-3`}>{`That's how Osmos works`}</p>

                    <div className={`row top center nogap landing-4-imgs`}>
                        {[
                            {
                                imgUrl: "/api/static/media/landing_1_v1.png",
                                text: "Share your request",
                                subText: "And instantly explore the feed that<br>contains only business requests.",
                            },
                            {
                                imgUrl: "/api/static/media/landing_2_v1.png",
                                text: "Get matches from AI",
                                subText: "Look through relevant profiles that recommendation system shows.",
                            },
                            {
                                imgUrl: "/api/static/media/landing_3_v1.png",
                                text: "Find help",
                                subText: "Start collaborating with the right person. No spam, no cold messages.",
                            },
                            {
                                imgUrl: "/api/static/media/landing_4_v1.png",
                                text: "Help others",
                                subText: "Follow other users’ requests, contribute & recommend your peers.",
                            }
                        ].map((elem: any, index: number) => {return (
                            <div className={`col-3 md-col-6`} style={{paddingLeft: 7, paddingRight: 7}} key={index}>
                                <img src={elem?.imgUrl} alt={`img`} className={`w-flex rectangle b-3 mb-2`}></img>
                                <p data-size={4} className={`semibold seminarrow mb px`} data-color={`black`}>{elem?.text}</p>
                                <p data-size={5} className={`mb-2 px`} data-color={`black-40-opacity`}>
                                    {elem?.subText}
                                </p>
                            </div>
                        )})}
                    </div>
                    <div className={`neg-mt-2 mb-6 w-flex`}></div>

                    <p data-size={3} className={`semibold seminarrow center mb-3`}>{`Networking done right`}</p>

                    <div className={`row top center nogap landing-4-imgs`}>
                        {[
                            {
                                text: "Networking events ❌",
                                subText: "A post-conference party where you would slap on a name tag, grab a drink at the bar, and elevator pitch to other attendees for hours, only to find out that 18 out of 20 people were irrelevant to your business goals. The other 2 ignore your DMs when you follow up on LinkedIn.",
                            },
                            {
                                text: "Osmos 👌",
                                subText: "You post your request and let AI match you with the perfect individual. You find the help you need and, in the process, cultivate authentic relationships with others who share your enthusiasm for cool things, all through collaboration on your request.",
                            },
                        ].map((elem: any, index: number) => {return (
                            <div className={`col-6 md-col-12 mb-2`} 
                                style={{paddingLeft: 7, paddingRight: 7, height: 300}} key={index}>
                                <div className={`block column left bottom  b-3 p-3 h-flex`}>
                                    <p data-size={4} className={`semibold seminarrow mb`}>{elem?.text}</p>
                                    <p data-size={5} className={`mb-2`} data-color={`black-40-opacity`}>
                                        {elem?.subText}
                                    </p>
                                </div>
                            </div>
                        )})}
                    </div>
                    <div className={`neg-mt-2 mb-6 w-flex`}></div>

                    <div className={`row w-flex-p-3 neg-mx-3 h-flex gap-5 nowrap block b-3 p-3 mb-2`} style={{height: 446, maxWidth: 1212}}>
                        <div className={`w-flex h-flex column left bottom p-3`} style={{maxWidth: 550}}>
                            <h2 style={{fontSize: 32}} className={`mb-1`}>
                                {`Unlock the power of meaningful connections`}
                            </h2>
                            <p data-size={5}>{`If we focus more on doing cool stuff with interesting people and less on meeting interesting people, the latter tends to take care of itself. Now post your request, and let us play matchmaker for you.`}</p>
                            <a className={`block btn-flex mobile mt-2`} href={`https://my.osmos.social/?force_logout=true`} 
                            data-color={`black`} style={{width: 193}}>
                                <p className={`center`}>{`Get started`}</p>
                            </a>
                        </div>
                        <div className={`w-flex h-flex b-3`} style={{position: `relative`, overflow: `hidden`, maxWidth: 504,}} data-sm-hidden={true} id={`video-animation-2`}>
                            <video autoPlay={true} loop={true} muted={true} className={`w-flex h-flex`} controls={false}
                                style={{
                                        objectFit: `cover`, backgroundColor: `#000000`, objectPosition: `50% 50%`,
                                }}>
                                <source src={`/api/static/media/landing_video_v1.mp4`} type="video/mp4"/>   
                            </video>
                            {animation1.map((elem: any, index: number) => {return (
                                <div className={`landing-animated-elem-1-${elem?.type}`} key={index}
                                    style={{
                                        position: `absolute`, 
                                        top: elem?.topPosition - (elem?.topPosition - 250) * scroll2 / 600, 
                                        left: elem?.leftPosition, 
                                        animationDuration: `${elem?.animationDuration}s`
                                    }}>
                                    <NewAvatar name={elem?.name} type={elem?.avatarType} className={`circled`} style={{
                                                boxShadow: `0px 0px 50px #00000066`,
                                                animationDuration: `${elem?.animationDuration}s`,
                                            }} customStyle={iconStyles[elem?.size]}/>
                                </div>
                            )})}
                        </div>
                    </div>

                    <div className={`row left w-flex-p-3 neg-mx-3 h-flex gap-5 nowrap block b-3 p-3 mb-8`} style={{height: 446, maxWidth: 1212}}>
                        <div className={`w-flex h-flex b-3`} style={{position: `relative`, overflow: `hidden`, maxWidth: 504,}} data-sm-hidden={true} id={`video-animation-3`}>
                            <video autoPlay={true} loop={true} muted={true} className={`w-flex h-flex`} controls={false}
                                style={{
                                        objectFit: `cover`, backgroundColor: `#000000`, objectPosition: `50% 50%`,
                                }}>
                                <source src={`/api/static/media/landing_video_v1.mp4`} type="video/mp4"/>   
                            </video>
                            {animation1_2.map((elem: any, index: number) => {return (
                                <div className={`landing-animated-elem-1-${elem?.type}`} key={index}
                                    style={{
                                        position: `absolute`, 
                                        top: elem?.topPosition - (elem?.topPosition - 250) * scroll3 / 600, 
                                        left: elem?.leftPosition, 
                                        animationDuration: `${elem?.animationDuration}s`
                                    }}>
                                    <NewAvatar customStyle={iconStyles[elem?.size]} name={elem?.name} type={elem?.avatarType} className={`circled`} style={{
                                                boxShadow: `0px 0px 50px #00000066`,
                                                animationDuration: `${elem?.animationDuration}s`,
                                            }}/>
                                </div>
                            )})}
                        </div>
                        <div className={`w-flex h-flex column left bottom p-3`} style={{maxWidth: 550}}>
                            <h2 style={{fontSize: 32}} className={`mb-1`}>
                                {`No requests on your mind yet?`}
                            </h2>
                            <p data-size={5}>
                                {`Explore industry-specific queries to discover how many people are seeking your expertise and professional background!`}<br/>
                                {`Hit the `}<span data-color={`green`}>{`'I can help'`}</span>{` button and start networking right away.`}
                            </p>
                            <a className={`block btn-flex mobile mt-2`} href={`https://my.osmos.social/?force_logout=true`} 
                            data-color={`black`} style={{width: 193}}>
                                <p className={`center`}>{`Dive in`}</p>
                            </a>
                        </div>
                    </div>
                    <div className={`w-flex row py-2`}>
                        <a className={`btn desktop`} href={`https://my.osmos.social/privacy`}>
                            <p data-size={8} className={`semibold seminarrow`} data-color={`black`}>{`Privacy policy`}</p>
                        </a>
                        <div className={`row gap-3 nowrap left`}>
                            <a className={`btn row`} href={`https://www.linkedin.com/company/osmos-social`}>
                                <img src={LinkedInBlack} alt={``} className={`w-3`}></img>
                            </a>
                            <a className={`btn row`} href={`https://twitter.com/Osmos_social`}>
                                <img src={XBlack} alt={``} className={`w-3`}></img>
                            </a>
                        </div>
                    </div>
                </div>
                </>}
            </div>
            
            {page === 0 && 
            <div className={`block px-5 pt-5 w-flex min-h-screen column`} data-color={`blue`}>
                <div className={`mb-7`}></div>
                <div className={`row w-flex h-flex nogap nowrap`}>
                    <div className={`col-7 pr-4`}>
                        <h2 style={{fontSize: 48, maxWidth: 595, lineHeight: `53px`, paddingBottom: 62}}>
                            {`Unlock the power of meaningful connections`}
                        </h2>
                        {[
                            {
                                mainText: `Enjoy a focused feed with only business requests`, 
                                subText: `Follow people you’re truly interested in and stay informed about their needs.`,
                            },
                            {
                                mainText: `Tap into the expertise of top-tier professionals`, 
                                subText: `Connect with A-players in your field, no social capital is needed.`,
                            },
                            {
                                mainText: `Match with the right people at the right time`, 
                                subText: `Skip the gatekeepers, ignore the barriers. Go straight in with your request.`,
                            },
                            {
                                mainText: `Protect your most valuable resource, time`, 
                                subText: `Say goodbye to noisy feeds, warm intros and cold DMs.`,
                            },
                        ].map((elem: any, index: number) => {return (
                            <div className={`row top left nowrap gap-2 ${!!index ? `mt-2` : ``}`} key={index}>
                                <div className={`btn-symbol w-3 h-3 centered`} data-color={`white`}>
                                    <img src={OkWhite} alt={`ok`}></img>
                                </div>
                                <div>
                                    <p data-size={5} className={`semibold seminarrow mb`}>
                                        {elem?.mainText}
                                    </p>
                                    <p data-size={8} data-color={`white-60-opacity`}>
                                        {elem?.subText}
                                    </p>
                                </div>
                            </div>
                        )})}
                        <a className={`block btn-flex mobile mt-3 ml-4`} href={`https://my.osmos.social/?force_logout=true`}>
                            <p className={`px-1`}>{`Create my request`}</p>
                        </a>
                    </div>
                    <div className={`col-5 b-3`} style={{height: 520, position: `relative`, overflow: `hidden`}} id={`video-animation-1`}>
                        <video autoPlay={true} loop={true} muted={true} className={`w-flex h-flex`} controls={false}
                            style={{
                                    objectFit: `cover`, backgroundColor: `#000000`, objectPosition: `50% 50%`,
                            }}>
                            <source src={`/api/static/media/landing_video_v1.mp4`} type="video/mp4"/>   
                        </video>
                        {animation1.map((elem: any, index: number) => {return (
                            <div className={`landing-animated-elem-1-${elem?.type}`} key={index}
                                style={{
                                    position: `absolute`, 
                                    top: elem?.topPosition - (elem?.topPosition - 360) * scroll1 / 600, 
                                    left: elem?.leftPosition, 
                                    animationDuration: `${elem?.animationDuration}s`
                                }}>
                                <NewAvatar customStyle={iconStyles[elem?.size]} name={elem?.name} type={elem?.avatarType} className={`circled`} 
                                        style={{
                                            boxShadow: `0px 0px 50px #00000066`,
                                            animationDuration: `${elem?.animationDuration}s`,
                                            }}/>
                            </div>
                        )})}
                    </div>
                </div>
                <div className={`w-flex row py-2 mt-7`}>
                    <a className={`btn desktop`} href={`https://my.osmos.social/privacy`}>
                        <p data-size={8} className={`semibold seminarrow`}>{`Privacy policy`}</p>
                    </a>
                    <div className={`row gap-3 nowrap left`}>
                        <a className={`btn row`} href={`https://www.linkedin.com/company/osmos-social`}>
                            <img src={LinkedInWhite} alt={``} className={`w-3`}></img>
                        </a>
                        <a className={`btn row`} href={`https://twitter.com/Osmos_social`}>
                            <img src={XWhite} alt={``} className={`w-3`}></img>
                        </a>
                    </div>
                </div>
            </div>}

            {page === 3 &&
            <div className={`block px-5 pt-6 w-flex column top`} data-color={`light-gray`}>
                <p data-size={3} className={`semibold seminarrow center mb-3`}>{`That's how Osmos works`}</p>

                <div className={`row top center nogap landing-4-imgs`}>
                    {[
                        {
                            imgUrl: "/api/static/media/landing_1_v1.png",
                            text: "Share your request",
                            subText: "And instantly explore the feed that<br>contains only business requests.",
                        },
                        {
                            imgUrl: "/api/static/media/landing_2_v1.png",
                            text: "Get matches from AI",
                            subText: "Look through relevant profiles that recommendation system shows.",
                        },
                        {
                            imgUrl: "/api/static/media/landing_3_v1.png",
                            text: "Find help",
                            subText: "Start collaborating with the right person. No spam, no cold messages.",
                        },
                        {
                            imgUrl: "/api/static/media/landing_4_v1.png",
                            text: "Help others",
                            subText: "Follow other users’ requests, contribute & recommend your peers.",
                        }
                    ].map((elem: any, index: number) => {return (
                        <div className={`col-3 md-col-6`} style={{paddingLeft: 7, paddingRight: 7}} key={index}>
                            <img src={elem?.imgUrl} alt={`img`} className={`w-flex rectangle b-3 mb-2`}></img>
                            <p data-size={4} className={`semibold seminarrow mb px`}>{elem?.text}</p>
                            <p data-size={5} className={`mb-2 px`} data-color={`black-40-opacity`}>
                                {elem?.subText}
                            </p>
                        </div>
                    )})}
                </div>
                <div className={`neg-mt-2 mb-6 w-flex`}></div>

                <p data-size={3} className={`semibold seminarrow center mb-3`}>{`Networking done right`}</p>

                <div className={`row top center nogap landing-4-imgs`}>
                    {[
                        {
                            text: "Networking events ❌",
                            subText: "A post-conference party where you would slap on a name tag, grab a drink at the bar, and elevator pitch to other attendees for hours, only to find out that 18 out of 20 people were irrelevant to your business goals. The other 2 ignore your DMs when you follow up on LinkedIn.",
                        },
                        {
                            text: "Osmos 👌",
                            subText: "You post your request and let AI match you with the perfect individual. You find the help you need and, in the process, cultivate authentic relationships with others who share your enthusiasm for cool things, all through collaboration on your request.",
                        },
                    ].map((elem: any, index: number) => {return (
                        <div className={`col-6 md-col-12 mb-2`} 
                            style={{paddingLeft: 7, paddingRight: 7, height: 300}} key={index}>
                            <div className={`block column left bottom  b-3 p-3 h-flex`}>
                                <p data-size={4} className={`semibold seminarrow mb`}>{elem?.text}</p>
                                <p data-size={5} className={`mb-2`} data-color={`black-40-opacity`}>
                                    {elem?.subText}
                                </p>
                            </div>
                        </div>
                    )})}
                </div>
                <div className={`neg-mt-2 mb-6 w-flex`}></div>

                <div className={`row w-flex-p-3 neg-mx-3 h-flex gap-5 nowrap block b-3 p-3 mb-2`} style={{height: 446, maxWidth: 1212}}>
                    <div className={`w-flex h-flex column left bottom p-3`} style={{maxWidth: 550}}>
                        <h2 style={{fontSize: 32}} className={`mb-1`}>
                            {`Unlock the power of meaningful connections`}
                        </h2>
                        <p data-size={5}>{`If we focus more on doing cool stuff with interesting people and less on meeting interesting people, the latter tends to take care of itself. Now post your request, and let us play matchmaker for you.`}</p>
                        <a className={`block btn-flex mobile mt-2`} href={`https://my.osmos.social/?force_logout=true`} 
                        data-color={`black`} style={{width: 193}}>
                            <p className={`center`}>{`Get started`}</p>
                        </a>
                    </div>
                    <div className={`w-flex h-flex b-3`} style={{position: `relative`, overflow: `hidden`, maxWidth: 504,}} data-sm-hidden={true} id={`video-animation-2`}>
                        <video autoPlay={true} loop={true} muted={true} className={`w-flex h-flex`} controls={false}
                            style={{
                                    objectFit: `cover`, backgroundColor: `#000000`, objectPosition: `50% 50%`,
                            }}>
                            <source src={`/api/static/media/landing_video_v1.mp4`} type="video/mp4"/>   
                        </video>
                        {animation1.map((elem: any, index: number) => {return (
                            <div className={`landing-animated-elem-1-${elem?.type}`} key={index}
                                style={{
                                    position: `absolute`, 
                                    top: elem?.topPosition - (elem?.topPosition - 250) * scroll2 / 600, 
                                    left: elem?.leftPosition, 
                                    animationDuration: `${elem?.animationDuration}s`
                                }}>
                                <NewAvatar customStyle={iconStyles[elem?.size]} name={elem?.name} type={elem?.avatarType} className={`circled`} style={{
                                            boxShadow: `0px 0px 50px #00000066`,
                                            animationDuration: `${elem?.animationDuration}s`,
                                        }}/>
                            </div>
                        )})}
                    </div>
                </div>

                <div className={`row left w-flex-p-3 neg-mx-3 h-flex gap-5 nowrap block b-3 p-3 mb-8`} style={{height: 446, maxWidth: 1212}}>
                    <div className={`w-flex h-flex b-3`} style={{position: `relative`, overflow: `hidden`, maxWidth: 504,}} data-sm-hidden={true} id={`video-animation-3`}>
                        <video autoPlay={true} loop={true} muted={true} className={`w-flex h-flex`} controls={false}
                            style={{
                                    objectFit: `cover`, backgroundColor: `#000000`, objectPosition: `50% 50%`,
                            }}>
                            <source src={`/api/static/media/landing_video_v1.mp4`} type="video/mp4"/>   
                        </video>
                        {animation1_2.map((elem: any, index: number) => {return (
                            <div className={`landing-animated-elem-1-${elem?.type}`} key={index}
                                style={{
                                    position: `absolute`, 
                                    top: elem?.topPosition - (elem?.topPosition - 250) * scroll3 / 600, 
                                    left: elem?.leftPosition, 
                                    animationDuration: `${elem?.animationDuration}s`
                                }}>
                                <NewAvatar customStyle={iconStyles[elem?.size]} name={elem?.name} type={elem?.avatarType} className={`circled`} style={{
                                            boxShadow: `0px 0px 50px #00000066`,
                                            animationDuration: `${elem?.animationDuration}s`,
                                        }}/>
                            </div>
                        )})}
                    </div>
                    <div className={`w-flex h-flex column left bottom p-3`} style={{maxWidth: 550}}>
                        <h2 style={{fontSize: 32}} className={`mb-1`}>
                            {`No requests on your mind yet?`}
                        </h2>
                        <p data-size={5}>
                            {`Explore industry-specific queries to discover how many people are seeking your expertise and professional background!`}<br/>
                            {`Hit the `}<span data-color={`green`}>{`'I can help'`}</span>{` button and start networking right away.`}
                        </p>
                        <a className={`block btn-flex mobile mt-2`} href={`https://my.osmos.social/?force_logout=true`} 
                        data-color={`black`} style={{width: 193}}>
                            <p className={`center`}>{`Dive in`}</p>
                        </a>
                    </div>
                </div>
                <div className={`w-flex row py-2`}>
                    <a className={`btn desktop`} href={`https://my.osmos.social/privacy`}>
                        <p data-size={8} className={`semibold seminarrow`}>{`Privacy policy`}</p>
                    </a>
                    <div className={`row gap-3 nowrap left`}>
                        <a className={`btn row`} href={`https://www.linkedin.com/company/osmos-social`}>
                            <img src={LinkedInBlack} alt={``} className={`w-3`}></img>
                        </a>
                        <a className={`btn row`} href={`https://twitter.com/Osmos_social`}>
                            <img src={XBlack} alt={``} className={`w-3`}></img>
                        </a>
                    </div>
                </div>
            </div>} 
        </div>
    </div>)
}

export const LandingMobile = (props: any) => {
    const globalContext = useContext(GlobalContext);
    const [ cookie ] = useCookies([`user_id_cookie`]);
    const [ searchParams ] = useSearchParams();

    const [ page, setPage ] = useState<number>(0)
    const [ searchTag, setSearchTag ] = useState<string>(``);
    const [ openRequestTypes, setOpenRequestTypes ] = useState<boolean>(false);
    const [ filterByRequest, setFilterByRequest ] = useState<number[]>([]);
    const [ filterByIndustry, setFilterByIndustry ] = useState<string[]>([]);
    const [ filterOpenIndustryList, setFilterOpenIndustryList ] = useState<boolean>(false);

    const [ timer, setTimer ] = useState<any>();
    const [ searchData, setSearchData ] = useState<any>();

    const [ preloader, setPreloader ] = useState<boolean>(true);

    useEffect(() => {
        setFilterOpenIndustryList(
            Industries.filter((val: string) => val.toLowerCase().includes(searchTag.toLocaleLowerCase().trim()))?.length > 0 &&
            searchTag?.length > 0
        )
    }, [searchTag])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: `instant`,
        })
    }, [page])

    const random = (min: number, max: number) => {
        return Math.floor(Math.random() * Math.abs(min - max) + Math.min(max, min))
    }

    const merge = (a: any[], b: any[]) => {
        return b.reduce((array: any, next: any) => {
            if (!array.includes(next)) {
                return array.concat([next]);
            } else {
                return array;
            }
        }, a)
    }

    const avatarStyles = [
        [`green`, `white`, `white`, `green`], [`white`, `green`, `green`, `white`], 
        [`blue`, `white`, `white`, `blue`], [`white`, `blue`, `blue`, `white`], 
        [`bright-pink`, `white`, `white`, `bright-pink`], [`white`, `bright-pink`, `bright-pink`, `white`,], 
        [`yellow`, `white`, `white`, `yellow`], [`white`, `yellow`, `yellow`, `white`], 
        [`pink`, `white`, `white`, `pink`], [`white`, `pink`, `pink`, `white`], 
        [`bright-blue`, `white`, `white`, `bright-blue`], [`white`, `bright-blue`, `bright-blue`, `white`], 
        [`black-40-opacity`, `white`, `white`, `black-40-opacity`], [`white`, `black-40-opacity`, `black-40-opacity`, `white`], 
        [`coral`, `white`, `white`, `coral`], [`white`, `coral`, `coral`, `white`],
    ]

    // const hiddenPositions = [
    //     {top: `-50vw`, right: `25vw`},
    //     {top: `-50vw`, right: `150vw`},
    //     {top: `-50vw`, right: `0vw`},
    //     {top: `10vw`, right: `-50vw`},
    //     {top: `-50vw`, right: `80vw`},
    //     {top: `30vw`, right: `150vw`},
    //     {top: `-50vw`, right: `25vw`},
    //     {top: `5vw`, right: `150vw`},
    // ]

    const iconStyles = [
        {width: 58, borderRadius: 12, fontSize: 24},
        {width: 48, borderRadius: 10, fontSize: 20},
        {width: 40, borderRadius: 8, fontSize: 17},
    ]

    const [ animation2, setAnimation2 ] = useState<any[]>([]);

    const [ colorTopBar, setColorTopBar ] = useState<boolean>(false);
    const [ scroll, setScroll ] = useState<number>(window.scrollY);

    const onScroll = () => {
        if (window.scrollY > 510) {
            setColorTopBar(true)
        } else {
            setColorTopBar(false)
        }
        setScroll(window.scrollY);
    }

    const setDefaultSearchData = () => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}top_requests`, {
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors' as RequestMode,
            method: 'GET',
        }).then(res => {
            if (res.status === 200) {
                res.json().then(data => {
                    setSearchData({
                        user_requests: (data?.user_requests ?? []).map((elem: any) => {return (
                            SetDict(
                                elem,
                                ["card", "style", "background"],
                                elem?.card?.answers[0]?.length > 220 ? elem?.card?.style?.background : random(0, elem?.card?.answers[0]?.length) % 9
                            )
                        )})
                    })
                })
            }
        })
    }

    useEffect(() => {    
        document.querySelectorAll("video").forEach((elem: HTMLVideoElement) => {
            elem.play().catch(() => {});
        })
    }, [page])

    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        if (cookie[`user_id_cookie`] && searchParams.get("nologin") !== "true") {
            setTimeout(() => {
                window.location.href = "https://my.osmos.social";
            }, 1000)
        } else {
            setTimeout(() => {
                setPreloader(false);
            }, 4000)
        }
    
        setAnimation2([0, 1, 2].map((elem: number) => {return ({
            type: random(1, 20), topPosition: random(0, 100), leftPosition: random(0, 390), opacity: Math.random() + 0.5,
            size: random(0, 3), avatarType: avatarStyles[2 * elem + Math.floor(Math.random() + 0.7)], animationDuration: random(30, 60),
            name: `${'abcdefghijklmnopqrstuvwxyz'.split('')[Math.floor(Math.random() * 26)]} ${'abcdefghijklmnopqrstuvwxyz'.split('')[Math.floor(Math.random() * 26)]}`,
        })})
        .concat(
            [3, 4, 5].map((elem: number) => {return ({
                type: random(1, 20), topPosition: random(100, 700), leftPosition: random(-50, 50), opacity: Math.random() + 0.5,
                size: random(0, 3), avatarType: avatarStyles[2 * elem + Math.floor(Math.random() + 0.7)], animationDuration: random(30, 60),
                name: `${'abcdefghijklmnopqrstuvwxyz'.split('')[Math.floor(Math.random() * 26)]} ${'abcdefghijklmnopqrstuvwxyz'.split('')[Math.floor(Math.random() * 26)]}`,
            })})
        )
        .concat(
            [6, 7, 1].map((elem: number) => {return ({
                type: random(1, 20), topPosition: random(100, 700), leftPosition: random(310, 420), opacity: Math.random() + 0.5,
                size: random(0, 3), avatarType: avatarStyles[2 * elem + Math.floor(Math.random() + 0.7)], animationDuration: random(30, 60),
                name: `${'abcdefghijklmnopqrstuvwxyz'.split('')[Math.floor(Math.random() * 26)]} ${'abcdefghijklmnopqrstuvwxyz'.split('')[Math.floor(Math.random() * 26)]}`,
            })})
        ));

        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    }, [])

    useEffect(() => {
        clearTimeout(timer);
        if (!!filterByIndustry?.length || !!filterByRequest?.length) {
            setTimer(setTimeout(() => {
                SearchRequest(
                    "", 30, 
                    (data: any) => {
                        setSearchData({
                            user_requests: (data?.user_requests ?? []).filter(
                                (request: any, index: number, array: any[]) => 
                                    array.findIndex((val: any) => val?.user?.id === request?.user?.id) === index                                
                            ).map((elem: any) => {return (
                                SetDict(
                                    elem,
                                    ["card", "style", "background"],
                                    elem?.card?.answers[0]?.length > 220 ? elem?.card?.style?.background : random(0, elem?.card?.answers[0]?.length) % 9
                                )
                            )})
                        })
                    }, 
                    filterByIndustry?.length > 0 ? filterByIndustry : null,
                    filterByRequest?.length > 0 ? filterByRequest : null,
                );
            }, 500))
        } else {
            setDefaultSearchData();
        }
    }, [filterByIndustry, filterByRequest])

    return (<>
    <PreloaderLogo shown={preloader}/>
    <div className={`block min-h-screen w-flex`} data-color={`blue`} style={{position: `relative`}}>
        <div className={`block w-flex h-6 neg-mt-6`} data-color={`blue`}></div>
        {animation2.map((elem: any, index: number) => {return (
            <div className={`landing-animated-elem-1-${elem?.type}`} key={index}
                 style={{
                    position: `fixed`,  
                    animationDuration: `${elem?.animationDuration}s`,  
                    top: elem?.topPosition - scroll * Math.abs(elem?.topPosition + 200) / 400, 
                    left: elem?.leftPosition + (elem?.leftPosition - 180) * scroll * Math.abs(elem?.leftPosition) / 70000,
                 }}>
                <NewAvatar customStyle={iconStyles[elem?.size]} name={elem?.name} type={elem?.avatarType} className={`circled`}
                            style={{
                                boxShadow: `0px 0px 50px #1033AF`, 
                                animationDuration: `${elem?.animationDuration}s`,
                            }}/>
            </div>
        )})}
        <div className={`w-flex`}
             style={{
                ...Sticky(0, 2) as HTMLAttributes<HTMLDivElement>,
                pointerEvents: `none`,
            }}>
            <div className={`block w-flex px-2 row py-2`} data-color={(colorTopBar) ? `blue` : `transparent`} 
                 style={{pointerEvents: `auto`, transitionDuration: `0s`}}>
                <button className={`block btn-circled-40 mobile row`} data-color={page === 1 ? `black` : `white-20-opacity`}
                        style={{paddingLeft: 14, paddingRight: 14, boxShadow: `0px 0px 50px #1033AF`,}} onClick={() => { setPage(val => val === 0 ? 1 : 0) }}>
                    <p data-color={`white`}>{`About`}</p>    
                </button>
                <AnimatedLogo color={`white`} shadowColor={`#1033AF`} onClick={() => { setPage(0) }}/>
                <a className={`block btn-circled-40 mobile row`} style={{paddingLeft: 14, paddingRight: 14, boxShadow: `0px 0px 50px #1033AF`,}} 
                href={`https://my.osmos.social/`}>
                    <p>{`Sign in`}</p>    
                </a> 
            </div>
            <div className={`w-flex neg-mt-2 row`} style={{paddingBottom: 30, opacity: colorTopBar ? 1 : 0, overflow: `hidden`}}>
                <svg width="123" height="75" viewBox="0 0 123 75" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginLeft: -19}}>
                    <path fillRule="evenodd" clipRule="evenodd" d="M0,75h19V52c0-21,17-38,38-38h66V0H0V75z" fill="#1033AF"/>
                </svg>
                <svg width="123" height="75" viewBox="0 0 123 75" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginRight: -19}}>
                    <path fillRule="evenodd" clipRule="evenodd" d="M123,75h-19V52c0-21-17-38-38-38H0L0,0l123,0V75z" fill="#1033AF"/>
                </svg>
            </div>
        </div>

        {page === 0 && <>
        <div className={`w-flex column`} style={{
            ...Sticky(159) as HTMLAttributes<HTMLDivElement>,
            transition: `transform .7s ease-in-out`,
            transform: `translate(0, ${preloader ? 30 : 0}px)`, 
        }}>
            <h2 data-color={`white`} style={{
                fontSize: 34, lineHeight: `120%`,
                paddingTop: 50,
                textShadow: `0px 0px 20px #1033AF`,
            }} className={`mb-2 center px-3`}>
                {`Meet people who need you the most`}
            </h2>
            <h2 style={{fontWeight: 400, lineHeight: `140%`, fontSize: 16, textShadow: `0px 0px 20px #1033AF`,}} className={`mb-4 center px-3`}>
                {`Connect directly with thousands of founders, VCs, professionals, clients, and contractors.`}
                <span data-color={`green`}>
                    {`They're all here with their business requests. And so should you.`}
                </span>
            </h2>
            <a className={`block btn-flex mobile`} href={`https://my.osmos.social/?force_logout=true`} style={{boxShadow: `0px 0px 20px #1033AF`}}>
                <p className={`px-1`}>{`Create my request`}</p>
            </a>
        </div>

        <div className={`block w-flex b-4 p-2`} style={{
            marginTop: 117,
            position: `relative`, zIndex: 1,
            transition: `transform .7s cubic-bezier(.17,.67,.43,.99)`, 
            transform: `translate(0, ${preloader ? 400 : 0}px)`, 
        }} data-color={`light-gray`}>
            <div className={`row nowrap left mb-1`}>
                <div className={`block w-flex p-2 b-4 row nowrap`}>
                    <input style={{backgroundColor: `transparent`}} placeholder={`Enter the industry you're in`} 
                           onChange={e => { setSearchTag(e.target.value) }} onBlur={e => { 
                                if (e.relatedTarget?.id !== `select-btn`) {
                                    setFilterOpenIndustryList(false);
                                }
                            }} data-size={6}></input>
                    <img src={LensBlack} alt={``} className={`w-3`}></img>
                </div>
                <button className={`centered block btn desktop p-2 b-4`} onClick={() => { setOpenRequestTypes(val => !val) }}>
                    <img src={SettingsBlack} alt={``} className={`w-3`} style={{opacity: openRequestTypes ? 0.4 : 1}}></img>
                </button>
            </div>
            <div className={`h-8 neg-mb-8`}>
                <div className={`block w-flex max-h-flex px-2 pt-1 b-2 y-auto box-shadow`}
                    style={{position: `relative`, zIndex: 2}} hidden={!filterOpenIndustryList}>
                        {Industries.filter((val: string) => val.toLowerCase().includes(searchTag.toLocaleLowerCase().trim()))
                        .map((elem: string, index: number) => {return (
                            <button className={`btn desktop row mb-2 w-flex`} style={{marginTop: !index ? 6 : undefined}}
                                    onClick={() => {
                                        // setNewIndustry("");
                                        setFilterByIndustry((val: any) => 
                                            val.includes(elem) ? val.filter((val_: string) => val_ !== elem) : val.concat([elem])
                                        )
                                        setFilterOpenIndustryList(false);
                                    }} type={`button`} id={`select-btn`} key={index} tabIndex={index}>
                                <p data-size={8} className={filterByIndustry.includes(elem) ? `semibold` : ``}>
                                    {elem}
                                </p>
                            </button>
                        )})}
                </div>
            </div>
            {openRequestTypes && 
            <div className={`row center pb-1`}>
                {[
                    `share experience`,
                    `find a job`,
                    `hire`,
                    `find clients`,
                    `raise funds`,
                    `invest`,
                    `find experts`,
                    `find partners`,
                    `network`,
                ].map((elem: string, index: number) => {return (
                    <button key={index} className={`block btn-circled-32 mobile row left ${filterByRequest.includes(index) ? 'p-right' : ''}`}
                            type={`button`} onClick={() => { 
                                setFilterByRequest(
                                    val => val.includes(index) ? val.filter(elem => elem !== index) : val.concat([index])) 
                            }}>
                        <p data-color={!filterByRequest.includes(index) ? `black-40-opacity` : null}>{elem}</p>
                        <div className={`centered w-3 h-3 btn-symbol close`} hidden={!filterByRequest.includes(index)}>
                            <img src={PlusBlack} alt={`+`} className={`w-1`}></img>
                        </div>
                    </button>
                )})}
            </div>}
            <div className={`row center pt-1 pb-3`}>
                {merge([
                    "Impact", 
                    "Tech", 
                    "AI", 
                    "Media/Content", 
                    "Future of Work", 
                    "Education", 
                    "FinTech", 
                    "E-commerce", 
                    "MarketingTech", 
                    "Web3", 
                    "Social Networks", 
                    "Consulting", 
                ], filterByIndustry).map((elem: string, index: number) => {return (
                    <button key={index} className={`block btn-circled-32 mobile row left ${filterByIndustry.includes(elem) ? 'p-right' : ''}`}
                            type={`button`} onClick={() => { 
                                setFilterByIndustry(
                                    val => val.includes(elem) ? val.filter(elem_ => elem !== elem_) : val.concat([elem])) 
                            }}>
                        <p data-color={!filterByIndustry.includes(elem) ? `black-40-opacity` : null}>{elem}</p>
                        <div className={`centered w-3 h-3 btn-symbol close`} hidden={!filterByIndustry.includes(elem)}>
                            <img src={PlusBlack} alt={`+`} className={`w-1`}></img>
                        </div>
                    </button>
                )})}
            </div>
            <div style={{minHeight: 1045}}>
                {!!searchData?.user_requests?.length && searchData.user_requests.slice(0, 8).map((elem: any, index: number) => {return (
                    <div className={`clickable mb-2 w-flex`} onClick={() => {  
                        const newWindow: WindowProxy | null = window.open(`/request/${elem.card?.id}?open_request=true`, '_blank');
                        if (newWindow) {
                            newWindow.focus();
                        }
                    }} key={index}>
                    <FeedRequestCardMobile className={`w-flex`} profile={elem.user} landingV={true} 
                                            request={elem.card} onLandingCanHelp={() => {
                                                globalContext.setCanHelpRequest({
                                                    ...elem.card,                
                                                    onSend: () => {  }
                                                });
                                                globalContext.setCanHelpPopup(true);
                                            }}
                                            desktop={true} onlyValid={true} index={index} setFilterByIndustry={setFilterByIndustry}/>
                </div>
                )})}
                <div className={`neg-mt-2`}></div>
            </div>
        </div></>}
        
        {page === 1 && <>
        <div className={`w-flex column top`} style={Sticky(159) as HTMLAttributes<HTMLDivElement>}>
            <h2 data-color={`white`} style={{
                fontSize: 28, lineHeight: `120%`,
                paddingTop: 50,
                textShadow: `0px 0px 20px #1033AF`,
            }} className={`px-3 center mb-2`}>
                {`Fed up with the endless`}<br/>{`networking on LinkedIn?`}
            </h2>
            <h2 style={{fontWeight: 400, lineHeight: `140%`, fontSize: 14, textShadow: `0px 0px 20px #1033AF`,}} className={`center mb-4 px-3`}>
                {`Say goodbye to irrelevant and noisy feeds, along with cold DMs. Connect directly with thousands of founders, VCs, professionals, clients, and contractors. `}
                <span data-color={`yellow`}>
                    {`They're all here with their own requests. And so should you.`}
                </span>
            </h2>
            <a className={`block btn-flex mobile`} href={`https://my.osmos.social/?force_logout=true`} style={{boxShadow: `0px 0px 20px #1033AF`}}>
                <p className={`px-1`}>{`Post my request`}</p>
            </a>
        </div>

        <div className={`block w-flex b-4 p-2 pt-4`} style={{
                marginTop: 123,
                position: `relative`, zIndex: 1
            }} data-color={`light-gray`}>
            <p data-size={4} className={`semibold seminarrow center mb-3`}>{`That's how Osmos works`}</p>

            {[
                {
                    imgUrl: "/api/static/media/landing_1_v1.png",
                    text: "Share your request",
                    subText: "And instantly explore the feed that contains only business requests.",
                },
                {
                    imgUrl: "/api/static/media/landing_2_v1.png",
                    text: "Get matches from AI",
                    subText: "Look through relevant profiles that recommendation system shows.",
                },
                {
                    imgUrl: "/api/static/media/landing_3_v1.png",
                    text: "Find help",
                    subText: "Start collaborating with the right person. No spam, no cold messages.",
                },
                {
                    imgUrl: "/api/static/media/landing_4_v1.png",
                    text: "Help others",
                    subText: "Follow other users’ requests, contribute & recommend your peers.",
                }
            ].map((elem: any, index: number) => {return (
                <div className={`w-flex mb-3 px-4`} key={index}>
                    <img src={elem?.imgUrl} alt={`img`} className={`w-flex rectangle b-3 mb-2`}></img>
                    <p style={{fontSize: `20px`}} className={`center semibold seminarrow mb px`}>{elem?.text}</p>
                    <p data-size={6} className={`center px`} data-color={`black-40-opacity`}>
                        {elem?.subText}
                    </p>
                </div>
            )})}

            <p data-size={4} className={`semibold seminarrow center mb-3 mt-5`}>{`Networking done right`}</p>

            {[
                {
                    text: "Networking events ❌",
                    subText: "A post-conference party where you would slap on a name tag, grab a drink at the bar, and elevator pitch to other attendees for hours, only to find out that 18 out of 20 people were irrelevant to your business goals. The other 2 ignore your DMs when you follow up on LinkedIn.",
                },
                {
                    text: "Osmos 👌",
                    subText: "You post your request and let AI match you with the perfect individual. You find the help you need and, in the process, cultivate authentic relationships with others who share your enthusiasm for cool things, all through collaboration on your request.",
                },
            ].map((elem: any, index: number) => {return (
                <div className={`w-flex mb-2`} 
                    style={{height: 224}} key={index}>
                    <div className={`block column left bottom b-3 p-2 h-flex`}>
                        <p style={{fontSize: 20}} className={`semibold seminarrow center mb-1`}>{elem?.text}</p>
                        <p data-size={6} className={`center`} data-color={`black-40-opacity`}>
                            {elem?.subText}
                        </p>
                    </div>
                </div>
            )})}
            <div className={`neg-mt-2`}></div>
        </div>
        </>}
    </div>
    
    {page === 0 && 
    <div className={`block px-3 pt-5 w-flex min-h-screen column`} data-color={`blue`} style={{position: `relative`}}>
        <div style={{position: `absolute`, top: -60, bottom: 0, left: 0, right: 0, overflow: `hidden`}}>        
            <video autoPlay={true} loop={true} muted={true} style={{
                        objectFit: `cover`, backgroundColor: `#000000`, objectPosition: `50% 50%`,                        
                    }} className={`w-flex h-flex`} controls={false}>
                <source src={`/api/static/media/landing_video_v1.mp4`} type="video/mp4"/>   
            </video>
        </div>
        <div className={`mb-7`}></div>
        <div className={`w-flex`} style={{position: `relative`, zIndex: 0}}>
            <div className={`column top`}>
                <h2 style={{fontSize: 38, lineHeight: `110%`}} className={`mb-1 center`}>
                    {`Unlock the power of `}
                    <span data-color={`yellow`} style={{lineHeight: `110%`}}>{`meaningful connections`}</span>
                </h2>
                <p data-size={5} data-color={`white`} className={`center mb-4`}>
                    {`Post your request, and let us play matchmaker for you.`}
                </p>
                
                <a className={`block btn-flex mobile`} href={`https://my.osmos.social/?force_logout=true`} style={{width: 187}}>
                    <p className={`center`}>{`Dive in`}</p>
                </a>
            </div>
        </div>
        <div className={`w-flex-p-3 neg-mx-3 px-2 row py-2 mt-7`} style={{position: `relative`, zIndex: 0}}>
            <a className={`btn mobile`} href={`https://my.osmos.social/privacy`}>
                <p data-size={8} className={`semibold seminarrow`}>{`Privacy policy`}</p>
            </a>
            <div className={`row gap-3 nowrap left`}>
                <a className={`btn row`} href={`https://www.linkedin.com/company/osmos-social`}>
                    <img src={LinkedInWhite} alt={``} className={`w-3`}></img>
                </a>
                <a className={`btn row`} href={`https://twitter.com/Osmos_social`}>
                    <img src={XWhite} alt={``} className={`w-3`}></img>
                </a>
            </div>
        </div>
    </div>}

    {page === 1 &&
    <div className={`block px-3 pt-5 w-flex min-h-screen column bottom`} data-color={`blue`} style={{position: `relative`}}>
        <div style={{position: `absolute`, top: -60, bottom: 0, left: 0, right: 0, overflow: `hidden`}}>        
            <video autoPlay={true} loop={true} muted={true} style={{
                        objectFit: `cover`, backgroundColor: `#000000`, objectPosition: `50% 50%`,                        
                    }} className={`w-flex h-flex`} controls={false}>
                <source src={`/api/static/media/landing_video_v1.mp4`} type="video/mp4"/>   
            </video>
        </div>

        <div className={`column top`} style={{position: `relative`, zIndex: 0, marginTop: 194, marginBottom: 224}}>
            <h2 style={{fontSize: 28, lineHeight: `110%`, maxWidth: 279}} className={`mb-1 center`}>
                Unlock the power of&nbsp;connections
            </h2>
            <p data-size={8} data-color={`white-60-opacity`} className={`center mb-4`}>
                {`If we focus more on doing cool stuff with interesting people and less on meeting interesting people, the latter tends to take care of itself. Now post your request, and let us play matchmaker for you.`}
            </p>
            
            <a className={`block btn-flex mobile`} href={`https://my.osmos.social/?force_logout=true`} style={{width: 187}}>
                <p className={`center`}>{`Create my request`}</p>
            </a>
        </div>

        <div className={`column top`} style={{position: `relative`, zIndex: 0, marginBottom: 224}}>
            <h2 style={{fontSize: 28, lineHeight: `110%`, maxWidth: 260}} className={`mb-1 center`}>
                No requests on&nbsp;your mind yet?
            </h2>
            <p data-size={8} data-color={`white-60-opacity`} className={`center mb-4`}>
                {`Explore industry-specific queries to discover how many people are seeking your expertise and professional background! Hit the `}
                <span data-color={`yellow`}>{`'I can help'`}</span> 
                {` button and start networking right away.`}
            </p>
            
            <a className={`block btn-flex mobile`} href={`https://my.osmos.social/?force_logout=true`} style={{width: 187}}>
                <p className={`center`}>{`Dive in`}</p>
            </a>
        </div>
        
        <div className={`w-flex-p-3 neg-mx-3 px-2 row py-2`} style={{position: `relative`, zIndex: 0}}>
            <a className={`btn desktop`} href={`https://my.osmos.social/privacy`}>
                <p data-size={8} className={`semibold seminarrow`}>{`Privacy policy`}</p>
            </a>
            <div className={`row gap-3 nowrap left`}>
                <a className={`btn row`} href={`https://www.linkedin.com/company/osmos-social`}>
                    <img src={LinkedInWhite} alt={``} className={`w-3`}></img>
                </a>
                <a className={`btn row`} href={`https://twitter.com/Osmos_social`}>
                    <img src={XWhite} alt={``} className={`w-3`}></img>
                </a>
            </div>
        </div>
    </div>} 
    </>)
}