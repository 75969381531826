import React, { useState, useEffect, Fragment, useRef, useContext, HTMLAttributes } from "react";
import { 
    AngleRightBlack, 
    AngleRightBlack2, 
    EyeBlack, 
    GoogleLogo, 
    HumanPlusBlack, 
    LinkedInLogo, 
    LocationBrightBlue, 
    LocationGray, 
    PlusBlack, 
    ShareBlack, 
    TrashCoral,     
} from "../elements/Imgs";
import { 
    SetDict, 
    ScreenColors, 
    IsLeftScroll, 
    IsRightScroll, 
    OnlyValid, 
    DateFromString, 
    CreateHSLColor, 
    Share, 
    RequestColors2, 
    Sticky
} from "../elements/Data";
import { 
    Edit, 
    FooterDesktop, 
    Loading, 
    NewAvatar, 
    SmallUserBlock, 
    TopBar, 
    SiteCardSmall,
} from "../elements/Elements.jsx";
import { 
    InfoPopup,  
    AddRequestPopup, 
    PopupCenterCanHelp,
    PopupCanHelpMobile,
    AddRequestDesktop,
    PopupHintMobile,
    ProfilePopupMobile,
    PopupCanHelpMobile2,
    ConfirmPopup,
    PopupCenterSuggestAFriend,
    ProfilePopup,
    PopupSuggestAFriendMobile,
    FirstOfferPopupMobile,
    InfoErrorPopup,
    PopupSubscriptionMobile,
} from "../elements/Popups";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { 
    CardDelete, 
    CardRecommendation, 
    CardRecommendationRequests, 
    CardRequest, 
    EmailAuth, 
    GoogleAuth, 
    LinkedInAuth, 
    NotificationsRequest, 
    ProfileRequest, 
    RelevanceRequest, 
    Subscribe, 
    SubscriptionsRequest
} from "../Requests";
import { useCookies } from "react-cookie";
import { FeedRequestCardDesktop, FeedRequestCardMobile } from "./RequestCards";
import { GlobalContext, ProfileContext } from "../elements/Contexts";
import Confetti from "react-confetti";
import { BottomNavBar, SiteCard, TopNavBar } from "../elements/Elems";

const CardMobile = (props: any) => { 
    const navigate = useNavigate(); 
    const [ cookie, setCookie ] = useCookies(['user_id_cookie']);
    const [ searchParams, setSearchParams ] = useSearchParams();
    const globalContext = useContext(GlobalContext);

    const [ loaded, setLoaded ] = useState<boolean>(false);
    const [ profile, setProfile ] = useState<any>();
    const [ ownerProfile, setOwnerProfile ] = useState<any>();
    const [ subscriptions, setSubscriptions ] = useState<{
        followers: any[],
        follow: any[],
    }>()
    const [ card, setCard ] = useState<any>();
    const [ similarRequests, setSimilarRequests ] = useState<any[]>();
    const [ recommendations, setRecommendations ] = useState<any[]>([]);
    const [ recommendationsAbortController, setRecommendationsAbortController ] = useState<AbortController>(new AbortController());
    const [ notifications, setNotifications ] = useState<any[]>();

    const [ rndColor, setRndColor ] = useState<string[]>([`gray`, `black`, `black`]);
    const [ showEditRequestPopup, setShowEditRequestPopup ] = useState<boolean>(false);
    const [ showDeleteRequestPopup, setShowDeleteRequestPopup ] = useState<string | null>(null);
    const [ clipBoardOk, setClipboardOk ] = useState<boolean>(false);
    const [ wishToHelpRequest, setWishToHelpRequest ] = useState<any>(null);
    const [ wishToHelpPopup2, setWishToHelpPopup2 ] = useState<boolean>(false);
    // const [ wishToHelpPopup1, setWishToHelpPopup1 ] = useState<boolean>(false);
    // const [ wishToHelpRequest1, setWishToHelpRequest1 ] = useState<any>();
    const [ startChatWithId, setStartChatWithId ] = useState<string | undefined>();
    const [ profilePopup, setProfilePopup ] = useState<any | null>(null);
    // const [ contextMenu, setContextMenu ] = useState<boolean>(false);
    const [ suggestAFriendPopup, setSuggestAFriendPopup ] = useState<any>(null);
    const [ activateSubscriptionPopup, setActivateSubscriptionPopup ] = useState<boolean>(false);
    const [ hintPopup, setHintPopup ] = useState<{
        show: boolean,
        title?: string,
        description?: any,
        hint?: string,
        buttonTitle?: string,
        onClick?: Function,
        close?: boolean,
    }>();
    const [ fullText, setFullText ] = useState<boolean>(false);
    const [ asOther, setAsOther ] = useState<boolean>(searchParams.get("as_other") === 'true');
    
    const [ recommendationsLoadingText, setRecommendationsLoadingText ] = useState<number>(0);
    const [ recommendationsUserNumber, setRecommendationsUserNumber ] = useState<number>(0);
    const texts: string[] = [
        `Analyzing your preferences`,
        `Scanning our database for suitable matches`,
        `Compiling a personalized list based on your criteria`,
        `Reviewing potential matches for your request`,
        `Finalizing your customized match list`,
    ]
    const [ textsTimer, setTextsTimer ] = useState<NodeJS.Timeout>();
    const [ showRelevanceSurvey, setShowRelevanceSurvey ] = useState<boolean>(false);
    const [ relevanceOption, setRelevanceOption] = useState<number>();
    const [ relevanceDescription, setRelevanceDescription] = useState<string>('');
    const relevanceOptions = ["Not enough info in profile", "Does not match my request", "Other"];
    const [ enableCongrats, setEnableCongrats ] = useState<boolean>(false);
    const [ enableCongratsPopup, setEnableCongratsPopup ] = useState<boolean>(false);

    const avatarsBlock = useRef<HTMLDivElement | null>(null)

    const GetRecommendations = () => {
        return JSON.parse(sessionStorage.getItem('store_recommendations') ?? '{}');
    }
    const SetRecommendations = (id: string, recommendations: any) => {
        const currentRec = JSON.parse(sessionStorage.getItem('store_recommendations') ?? '{}');
        sessionStorage.setItem('store_recommendations', JSON.stringify(SetDict(currentRec, [id], recommendations)))
    }

    useEffect(() => {
        setShowRelevanceSurvey(false);
        setRelevanceDescription('');
        setRelevanceOption(undefined);

        if (avatarsBlock.current) {
            (avatarsBlock.current as HTMLDivElement).scrollTo({
                left: -(avatarsBlock.current as HTMLDivElement).offsetWidth / 2 + 28 + recommendationsUserNumber * 48,
                behavior: `smooth`,
            })
        }

        const aboutReadMoreElem = document.querySelector(`#read-more-about`) as HTMLDivElement;
        if (aboutReadMoreElem) {
            setRecommendations(val => SetDict(
                val, [recommendationsUserNumber, "user", "openAbout"], 
                aboutReadMoreElem?.offsetHeight === aboutReadMoreElem?.scrollHeight
            ))
        }

        (recommendations[recommendationsUserNumber]?.user?.requests ?? []).forEach((elem: any, index: number) => {
            const requestReadMoreElem = document.querySelector(`#read-more-requests-${index}`) as HTMLDivElement;
            if (requestReadMoreElem) {
                setRecommendations(val => SetDict(
                    val, [recommendationsUserNumber, "user", "requests", index, "openRequest"], 
                    requestReadMoreElem?.offsetHeight === requestReadMoreElem?.scrollHeight
                ))
            }
        });
    }, [recommendations?.length, recommendationsUserNumber])

    const { num } = useParams();

    useEffect(() => {
        if (recommendations?.length) {
            setRndColor(ScreenColors[Math.floor(Math.random() * 3)]);
        }
        if (recommendations?.length > 0) {
            SetRecommendations(card?.id, recommendations);
        }
    }, [recommendations?.length])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: `instant`,
        });

        if (num) {        
            CardRequest(num, setProfile, () => {
                ProfileRequest(cookie[`user_id_cookie`], setProfile, () => {
                    navigate('/404');
                })
            });
        } else {
            navigate(`/profile`);
        }
    }, [num])

    useEffect(() => {
        // if (profile && profile?.id !== cookie[`user_id_cookie`]) {
        //     navigate(`/profile/${profile?.id}?request_id=${encodeURIComponent(num ?? '')}`)
        // }
    }, [profile])

    useEffect(() => {
        if (ownerProfile && !ownerProfile?.subscription?.is_subscription_active && !asOther) {
            setActivateSubscriptionPopup(true);
        }
    }, [ownerProfile])

    useEffect(() => {
        if (profile) {
            if (profile.requests.find((val: any) => val.id === num)) {
                setCard(profile.requests.find((val: any) => val.id === num));
            } else if (Number(num) < profile.requests?.length) {
                setCard(profile.requests[Number(num)]);
            } else {
                navigate('/404');
            }
            setLoaded(true);

            if (profile.id === cookie[`user_id_cookie`]) {
                setOwnerProfile(profile);
            } else if (!ownerProfile) {
                ProfileRequest(cookie[`user_id_cookie`], setOwnerProfile, () => {
                    setCookie("user_id_cookie", undefined);
                }) 
                NotificationsRequest(cookie[`user_id_cookie`], setNotifications);  
                SubscriptionsRequest(cookie[`user_id_cookie`], setSubscriptions);             
            }
        }
    }, [profile?.id, profile?.requests])
    
    const SetRecommendationsAgain = (id: string, text: string, setInfo: Function) => {
        setInfo([]);
        recommendationsAbortController.abort();
        const newAbortController = new AbortController();
        setRecommendationsAbortController(newAbortController);
        CardRecommendation(
            id, 
            text,
            setInfo,
            {}, newAbortController.signal,                
        )
    }

    useEffect(() => {
        if (card?.answers[0]) {
            if (GetRecommendations()[card?.id]?.length === 10) {
                setRecommendations(GetRecommendations()[card?.id]);
            } else {
                setRecommendations([]);
                SetRecommendationsAgain(
                    profile?.id, 
                    card?.answers[0],
                    setRecommendations,
                )
            }
            setSimilarRequests([]);
            CardRecommendationRequests(
                profile?.id,
                card.answers[0],
                setSimilarRequests,
                () => {}, () => {}, 8, false,
            )
        }

        if (card?.answers[0] && 
            (searchParams.get(`first_login`) === `true` || sessionStorage.getItem(`first_login_requests`) === `true`) && 
            cookie[`user_id_cookie`] === profile?.id) {
            document.documentElement.style.overflowY = 'hidden';
            setHintPopup({
                show: true,
                title: `Your requests`,
                description: `When updating requests or modifying current ones, Osmos delivers a stream of relevant professionals.`,
                hint: `Key: Stay sharp, focused, and industry-specific. Showcase how assisting you holds relevance for your audience.`,
                buttonTitle: `Sounds great!`,
                onClick: () => {
                    // setSearchParams({first_login: `false`}) 
                    setHintPopup({
                        show: true,
                        title: `People that match your request `,
                        description: `Explore a lineup of professionals tailored to your requests. Take the lead in engaging with relevant people without delay — something incredible is just a handshake away, so make every moment count!`,
                        buttonTitle: `Sounds great!`,
                        onClick: () => { 
                            setSearchParams({first_login: `false`});
                            sessionStorage.removeItem(`first_login_requests`);                             
                        },
                        close: true,
                    })
                },
                close: false,
            })
        }  

        if (card?.user?.id && card?.user?.id !== cookie[`user_id_cookie`]) {
            setAsOther(true)
        }
    }, [card])

    useEffect(() => {    
        if (card && loaded) {
            setRecommendationsLoadingText(0);            
            setTextsTimer(setInterval(() => {
                setRecommendationsLoadingText((val: number) => Math.min(val + 1, 4))
            }, 1100))
        } else if (!!textsTimer) {
            clearInterval(textsTimer);
            setRecommendationsLoadingText(-1);
        }
    }, [card, loaded])
    
    return(<ProfileContext.Provider value={{
            notifications: notifications, setNotifications: setNotifications,
            subscriptions: subscriptions, setSubscriptions: setSubscriptions,
            setProfilePopup: setProfilePopup, setSuggestAFriendPopup: setSuggestAFriendPopup,
            setEnableCongrats: setEnableCongrats,
            setEnableCongratsPopup: setEnableCongratsPopup,
            profile: ownerProfile, setProfile: setOwnerProfile,
            isSubscriptionActive: ownerProfile?.subscription?.is_subscription_active,
        }}>
        <div className={`body top`}>
            <TopBar notification={3} className={`mb-2 w-flex`} 
                    func={() => {
                        navigate(cookie["user_id_cookie"] ? `/profile/feed` : `/?nologin=true`);
                    }} toTop={false} profile={ownerProfile} setProfile={setOwnerProfile} color={`light-gray`}/>
  
            <div className={`px-3`}>
            {cookie[`user_id_cookie`] === profile?.id && 
            <button className={`btn-circled-40 mobile p-left row left mb-2`} onClick={() => { navigate(`/profile`) }}>
                <img src={AngleRightBlack} alt={`>`} className={`w-3`} style={{transform: `rotate(180deg)`}}></img>
                <p data-size={5}>{`Back to profile`}</p>
            </button>}
            {loaded ? <>
                <div className={`w-flex mb-2`}
                    style={{position: `relative`}}>
                    {asOther ?
                    <FeedRequestCardMobile className={`w-flex`} profile={profile} request={card}
                                            mobile={true} onlyValid={true} onAvatarClick={() => {
                                                if (window.getSelection()?.type !== "Range") {
                                                    const newWindow: WindowProxy | null = window.open(`/profile/${card?.user?.id}`, '_blank');
                                                    if (newWindow) {
                                                        newWindow.focus();
                                                    }
                                                }
                                            }} showAnywhere={true}/> : 
                    <div className={`block w-flex p-2 b-2`} data-color={typeof card?.style?.background === 'number' ? RequestColors2[card?.style?.background]?.color : `gray`}>
                        <p className={`mb-1`} data-size={6} style={{opacity: 0.4}} 
                           data-color={typeof card?.style?.background === 'number' ? RequestColors2[card?.style?.background]?.textSecondaryColor : `black`}>
                            {DateFromString(card?.created_at)}
                        </p>
                        <div className={`w-flex`}>
                            <div data-lines={fullText ? null : 7} id={`request-text-field`} style={{wordBreak: `break-word`}}>
                                {(card?.answers ?? []).filter((val: string) => !!val).reduce((init: string, elem: string) => {
                                    return `${init}${init && '\n'}${elem}`;
                                }, '').trim().split(`\n`).map((elem: string, index: number) => {return ( elem ?
                                    <p data-size={6} className={`article`} key={index}
                                       data-color={typeof card?.style?.background === 'number' ?  RequestColors2[card?.style?.background]?.textSecondaryColor : `black`}>
                                        {elem.trim()}
                                    </p>
                                : null)})}
                            </div>
                            {!fullText && document.querySelector(`#request-text-field`) && 
                            (document.querySelector(`#request-text-field`) as HTMLDivElement).offsetHeight < (document.querySelector(`#request-text-field`) as HTMLDivElement).scrollHeight &&
                            <button className={`btn mobile mt`} onClick={() => { setFullText(true) }}>
                                <p data-size={6} className={`semibold`} data-color={`black`}>{`Read more`}</p>
                            </button>}

                            <div className={`row nowrap mt-2`}>
                                <button className={`block btn mobile centered b px-1`} data-color={`white`} type={`submit`}
                                        style={{height: 40}}
                                        onClick={e => {
                                            const newWindow: WindowProxy | null = window.open(`/request/${card.id}?as_other=true`, '_blank');
                                            if (newWindow) {
                                                newWindow.focus();
                                            }
                                        }}>
                                    <img src={EyeBlack} alt={``} className={`w-3`}></img>
                                </button>
                                <div className={`row nowrap right`}>
                                    <button className={`block btn mobile centered b px-1`} data-color={`white`} type={`submit`}
                                            style={{height: 40}}
                                            onClick={e => {
                                                e.stopPropagation();
                                                setShowEditRequestPopup(true)
                                            }}>
                                        <Edit color={'black'} className={`w-3 h-3`} hover={false}/>
                                    </button>
                                    <button className={`block btn mobile centered b px-1`} data-color={`white`} type={`submit`}
                                            style={{height: 40}} hidden={profile?.requests?.length < 2}
                                            onClick={e => {
                                                e.stopPropagation();
                                                setShowDeleteRequestPopup(card.id);
                                            }}>
                                        <img src={TrashCoral} alt={``} className={`w-3`}></img>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>

                {(cookie[`user_id_cookie`] !== profile?.id || asOther) &&
                (!!similarRequests?.length && <>
                    <div className={`row left nowrap px-1 mt-3 mb-2`}>
                        <div className={`block min-w-3 h-3 centered b-2`} data-color={`black`}>
                            <p data-size={6} className={`px-1`}>{similarRequests?.length}</p>
                        </div>
                        <p data-size={5} className={`seminarrow semibold`} data-color={rndColor[2]}>{`Similar requests`}</p>
                    </div>
                    {similarRequests.map((elem, index) => { return (
                    <Fragment key={index}>
                        <FeedRequestCardMobile className={`mb-1 w-flex`} profile={elem.user} request={elem.card}
                                                mobile={true} onlyValid={true} index={index} onAvatarClick={() => {
                                                    if (window.getSelection()?.type !== "Range") {
                                                        const newWindow: WindowProxy | null = window.open(`/profile/${elem.user?.id}`, '_blank');
                                                        if (newWindow) {
                                                            newWindow.focus();
                                                        }
                                                    }
                                                }} onRequestClick={() => {
                                                    navigate(`/request/${elem?.card?.id}`)
                                                }}/>
                    </Fragment>)})}
                </>)} 

                {(cookie[`user_id_cookie`] !== profile?.id || asOther) ?
                <div className={`block p-2 b-3 w-flex mb-3`} data-color={cookie[`user_id_cookie`] === profile?.id ? rndColor[0] : null}>
                    <div className={`row nowrap top left noscroll mb-2 px-1 pt-1`}>
                        <div className={`block min-w-3 h-3 b-3 centered noshrink`} data-color={rndColor[2]}>
                            {!!recommendations?.length ? 
                            <p data-size={6} className={`narrow px-1`}>{recommendations?.length}</p> :
                            <Loading color={rndColor[2] === `white` ? `black` : `white`} width={14} height={14}/>}
                        </div>
                        <p data-size={5} className={`semibold mb-2`} data-color={rndColor[2]}>
                            {cookie[`user_id_cookie`] === profile?.id ? `People that match your request` : `Interesting people`}
                        </p>
                    </div>
                    
                    <div className={`px-2 neg-mx-2 row left x-auto noshrink nowrap mobile gap-2`}>
                        {recommendations?.length ?
                        recommendations.map((elem, index) => {return (
                            <SmallUserBlock profile={elem?.user} index={index} mobile={true} key={index}
                                            setRecommendations={setRecommendations} onClick={() => { 
                                                setProfilePopup({
                                                    ...elem?.user, 
                                                    onStartChat: () => {
                                                        setWishToHelpRequest({
                                                            ...card, 
                                                            user_id: elem?.user?.id,
                                                            avatar: profile?.avatar,
                                                            name: profile?.name,
                                                            occupation: profile?.occupation,
                                                        });
                                                        setWishToHelpPopup2(true);
                                                        setProfilePopup(null);
                                                    }
                                                }) 
                                            }}
                                            onCanHelp={() => {
                                                if (cookie[`user_id_cookie`] === profile?.id) {
                                                    setWishToHelpRequest({
                                                        ...card, 
                                                        user_id: elem?.user?.id,
                                                        avatar: profile?.avatar,
                                                        name: profile?.name,
                                                        occupation: profile?.occupation,
                                                    });
                                                    setWishToHelpPopup2(true);
                                                } else {
                                                    setStartChatWithId(elem?.user?.id);
                                                }
                                            }} color={cookie[`user_id_cookie`] === profile?.id ? null : `light-gray`}/>
                        )}) :
                        [1, 2, 3].map((elem, index) => {return (
                            <div className={`block b-3 p-2 column top`} key={index} data-border={`gray-1`}>
                                <NewAvatar size={0} className={`mb-2 circled`}/>
                                <div style={{height: 61}}>
                                    <div className={`skeleton b-4 h-3 mb-1`} style={{width: 180}}></div>
                                    <div className={`skeleton b-3 h-2 mb-2`} style={{width: 195}}></div>
                                </div>
                                <div className={`skeleton b-4`} style={{width: 198, height: 40}}></div>
                            </div>
                        )})}
                    </div>
                </div> :                 
                (false && ((recommendations?.length > 0) ? 
                <div className={`block w-flex b-3 p-3 top column left mb-3`} style={{height: 594, position: `relative`}}>
                    <div className={`row top nowrap w-flex mb-2`}>
                        <p data-size={5} className={`semibold seminarrow`}>
                            {`People that match your request`}
                        </p>
                        <div className={`block label btn-circled-24 mobile`} data-color={`light-gray`}>
                            <p style={{whiteSpace: `nowrap`}} className={`regular`} data-size={6}>{`${recommendationsUserNumber + 1}/${recommendations?.length}`}</p>
                        </div>
                    </div>
                    <div className={`row w-flex nowrap mb-3`} hidden={true}>
                        <button className={`block btn-circled-40 mobile p-left p-right`} data-color={`light-gray`}
                                onClick={() => { setRecommendationsUserNumber(val => Math.max(0, val - 1)) }}>
                            <div className={`btn-symbol centered w-3 h-3`} style={{opacity: 0.4, transform: `rotate(180deg)`}}>
                                <img className={`not-scale w-3 neg-m`} src={AngleRightBlack2} alt={`>`}></img>
                            </div>
                        </button>
                        <div className={`row nowrap neg-m p nogap left x-auto`} ref={avatarsBlock}>
                            {recommendations.map((elem: any, index: number) => {return (
                                <div className={`block neg-my`} data-border={`${index === recommendationsUserNumber ? `bright-blue` : `white`}-4`}
                                    data-color={index === recommendationsUserNumber ? `bright-blue` : `white`} style={{ borderRadius: 13 }} 
                                    onClick={() => { setRecommendationsUserNumber(index) }} key={index}>
                                    <NewAvatar name={elem?.user?.name} size={4} avatar={elem?.user?.avatar?.link} 
                                            type={elem?.user?.avatar?.style} key={index} 
                                            status={elem?.user?.notRelevant ? 'coral' : (elem?.user?.followed ? `green` : null)}/>
                                </div>
                            )})}
                        </div>                        
                        <button className={`block btn-circled-40 mobile p-left p-right`} data-color={`light-gray`}
                                onClick={() => { setRecommendationsUserNumber(val => (recommendations?.length - 1, val + 1) % recommendations?.length) }}>
                            <div className={`btn-symbol centered w-3 h-3`} style={{opacity: 0.4}}>
                                <img className={`not-scale w-3 neg-m`} src={AngleRightBlack2} alt={`>`}></img>
                            </div>
                        </button>
                    </div>

                    {showRelevanceSurvey ? <>
                    <div className={`block w-flex p-2 mb-3 h-flex column top left`} data-border={`gray-4`} style={{borderRadius: 18}}>
                        <p data-size={5} className={`semibold seminarrow mb-3 noshrink`}>
                            {`Give us your feedback on ${recommendations[recommendationsUserNumber]?.user?.fname ?? 'user'}’s profile`}
                        </p>

                        {relevanceOptions.map((elem: string, index: number) => {return (
                            <button className={`btn desktop row top nowrap left w-flex ${index + 1 < relevanceOptions?.length ? 'mb-1' : ''}`} key={index}
                                    onClick={() => {setRelevanceOption(index)}}>
                                <div className={`checkbox-radio w-3 h-3 b-2 centered noshrink`} data-checked={relevanceOption === index}>
                                    <div className={`checkbox-center h-1 w-1 b`}></div>
                                </div>
                                <p data-size={6} data-color={relevanceOption === index ? 'black' : 'dark-gray'} style={{marginTop: 2}}>{elem}</p> 
                            </button>
                        )})}

                        <textarea className={`desktop w-flex h-flex b-1 p-2 mt-2`} value={relevanceDescription} onChange={e => { 
                                        setRelevanceDescription(e.target.value);
                                        setRelevanceOption(val => !val ? relevanceOptions.findIndex(val_ => val_ === "Other") : val);
                                    }} data-size={6}
                                placeholder={`Share deeper insights. Provide additional feedback to help us understand your preferences better.`}></textarea>
                    </div>
                    <div className={`row w-flex`}>
                        <button className={`block btn-circled-40 mobile row center`} data-color={`black`} style={{width: 100}}
                            disabled={relevanceOption === undefined || (relevanceOptions[relevanceOption ?? -1] === "Other" && !relevanceDescription?.length)}
                            onClick={() => {
                                RelevanceRequest(recommendations[recommendationsUserNumber]?.user?.id, [relevanceOptions[relevanceOption ?? 2], relevanceDescription ?? ''], false, () => {
                                    setRecommendations(val => SetDict(
                                        val, 
                                        [recommendationsUserNumber, "user", "notRelevant"], 
                                        true
                                    ));
                                    setShowRelevanceSurvey(false);
                                    setRelevanceDescription('');
                                    setRelevanceOption(undefined);
                                    if (recommendations?.length === 1) {
                                        setCard((val: any) => SetDict(
                                            val, ['reloadRecommendations'], 
                                            (card?.reloadRecommendations ?? -1) + 1,
                                        ))
                                    }
                                    setRecommendations(val => val.filter((val_, index: number) => index !== recommendationsUserNumber));
                                });
                            }}>
                            <p>{`Send`}</p>
                        </button>
                        <button className={`block btn-circled-40 mobile row center`} data-color={`light-gray`} style={{width: 100}}
                                onClick={() => {
                                    setRecommendationsUserNumber(val => (recommendations?.length - 1, val + 1) % recommendations?.length);
                                }}>
                            <p>{`Skip`}</p>
                        </button>
                    </div>
                    </> : <>
                    <div className={`label block btn-rectangle-24 mobile`} data-color={`light-blue`}
                            style={{position: `absolute`, bottom: 86, right: 32, borderRadius: 8}} 
                            hidden={!recommendations[recommendationsUserNumber]?.user?.responsive}>
                        <p data-color={`blue`}>{`Responsive`}</p>
                    </div>
                    <div className={`block w-flex b-2 px-2 pt-2 mb-2 h-flex y-auto`} data-border={`gray-4`} style={{borderRadius: 18}}>
                        <div className={`block w-flex row left top nowrap mb-2`}>
                            <NewAvatar size={5} name={recommendations[recommendationsUserNumber]?.user?.name} 
                                    avatar={recommendations[recommendationsUserNumber]?.user?.avatar?.link}
                                    type={recommendations[recommendationsUserNumber]?.user?.avatar?.style} style={{transitionDuration: `0s`}}/>
                            <div className={`left column`} style={{
                                    minHeight: 58,
                                    width: `calc(100% - 66px)`,
                                }}>
                                <div className={`w-flex`}>
                                    <a href={`/profile/${recommendations[recommendationsUserNumber]?.user?.unique_id ?? recommendations[recommendationsUserNumber]?.user?.id}`}>
                                        <p data-size={8} className={`semibold`} data-color={`black`}>{recommendations[recommendationsUserNumber]?.user?.name}</p>
                                    </a>   
                                    <p data-size={6} data-lines={2} data-color={`black-40-opacity`}>
                                        {recommendations[recommendationsUserNumber]?.user?.occupation}
                                    </p> 
                                </div>
                            </div>
                        </div>
                        {recommendations[recommendationsUserNumber]?.user?.industry?.length > 0 &&
                        <div className={`my row left w-flex h-3 mb-1`} style={{overflow: `hidden`}}>
                            {recommendations[recommendationsUserNumber]?.user?.industry.map((elem: string, index: number) => {return (
                                <div className={`block label btn-circled-24 mobile`} key={index} data-color={`transparent`} data-border={true}
                                     style={{border: `solid 1px ${CreateHSLColor(elem)}`}}>
                                    <p style={{color: CreateHSLColor(elem)}} className={`not-active`}>{elem}</p>
                                </div>
                            )})}
                        </div>}                        
                        <div className={`row nowrap nogap mb`} 
                            hidden={!recommendations[recommendationsUserNumber]?.user?.location?.city}>
                            <img src={LocationBrightBlue} alt={``} style={{width: 16}}></img>
                            <p data-size={7} data-color={"bright-blue"} className={`w-flex ellipsis seminarrow semibold`}>
                                {recommendations[recommendationsUserNumber]?.user?.location?.city}
                            </p>
                        </div>                        
                        <div className={`w-flex`} id={`read-more-about`} data-lines={recommendations[recommendationsUserNumber]?.user?.openAbout ? 50000 : 6}>
                            <p data-size={6}>
                                {recommendations[recommendationsUserNumber]?.user?.about}
                            </p>
                        </div>
                        <p data-size={6} className={`semibold seminarrow clickable`} 
                            onClick={() => { setRecommendations(val => SetDict(val, [recommendationsUserNumber, "user", "openAbout"], true)) }}
                            hidden={ recommendations[recommendationsUserNumber]?.user?.openAbout }>
                            {`Read more`}
                        </p>

                        {!!OnlyValid(recommendations[recommendationsUserNumber]?.user?.requests)?.length &&
                        <div className={`block p-2 b-2 w-flex-p-2 neg-mx-2 mt-2`} data-color={`light-gray`}>
                            <div className={`row left nowrap mb-2`}>
                                <div className={`block min-w-3 h-3 centered b-2`} data-color={rndColor[2]}>
                                    <p data-size={6} className={`px-1`}>{OnlyValid(recommendations[recommendationsUserNumber]?.user?.requests)?.length}</p>
                                </div>
                                <p data-size={8} className={`seminarrow semibold`} data-color={rndColor[2]}>{`Requests`}</p>
                            </div>
                            {OnlyValid(recommendations[recommendationsUserNumber]?.user?.requests).map((elem: any, index: number) => {return (<Fragment key={index}>
                                <div className={`w-flex p-2 clickable block b-2 mb-1`} onClick={() => { 
                                    const newWindow: WindowProxy | null = window.open(`/request/${elem?.id}`, '_blank');
                                    if (newWindow) {
                                        newWindow.focus();
                                    }
                                }}>
                                    <div className={`neg-mx-1 px-1 w-flex-p-1`}
                                         data-lines={elem?.openRequest ? 50000 : 3}
                                         id={`read-more-requests-${index}`}>
                                        {elem?.answers.reduce((init: string, elem: string) => {
                                            return `${init}${init && '\n'}${elem.trim()}`;
                                        }, '').trim().split(`\n`).map((elem: string, index: number) => {return ( elem !== '' ?
                                        <p data-size={6} className={`article`} data-color={'black'} key={index}>
                                            {elem}
                                        </p> : null)})}
                                    </div>
                                    <p data-size={6} className={`semibold seminarrow clickable`} 
                                        onClick={e => { 
                                            e.stopPropagation();
                                            setRecommendations(val => SetDict(val, [recommendationsUserNumber, "user", "requests", index, "openRequest"], true)); 
                                        }}
                                        hidden={ elem?.openRequest }>
                                        {`Read more`}
                                    </p>
                                    <div className={`row w-flex nowrap mt-1`}>
                                        <button className={`block btn-circled-32 mobile row`} data-color={`black`} onClick={e => {
                                            e.stopPropagation();
                                            // setWishToHelpRequest1({...elem, user_id: elem?.user?.id});
                                            // setWishToHelpPopup1(true);
                                            sessionStorage.setItem("new_chat_offer", JSON.stringify({
                                                created_at: Date.now() / 1000,    
                                                items: [],
                                                pair: elem?.user,
                                                user: ownerProfile,
                                                is_new_requested: true, 
                                                is_can_help: true,
                                                refer_to_request: elem,
                                            }));
                                            navigate(`/profile/chat?new_chat_offer=true`);
                                        }}>
                                            <p>{`I can help`}</p>
                                        </button>
                                        <button className={`block btn-circled-32 mobile p-both row`} data-color={'light-gray'}
                                            onClick={e => {
                                                e.stopPropagation(); 
                                                Share(`${window.location.origin}/share/${elem?.id}?from_user=${cookie[`user_id_cookie`]}`, () => {
                                                    globalContext.setClipboardOk(true);
                                                }) 
                                            }} type={'button'} id={`share-feed-btn`} disabled={false}>
                                        <img src={ShareBlack} className={`w-3`} alt={``}></img>
                                    </button>
                                    </div>
                                </div>
                            </Fragment>)})}
                            <div className={`neg-mb-1`}></div>
                        </div>}

                        {!!recommendations[recommendationsUserNumber]?.user?.projects?.length && 
                        <div className={`block p-2 b-2 w-flex-p-2 neg-mx-2 mt-2`} data-color={`light-gray`}>
                            <div className={`row nowrap`}>
                                <div className={`row left nowrap`}>
                                    <div className={`block min-w-3 h-3 centered b-2`} data-color={rndColor[2]}>
                                        <p data-size={6} className={`px-1`}>{recommendations[recommendationsUserNumber]?.user?.projects?.length}</p>
                                    </div>
                                    <p data-size={8} className={`seminarrow semibold`} data-color={rndColor[2]}>{`Projects`}</p>
                                </div>
                            </div>
                            <div className={`row nogap nowrap min-w-flex neg-mx-2 mt-2`}>
                                <div className={`row x-auto left top noshrink nowrap nogap w-flex px-2`}>
                                    {recommendations[recommendationsUserNumber]?.user?.projects.map((elem : any, index: number) => {return (
                                        <SiteCardSmall project={elem} key={index} className={`${!!index ? `ml-2` : ``}`} 
                                                    mobile={true} orderNumber={index}/>
                                    )})}
                                </div> 
                            </div> 
                        </div>}
                    </div>                    
                    <div className={`row w-flex-p-1 neg-mx-1 neg-mb-1 nowrap block b-4 py px-1`} data-color={`light-gray`}>
                        <button className={`btn mobile row`} onClick={() => { setRecommendationsUserNumber(val => Math.max(0, val - 1)) }}>
                            <div className={`btn-symbol centered w-3 h-3`} style={{transform: `rotate(180deg)`}}>
                                <img className={`not-scale w-3 neg-m`} src={AngleRightBlack2} alt={`>`}></img>
                            </div>
                        </button>
                        <button className={`btn mobile row`} onClick={() => {
                                    setShowRelevanceSurvey(true);
                                }}>
                            <div className={`btn-symbol w-3 h-3 centered close`} data-color={'black'}>
                                <img src={PlusBlack} alt={`+`}></img>
                            </div>
                        </button>
                        <button className={`block btn-circled-40 mobile row p-left p-right`} data-color={`black`}
                                onClick={() => {
                                    // setWishToHelpRequest({
                                    //     ...card,                                                            
                                    //     avatar: profile?.avatar,
                                    //     name: profile?.name,
                                    //     occupation: profile?.occupation,
                                    //     user_id: recommendations[recommendationsUserNumber]?.user?.id,
                                    //     onSent: () => { setRecommendationsUserNumber(val => (recommendations?.length - 1, val + 1) % recommendations?.length) }
                                    // });
                                    // setWishToHelpPopup2(true);
                                    sessionStorage.setItem("new_chat_offer", JSON.stringify({
                                        created_at: Date.now() / 1000,    
                                        items: [],
                                        pair: recommendations[recommendationsUserNumber]?.user,
                                        user: profile,
                                        is_new_requested: true, 
                                        refer_to_request: card,
                                    }));
                                    navigate(`/profile/chat?new_chat_offer=true`);
                                }}>
                            <p className={`px-1`}>{`Contact me`}</p>
                        </button>
                        <button className={`btn mobile row`} onClick={() => {
                                    Subscribe(
                                        cookie[`user_id_cookie`],
                                        recommendations[recommendationsUserNumber]?.user?.id,
                                        !recommendations[recommendationsUserNumber]?.user?.followed,
                                        () => { 
                                            setRecommendations(val => SetDict(
                                                val, 
                                                [recommendationsUserNumber, "user", "followed"], 
                                                !recommendations[recommendationsUserNumber]?.user?.followed
                                            ));
                                            setRecommendationsUserNumber(val => (recommendations?.length - 1, val + 1) % recommendations?.length);
                                        },
                                    ) 
                                }}>
                            <img src={HumanPlusBlack} alt={``} className={`w-3`}></img>
                        </button>                     
                        <button className={`btn mobile row`} onClick={() => { setRecommendationsUserNumber(val => (recommendations?.length - 1, val + 1) % recommendations?.length) }}>
                            <div className={`btn-symbol centered w-3 h-3`}>
                                <img className={`not-scale w-3 neg-m`} src={AngleRightBlack2} alt={`>`}></img>
                            </div>
                        </button>
                    </div></>}
                </div> :
                <div className={`block w-flex b-3 p-3 mb-3 column top left`} style={{height: 594}}>
                    <p data-size={5} className={`semibold seminarrow mb-2`}>
                        {`Searching for your personalized match list based on your request...`}
                    </p>
                    <div className={`row w-flex center nowrap mb gap-0`}>
                        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((elem: number) => {return (
                            <div className={`b-1 skeleton`} key={elem} style={{
                                opacity: elem > recommendationsLoadingText * 2 + 1 ? 0.5 : 1,
                                width: 40, height: 8,
                            }}></div>
                        )})}
                    </div>
                    <p data-size={8} className={`mb-2`}>
                        {texts[recommendationsLoadingText]}
                    </p>
                    <div className={`block w-flex h-flex b-2 skeleton mb-2`}></div>
                    <div className={`block row w-flex-p-1 neg-mx-1 neg-mb-1 b-4 p skeleton`}>
                        <div className={`block label btn-circled-40 mobile`}><div className={`w-3 h-3`}></div></div>
                    </div>
                </div>))}
            </> : <>
                <div className={`skeleton b-3 w-flex h-9 mb-3`}></div>
                <div className={`skeleton b-3 w-flex h-8 mb-3`}></div>
            </>}
            </div>

            {cookie['user_id_cookie'] ? 
            <BottomNavBar other={true} profile={ownerProfile}/> :
            <FooterDesktop isMobile={true}/>}  

            {/* {wishToHelpPopup1 &&
            <PopupCanHelpMobile show={wishToHelpPopup1} setShow={setWishToHelpPopup1} mobile={true} request_={{...card, user_id: profile?.id}} request={wishToHelpRequest1}/>}  */}

            {wishToHelpPopup2 &&
            <PopupCanHelpMobile2 show={wishToHelpPopup2} setShow={setWishToHelpPopup2} mobile={true} 
                                request={wishToHelpRequest} onRequested={(id: string) => {
                                    setOwnerProfile((val: any) => SetDict(val, [`is_requested`], val.is_requested.concat([id])));
                                }}/>}

            {clipBoardOk && 
            <InfoPopup show={clipBoardOk} setShow={setClipboardOk} hideBg={true}
                        title={`Link successfully copied to clipboard`}
                        buttonTitle={`Ok, I got it!`} onOk={() => {
                            
                        }} mobile={true} noButton={true}/>}

            {showDeleteRequestPopup &&
            <ConfirmPopup title={`Are you sure you want to delete your request?`} show={showDeleteRequestPopup} setShow={setShowDeleteRequestPopup} _id={`yesno`}
                        color_1={'black'} buttonTitle_1={`No`} onClick_1={() => {}} black={false} mobile={true}
                        color_2={'light-gray'} buttonTitle_2={`Yes`} onClick_2={() => {
                            CardDelete(showDeleteRequestPopup, () => {
                                setShowDeleteRequestPopup(null); 
                                navigate(`/profile/requests`)
                            }, () => {
                                setShowDeleteRequestPopup(null); 
                            })
                        }} close={'first'}/>}
        
            {!!profilePopup &&
            <ProfilePopupMobile user={profilePopup} setUser={setProfilePopup}
                                onSubscribe={() => {}}/>}
                        
            {showEditRequestPopup &&  
            <AddRequestPopup show={showEditRequestPopup} setShow={setShowEditRequestPopup} canClose={true}
                            onSubmit={() => {
                                CardRequest(num, setProfile, () => {
                                    ProfileRequest(cookie[`user_id_cookie`], setProfile, () => {
                                        navigate('/404');
                                    })
                                });
                            }} onDelete={() => {
                                navigate(`/profile/requests`);
                            }} onClose={() => { setShowEditRequestPopup(false) }} canDelete={profile?.requests?.length > 1}
                            headerId={`add-request-popup-content`} request={card}/>}

            {hintPopup?.show &&
            <PopupHintMobile show={hintPopup?.show} setShow={(show: boolean) => { setHintPopup(val => SetDict(val, [`show`], show)) }} onClick={hintPopup?.onClick}
                            title={hintPopup?.title} description={hintPopup?.description} buttonTitle={hintPopup?.buttonTitle} close={hintPopup?.close} hint={hintPopup?.hint}/>} 

            {!!startChatWithId &&
            <PopupCanHelpMobile show={!!startChatWithId} setShow={(bool: boolean) => { setStartChatWithId(val => bool ? val : undefined) }} 
                                desktop={true} request={{user_id: startChatWithId}}/>}

            {!!suggestAFriendPopup &&
            <PopupSuggestAFriendMobile show={!!suggestAFriendPopup} request={suggestAFriendPopup}
                                    setShow={(show: boolean) => { setSuggestAFriendPopup((val: any) => show ? val : null) }}/>}

            {activateSubscriptionPopup && 
            <PopupSubscriptionMobile show={activateSubscriptionPopup} setShow={setActivateSubscriptionPopup}/>}

            {enableCongratsPopup &&
            <FirstOfferPopupMobile show={enableCongratsPopup} setShow={setEnableCongratsPopup}/>}

            <div style={{position: `fixed`, top: 0, left: 0}}>
                <Confetti 
                    drawShape={ctx => {
                        // ctx.fillRect(7, 7, 10, 10);
                        ctx.arc(0, 0, 4, 0, 360);
                        ctx.fill();
                    }} recycle={enableCongrats}
                    numberOfPieces={enableCongrats ? 200 : 0}
                />
            </div>
        </div>
    </ProfileContext.Provider>)
}

const CardDesktop = (props: any) => { 
    const navigate = useNavigate(); 
    const [ cookie, setCookie ] = useCookies(['user_id_cookie']);
    const globalContext = useContext(GlobalContext);

    const [ loaded, setLoaded ] = useState<boolean>(false);
    const [ profile, setProfile ] = useState<any>();
    const [ ownerProfile, setOwnerProfile ] = useState<any>();
    const [ notifications, setNotifications ] = useState<any[]>();
    const [ subscriptions, setSubscriptions ] = useState<{
        followers: any[],
        follow: any[],
    }>()
    const [ card, setCard ] = useState<any>();
    const [ similarRequests, setSimilarRequests ] = useState<any[]>();
    const [ recommendations, setRecommendations ] = useState<any[]>([]);
    const [ recommendationsAbortController, setRecommendationsAbortController ] = useState<AbortController>(new AbortController());

    const [ clipBoardOk, setClipboardOk ] = useState<boolean>(false);
    const [ wishToHelpPopup1, setWishToHelpPopup1 ] = useState<boolean>(false);
    const [ startChatWithId, setStartChatWithId ] = useState<string | undefined>();
    const [ editRequestPopup, setEditRequestPopup ] = useState<boolean>(false);
    const [ profilePopup, setProfilePopup ] = useState<any>();
    const [ suggestAFriendPopup, setSuggestAFriendPopup ] = useState<any>();
    const [ rndColor, setRndColor ] = useState<string[]>([]);
    
    const recommendScrollBlock = useRef<HTMLDivElement | null>(null);
    const recommendScrollElem = useRef<HTMLDivElement | null>(null);
    const [ showLeftRecommendBtn, setShowLeftRecommendBtn ] = useState<boolean>(false);
    const [ showRightRecommendBtn, setShowRightRecommendBtn ] = useState<boolean>(false);

    const [ email, setEmail ] = useState<string>("");    
    const [ waitEmail, setWaitEmail ] = useState<boolean>(false);
    const [ mailOkPopup, setMailOkPopup ] = useState<boolean>(false);
    const [ mailErrorPopup, setMailErrorPopup ] = useState<boolean>(false); 

    const { num } = useParams();

    const projectScrollBlock = useRef<HTMLDivElement | null>(null);
    const projectScrollElem = useRef<HTMLDivElement | null>(null);
    const [ showLeftProjectBtn, setShowLeftProjectBtn ] = useState<boolean>(false);
    const [ showRightProjectBtn, setShowRightProjectBtn ] = useState<boolean>(false);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: `instant`,
        });

        if (num) {        
            CardRequest(num, setProfile, () => {
                ProfileRequest(cookie[`user_id_cookie`], setProfile, () => {
                    navigate('/404');
                })
            });
        } else {
            navigate('/profile');
        }        
        
        NotificationsRequest(cookie[`user_id_cookie`], setNotifications);
        SubscriptionsRequest(cookie[`user_id_cookie`], setSubscriptions);
        setRndColor(
            [[`gray`, `black`, `black`, `#F5F5F5`], ...ScreenColors]
            [Math.floor(Math.random() * 4)]);
    }, [num])

    useEffect(() => {
        if (rndColor) {
            const btn = document.querySelector(`#footer-button-color`) as HTMLButtonElement;
            // console.warn(`main changed`)
            if (btn) {
                btn.setAttribute(`footer-color`, rndColor[0] ?? ``);
                btn.click();
            }
        }
    }, [rndColor])

    useEffect(() => {
        if (profile) {
            console.warn(num)
            if (profile.requests.find((val: any) => val.id === num)) {
                setCard(profile.requests.find((val: any) => val.id === num));
            } else if (Number(num) < profile.requests?.length) {
                setCard(profile.requests[Number(num)]);
            } else {
                navigate('/404');
            }
            // setCards(profile.requests);
            setLoaded(true);            

            if (profile.id === cookie[`user_id_cookie`]) {
                setOwnerProfile(profile);
            } else if (!ownerProfile) {
                ProfileRequest(cookie[`user_id_cookie`], setOwnerProfile, () => {
                    setCookie("user_id_cookie", undefined);
                })   
                // if (profile && profile?.id !== cookie[`user_id_cookie`]) {
                //     navigate(`/profile/${profile?.id}?request_id=${encodeURIComponent(num ?? '')}`)
                // }
            }
        }
    }, [profile?.id, profile?.requests])
    
    const SetRecommendationsAgain = (id: string, text: string, setInfo: Function) => {
        setInfo([]);
        recommendationsAbortController.abort();
        const newAbortController = new AbortController();
        setRecommendationsAbortController(newAbortController);
        CardRecommendation(
            id, 
            text,
            setInfo,
            {}, newAbortController.signal,                
        )
    }

    useEffect(() => {
        setSimilarRequests([]);
        setRecommendations([]);
        if (card) {
            CardRecommendationRequests(
                profile?.id,
                card.answers[0],
                setSimilarRequests,
                () => {}, () => {}, 8, false,
            )
            SetRecommendationsAgain(
                profile?.id, 
                card?.answers[0],
                setRecommendations,
            )
        }
    }, [card])
    
    return(<ProfileContext.Provider value={{
        notifications: notifications, setNotifications: setNotifications,
        subscriptions: subscriptions, setSubscriptions: setSubscriptions,
        setProfilePopup: setProfilePopup, setSuggestAFriendPopup: setSuggestAFriendPopup,
        profile: ownerProfile, setProfile: setOwnerProfile,
        isSubscriptionActive: ownerProfile?.subscription?.is_subscription_active,
    }}>
        <div className={`body top desktop`}>     
            <TopNavBar other={true} onLogoClick={() => {
                navigate(cookie["user_id_cookie"] ? `/profile/feed` : `/?nologin=true`)
            }}/>

            <div className={`row left top gap-4 md-gap-3 grid-7-5 md-grid-1 w-flex mb-6 px-5 md-px-3 pt-2`}>
                <div hidden={!!cookie[`user_id_cookie`]}>
                    {/* FOR NARROW SCREEN */}
                    <div className={`block p-3 b-3 w-flex row gap-2 left top nowrap`} onClick={() => {}} 
                        data-hidden={true} data-md-shown={true}>
                        <NewAvatar size={globalContext.isTablet ? 11 : 8} name={profile?.name} avatar={profile?.avatar?.link}
                                type={profile?.avatar?.style} style={{transitionDuration: `0s`}}/>
                        <div className={`left column`} style={{
                                minHeight: globalContext.isTablet ? 271 : 123,
                                width: `calc(100% - 285px)`,
                            }}>
                            <div>
                                <a href={`/profile/${profile?.unique_id ?? profile?.id}`}>
                                    <p data-size={4} className={`semibold`}>{profile?.name}</p>
                                </a>
                                <p data-size={globalContext.isTablet ? 5 : 8} data-lines={2}>
                                    {profile?.occupation}
                                </p>                            
                                <div className={`row left nowrap nogap mt-1`} hidden={!profile?.location?.city}>
                                    <img src={LocationGray} alt={``} className={`w-3`}></img>
                                    <p data-size={5} data-color={`black-40-opacity`} className={`w-flex ellipsis`}>
                                        {profile?.location?.city}
                                    </p>
                                </div>
                            </div>

                            <div className={`mt-2 w-flex`}>  
                                <div className={`row w-flex nowrap mt-2`}>
                                    <a className={`block btn-circled-32 desktop row center w-flex`} href={`/`} data-color={`light-gray`}>
                                        <p>{`Sign up`}</p>
                                    </a>
                                    <button className={`block btn-circled-32 desktop p-left p-right row center`} data-color={`light-gray`}
                                            onClick={() => {
                                                GoogleAuth(
                                                    null, 
                                                    null, 
                                                    encodeURIComponent(`/request/${card?.id}`), 
                                                    null,
                                                    {
                                                        203: () => {
                                                            navigate('/notfound');
                                                        }
                                                    },
                                                )
                                            }}>
                                        <img src={GoogleLogo} alt={``} className={`w-3`}></img>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div> 
                    {/* FOR WIDE SCREEN */}
                    <div className={`block p-3 b-3 w-flex`} data-md-hidden={true} 
                        onClick={() => {}}>
                        <NewAvatar size={0} name={profile?.name} avatar={profile?.avatar?.link}
                                    type={profile?.avatar?.style} className={`circled mb-2`}/>
                        <a href={`/profile/${profile?.unique_id ?? profile?.id}`}>
                            <p data-size={4} className={`semibold`}>{profile?.name}</p>
                        </a>
                        <p data-size={5}>
                            {profile?.occupation}
                        </p>
                        <div className={`row left nowrap nogap mt-1`} hidden={!profile?.location?.city}>
                            <img src={LocationGray} alt={``} className={`w-3`}></img>
                            <p data-size={5} data-color={`black-40-opacity`} className={`w-flex ellipsis`}>
                                {profile?.location?.city}
                            </p>
                        </div>
                    </div>
                    <div className={`block w-flex p-3 b-3 mt-3`} data-md-hidden={true}>
                        <p data-size={4} className={`center semibold seminarrow mb-3`}>
                            {`Sign in`}
                        </p>
                        {
                            [
                                {title: `Google`, logo: GoogleLogo, onClick: () => {
                                    GoogleAuth(
                                        null, 
                                        null, 
                                        encodeURIComponent(`/request/${card?.id}`), 
                                        null,
                                        {
                                            203: () => {
                                                navigate('/notfound');
                                            }
                                        },
                                    )
                                }, hidden: false},
                                {title: `LinkedIn`, logo: LinkedInLogo, onClick: () => {
                                    LinkedInAuth(
                                        null, 
                                        null, 
                                        encodeURIComponent(`/request/${card?.id}`), 
                                        null,
                                        {
                                            203: () => {
                                                navigate('/notfound');
                                            }
                                        },
                                    )
                                }, hidden: true},
                            ].map((elem, index) => {return (
                                <button className={`btn-google mobile w-flex row center mb-1`}
                                        data-color={`black`} onClick={() => {
                                            elem.onClick();
                                        }} key={index} hidden={elem.hidden}>
                                    <img src={elem.logo} alt={`li`}></img>
                                    <p>{`Continue with ${elem.title}`}</p>
                                </button>
                            )})
                        }                
                        <div className={`w-flex row my-2 nowrap`}>
                            <div className={`block w-flex`} data-color={`black-10-opacity`} style={{height: 1}}></div>
                            <p data-size={8} data-color={`black-40-opacity`} className={`noshrink semibold seminarrow`}>
                                {`or`}
                            </p>
                            <div className={`block w-flex`} data-color={`black-10-opacity`} style={{height: 1}}></div>
                        </div>
                        <form className={`w-flex`} onSubmit={e => {
                            e.preventDefault();
                            if (email) {
                                setWaitEmail(true); 
                                setTimeout(() => { setWaitEmail(false) }, 5000);
                                EmailAuth(
                                    email, () => {}, () => {}, 
                                    null, 
                                    null, 
                                    encodeURIComponent(`/request/${card?.id}`), 
                                    null,
                                    {
                                        200: () => {                                     
                                            setWaitEmail(false);
                                            setMailOkPopup(true);
                                        },
                                        0: () => {
                                            setMailErrorPopup(true);
                                        },
                                        203: () => {
                                            navigate('/notfound');
                                        }
                                    },
                                )
                            } 
                        }}>
                            <div className={`row mobile input-block mb-1`}>
                                <input className={`mobile`} placeholder={`Email address`} type={`email`}
                                        value={email} onChange={e => {
                                            setEmail(e.target.value);
                                        }} required={true} data-size={6}></input> 
                                    <p className={`text-4 semibold`} style={{marginTop: '-0.1em'}}>{`@`}</p>
                            </div>
                                
                            <button className={`block btn-flex mobile w-flex row center`}
                                    data-color={`black`} type={`submit`}>
                                {waitEmail ? 
                                <Loading color={`white`} width={24} height={24}/> : 
                                <p>{`Continue`}</p>}
                            </button>
                        </form>
                        <div className={`mt-3 row center nogap nowrap`}>
                            <p data-size={8} data-color={`black-40-opacity`} className={`semibold seminarrow`}>
                                {`New to Osmos?`}&nbsp;	
                                <a href={`/`}>
                                    <span data-color={`black`}>{`Join now`}</span>
                                </a>
                            </p>
                        </div>
                    </div>
                </div>

                <div>
                {loaded ? <>
                    <FeedRequestCardDesktop className={`w-flex mb-3`} profile={profile} request={card}
                                            desktop={true} onlyValid={true} onAvatarClick={() => {
                                                if (window.getSelection()?.type !== "Range") {
                                                    const newWindow: WindowProxy | null = window.open(`/profile/${profile?.id}`, '_blank');
                                                    if (newWindow) {
                                                        newWindow.focus();
                                                    }
                                                }
                                            }} showAnywhere={true} landingV={!cookie[`user_id_cookie`]}
                                            onLandingCanHelp={() => {
                                                globalContext.setCanHelpRequest({
                                                    ...card,                
                                                    onSend: () => {  }
                                                });
                                                globalContext.setCanHelpPopup(true);
                                            }}/>

                    {!!profile?.projects?.length && !cookie[`user_id_cookie`] && false &&
                    <div className={`block p-2 b-3 w-flex mb-3`} ref={projectScrollBlock}
                            onMouseEnter={() => {
                            if (projectScrollElem.current && 
                                projectScrollElem.current.scrollWidth > projectScrollElem.current.clientWidth) { 
                                if (IsLeftScroll(projectScrollElem.current)) {
                                    setShowLeftProjectBtn(true);
                                }
                                if (IsRightScroll(projectScrollElem.current)) {
                                    setShowRightProjectBtn(true);
                                }
                            }
                            }} onMouseLeave={() => { 
                            setShowLeftProjectBtn(false); 
                            setShowRightProjectBtn(false);
                        }}>
                        <div className={`row nowrap pt-1 px-1`}>
                            <div className={`row left nowrap`}>
                                <div className={`block min-w-3 h-3 centered b-2`} data-color={rndColor[2]}>
                                    <p data-size={6} className={`px-1`}>{profile?.projects?.length}</p>
                                </div>
                                <p data-size={5} className={`seminarrow semibold`} data-color={rndColor[2]}>{`Projects`}</p>
                            </div>
                        </div>
                        <div className={`row nogap nowrap min-w-flex neg-mx-2 mt-2`}>
                            <div className={`w-5 h-flex centered neg-mr-5`} hidden={!showLeftProjectBtn} style={{zIndex: 1}}>
                                <button className={`block btn centered w-4 h-4 b-4`} style={{
                                    boxShadow: '0 0 13px #00000020',
                                    transform: 'translate(-29px, 0) rotate(180deg)'
                                }} data-color={'white'} onClick={() => {
                                    if (projectScrollElem.current) {
                                        projectScrollElem.current.scrollTo({
                                            left: projectScrollElem.current.scrollLeft - 278, 
                                            behavior: 'smooth'
                                        });
                                    }
                                }}>
                                    <img className={`h-2`} src={AngleRightBlack} alt={`>`}></img>
                                </button>
                            </div>

                            <div className={`row x-auto left top noshrink nowrap gap-2 w-flex px-2`} ref={projectScrollElem}
                                onScroll={() => {
                                    if (projectScrollElem.current && 
                                        projectScrollElem.current.scrollWidth > projectScrollElem.current.clientWidth) { 
                                        if (IsLeftScroll(projectScrollElem.current)) {
                                            setShowLeftProjectBtn(true);
                                        } else {
                                            setShowLeftProjectBtn(false);
                                        }
                                        if (IsRightScroll(projectScrollElem.current)) {
                                            setShowRightProjectBtn(true);
                                        } else {
                                            setShowRightProjectBtn(false);
                                        }
                                    }
                                }} >
                                {profile?.projects.map((elem : any, index: number) => {return (
                                    <SiteCard project={elem} key={index} 
                                                desktop={true} orderNumber={index}/>
                                )})}
                            </div> 

                            <div className={`w-5 h-flex centered neg-ml-5`} hidden={!showRightProjectBtn}>
                                <button className={`block btn centered w-4 h-4 b-4`} style={{
                                    boxShadow: '0 0 13px #00000020',
                                    transform: 'translate(29px, 0)'
                                }} data-color={'white'} onClick={() => {
                                    if (projectScrollElem.current) {
                                        projectScrollElem.current.scrollTo({
                                            left: projectScrollElem.current.scrollLeft + 278, 
                                            behavior: 'smooth'
                                        });
                                    }
                                }}>
                                    <img className={`h-2`} src={AngleRightBlack} alt={`>`}></img>
                                </button>
                            </div>
                        </div> 
                    </div>}

                    {!!similarRequests?.length && <>
                    <div className={`row left nowrap mb-2 px-1`}>
                        <div className={`block min-w-3 h-3 centered b-2`} data-color={rndColor[2]}>
                            <p data-size={6} className={`px-1`}>{similarRequests?.length}</p>
                        </div>
                        <p data-size={5} className={`seminarrow semibold`} data-color={rndColor[2]}>{`Similar requests`}</p>
                    </div>
                    {similarRequests.map((elem: any, index: number) => {return (<Fragment key={index}>
                    <FeedRequestCardDesktop className={`w-flex mb-2`} profile={elem.user} request={elem.card}
                                            desktop={true} onlyValid={true} index={index} onAvatarClick={() => {
                                                if (window.getSelection()?.type !== "Range") {
                                                    const newWindow: WindowProxy | null = window.open(`/profile/${elem.user?.id}`, '_blank');
                                                    if (newWindow) {
                                                        newWindow.focus();
                                                    }
                                                }
                                            }} onRequestClick={() => {
                                                navigate(`/request/${elem.card?.id}`);
                                            }} landingV={!cookie[`user_id_cookie`]} landingAndRequest={true} onLandingCanHelp={() => {
                                                globalContext.setCanHelpRequest({
                                                    ...elem.card,                
                                                    onSend: () => {  }
                                                });
                                                globalContext.setCanHelpPopup(true);
                                            }}/>
                    </Fragment>)})}
                    </>}
                </> : <>
                    <div className={`skeleton b-3 w-flex h-10 mb-3`}></div>
                </>}
                </div>

                <div data-hidden={!cookie[`user_id_cookie`] ? true : null} data-md-shown={true} 
                     style={Sticky(86) as HTMLAttributes<HTMLDivElement>}>
                {loaded ? <>
                    <div className={`block p-2 b-3 w-flex mb-3`} ref={recommendScrollBlock}
                            onMouseEnter={() => {
                            if (recommendScrollElem.current && 
                                recommendScrollElem.current.scrollWidth > recommendScrollElem.current.clientWidth) { 
                                if (IsLeftScroll(recommendScrollElem.current)) {
                                    setShowLeftRecommendBtn(true);
                                }
                                if (IsRightScroll(recommendScrollElem.current)) {
                                    setShowRightRecommendBtn(true);
                                }
                            }
                            }} onMouseLeave={() => { 
                            setShowLeftRecommendBtn(false); 
                            setShowRightRecommendBtn(false);
                        }}>
                        <div className={`row top left nowrap px-1 pt-1`}>
                            <div className={`block min-w-3 h-3 b-3 centered noshrink`} data-color={rndColor[2] ?? `black`}>
                                {!!recommendations?.length ? 
                                <p data-size={6} className={`narrow px-1`}>{recommendations?.length}</p> :
                                <Loading color={rndColor[2] === `white` ? `black` : `white`} width={14} height={14}/>}
                            </div>
                            <p data-size={5} className={`semibold seminarrow min-h-3`} data-color={rndColor[2]}>
                                {cookie[`user_id_cookie`] === profile?.id ? `People that match your request` : `Interesting people`}
                            </p>
                        </div>
                        <div className={`row nogap nowrap min-w-flex neg-mx-2 mt-2`}>
                            <div className={`w-5 h-flex centered neg-mr-5`} hidden={!showLeftRecommendBtn} style={{zIndex: 1}}>
                                <button className={`block btn centered w-4 h-4 b-4`} style={{
                                    boxShadow: '0 0 13px #00000020',
                                    transform: 'translate(-29px, 0) rotate(180deg)'
                                }} data-color={'white'} onClick={() => {
                                    if (recommendScrollElem.current) {
                                        recommendScrollElem.current.scrollTo({
                                            left: recommendScrollElem.current.scrollLeft - 278, 
                                            behavior: 'smooth'
                                        });
                                    }
                                }}>
                                    <img className={`h-2`} src={AngleRightBlack} alt={`>`}></img>
                                </button>
                            </div>

                            <div className={`row x-auto left top noshrink nowrap nogap w-flex px-2`} ref={recommendScrollElem}
                                onScroll={() => {
                                    if (recommendScrollElem.current && 
                                        recommendScrollElem.current.scrollWidth > recommendScrollElem.current.clientWidth) { 
                                        if (IsLeftScroll(recommendScrollElem.current)) {
                                            setShowLeftRecommendBtn(true);
                                        } else {
                                            setShowLeftRecommendBtn(false);
                                        }
                                        if (IsRightScroll(recommendScrollElem.current)) {
                                            setShowRightRecommendBtn(true);
                                        } else {
                                            setShowRightRecommendBtn(false);
                                        }
                                    }
                                }}>
                                {recommendations?.length ?
                                recommendations.map((elem: any, index: number) => {return (
                                    <SmallUserBlock profile={elem?.user} index={index} className={`${!!index ? `ml-2` : ``}`} 
                                                    setRecommendations={setRecommendations} color={`light-gray`}
                                                    onClick_={() => { 
                                                        setProfilePopup({
                                                            ...elem.user,
                                                            onStartChat: () => {
                                                                // setWishToHelpRequest({
                                                                //     ...card, 
                                                                //     user_id: elem?.user?.id,
                                                                //     avatar: profile?.avatar,
                                                                //     name: profile?.name,
                                                                //     occupation: profile?.occupation,
                                                                // });
                                                                // setWishToHelpPopup2(true);
                                                                setProfilePopup(null);
                                                            }
                                                        }) 
                                                    }} onCanHelp={() => {
                                                        setStartChatWithId(elem?.user?.id);
                                                    }} key={index}/>
                                )}) :
                                [1, 2, 3].map((elem, index) => {return (
                                    <div className={`block b-3 p-2 column top ${!!index ? `ml-2` : ``}`} key={index}
                                        data-border={`gray-1`}>
                                        <NewAvatar size={0} className={`mb-2 circled`}/>
                                        <div style={{height: 61}}>
                                            <div className={`skeleton b-4 h-3 mb-1`} style={{width: 180}}></div>
                                            <div className={`skeleton b-3 h-2 mb-2`} style={{width: 195}}></div>
                                        </div>
                                        <div className={`skeleton b-4`} style={{width: 198, height: 40}}></div>
                                    </div>
                                )})}
                            </div> 

                            <div className={`w-5 h-flex centered neg-ml-5`} hidden={!showRightRecommendBtn}>
                                <button className={`block btn centered w-4 h-4 b-4`} style={{
                                    boxShadow: '0 0 13px #00000020',
                                    transform: 'translate(29px, 0)'
                                }} data-color={'white'} onClick={() => {
                                    if (recommendScrollElem.current) {
                                        recommendScrollElem.current.scrollTo({
                                            left: recommendScrollElem.current.scrollLeft + 278, 
                                            behavior: 'smooth'
                                        });
                                    }
                                }}>
                                    <img className={`h-2`} src={AngleRightBlack} alt={`>`}></img>
                                </button>
                            </div>
                        </div> 
                    </div>
                </> : <>
                    <div className={`skeleton b-3 w-flex h-8`}></div>
                </>}
                </div>
            </div>
            
            <FooterDesktop links={!cookie[`user_id_cookie`]}/>

            {!!profilePopup &&
            <ProfilePopup user={profilePopup} setUser={setProfilePopup} 
                        onSubscribe={() => {}}/>}  

            {wishToHelpPopup1 &&
            <PopupCenterCanHelp show={wishToHelpPopup1} setShow={setWishToHelpPopup1} desktop={true} request={{...card, user_id: profile?.id}}/>} 

            {!!startChatWithId &&
            <PopupCenterCanHelp show={!!startChatWithId} setShow={(bool: boolean) => { setStartChatWithId(val => bool ? val : undefined) }} 
                                desktop={true} request={{user_id: startChatWithId}}/>} 

            {editRequestPopup &&  
            <AddRequestDesktop show={editRequestPopup} setShow={setEditRequestPopup} canClose={true}
                            onSubmit={() => {
                                ProfileRequest(cookie[`user_id_cookie`], setProfile, () => {
                                    navigate('/404');
                                })
                            }} onDelete={() => {
                                ProfileRequest(cookie[`user_id_cookie`], setProfile, () => {
                                    navigate('/404');
                                })
                            }} onClose={() => { setEditRequestPopup(false) }} canDelete={profile?.requests?.length > 1}
                            headerId={`add-request-popup-content`} request={card}/>}
            
            {!!suggestAFriendPopup &&
            <PopupCenterSuggestAFriend show={!!suggestAFriendPopup} request={suggestAFriendPopup}
                                    setShow={(show: boolean) => { setSuggestAFriendPopup((val: any) => show ? val : null) }}/>}

            {clipBoardOk && 
            <InfoPopup show={clipBoardOk} setShow={setClipboardOk} hideBg={true}
                        title={`Link successfully copied to clipboard`}
                        buttonTitle={`Ok, I got it!`} onOk={() => {
                            
                        }} desktop={true} noButton={true}/>}             

            {mailOkPopup &&
            <InfoPopup _id={`mail-ok-popup`} show={mailOkPopup} setShow={setMailOkPopup}
                    title={`Check your email. We sent the magic link for authorization`}
                    buttonTitle={`Ok, I got it!`} onOk={() => {}} desktop={true}/>}

            {mailErrorPopup &&
            <InfoErrorPopup show={mailErrorPopup} setShow={setMailErrorPopup}
                            title={`Something went wrong`}
                            buttonTitle={`Reload the page`} onOk={() => {
                                    setMailErrorPopup(false);
                                    window.location.reload();
                            }} desktop={true}/>} 
        </div>
    </ProfileContext.Provider>)
}

export {
    CardMobile,
    CardDesktop,
}