import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {   
    TopBarDesktop,
    TopBar,
    NewAvatar,
} from "./elements/Elements.jsx";
import { ConfirmPopup } from './elements/Popups';
import { CardDelete, ProfileDelete } from './Requests';
import { ClipBoard, DateTimeFromString, MatchTimestamp, SetDict } from './elements/Data';
import { useCookies } from 'react-cookie';
import { GlobalContext } from './elements/Contexts';
import { AngleRightWhite2, CopyBlack, OkGray, OkGreen, OkWhite, PlusWhite } from './elements/Imgs';
import { Loading } from './elements/Elems';

const GetRole = (
    onAdministrator: Function = () => {},
    onNotAdministrator: Function = () => {},
) => {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'GET',
    }

    return fetch(`${process.env.REACT_APP_BACKEND_URL}administrator/role`, request)
    .then(res => {
        if (res.status === 200) {
            res.json().then(data => {
                if (data?.role === `ADMINISTRATOR` || data?.role === 'admin') {
                    onAdministrator();
                } else {
                    onNotAdministrator();
                }
            });
        } else {
            onNotAdministrator();
        }
    })
}

const GetUser = (
    id: string,
    setInfo: Function,
    onNotFound: Function = () => {},
) => {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'GET',
    }

    return fetch(`${process.env.REACT_APP_BACKEND_URL}administrator/user/${id}`, request)
    .then(res => {
        if (res.status === 200) {
            res.json().then(data => {
                setInfo(data);
            });
        } else if (res.status === 404) {
            onNotFound();
        }
    })
}

const PatchUser = (
    id: string,
    data: any,
    onSuccess: Function = () => {},
    onFailure: Function = () => {},
) => {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'PATCH',
        body: JSON.stringify(data),
    }

    return fetch(`${process.env.REACT_APP_BACKEND_URL}administrator/user/${id}`, request)
    .then(res => {
        if (res.status === 200) {
            onSuccess();
        } else {
            onFailure();
        }
    })
}

const GetRequest = (
    id: string,
    setInfo: Function,
    onNotFound: Function = () => {},
) => {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'GET',
    }

    return fetch(`${process.env.REACT_APP_BACKEND_URL}administrator/card/${id}`, request)
    .then(res => {
        if (res.status === 200) {
            res.json().then(data => {
                setInfo(data);
            });
        } else if (res.status === 404) {
            onNotFound();
        } 
    })
}

const GetChats = (
    setInfo: Function,
) => {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'GET',
    }

    return fetch(`${process.env.REACT_APP_BACKEND_URL}administrator/chats`, request)
    .then(res => {
        if (res.status === 200) {
            res.json().then(data => {
                setInfo(data);
            });
        } 
    })
}

const GetRatings = (
    setInfo: Function,
) => {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'GET',
    }

    return fetch(`${process.env.REACT_APP_BACKEND_URL}administrator/relevance`, request)
    .then(res => {
        if (res.status === 200) {
            res.json().then(data => {
                setInfo(data);
            });
        } 
    })
}

const GenPromoCode = (
    setInfo: Function,
    type: number = 50,
) => {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'GET',
    }

    return fetch(`${process.env.REACT_APP_BACKEND_URL}create_promotion_code?type=${type}`, request)
    .then(res => {
        if (res.status === 200) {
            res.json().then(data => {
                setInfo(data?.code);
            });
        } 
    })
}

const ActivateSubscription = (
    id: string,
    activate: boolean,
    onSuccess: Function = () => {},
    onFailure: Function = () => {},
) => {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        method: 'GET',
    }

    return fetch(`${process.env.REACT_APP_BACKEND_URL}administrator/user/${id}/${activate ? "activate" : "deactivate"}_subscription`, request)
    .then(res => {
        if (res.status === 200) {
            onSuccess();
        } else {
            onFailure();
        }
    })
}

const AddPlatformMessage = (
    id_1: string,
    id_2: string,
    message: string,
    target: "first" | "second" | "both",
    onSuccess: () => void = () => {},
    onFailure: (err: string) => void = () => {},
) => {
    const request = {
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors' as RequestMode,
        credentials: 'include' as RequestCredentials,
        body: JSON.stringify({
            user_1: id_1,
            user_2: id_2,
            message: message,
            for_users: target,
        }),
        method: 'PUT',
    }

    return fetch(`${process.env.REACT_APP_BACKEND_URL}administrator/add_message`, request)
    .then(async res => {
        if (res.status === 200) {
            onSuccess();
        } else {
            onFailure(JSON.parse(await res.text())?.detail ?? res.statusText);
        }
    })
}

export const AdminDesktop = (props: any) => {
    const navigate = useNavigate();
    const [ cookie ] = useCookies([`user_id_cookie`]);
    const globalContext = useContext(GlobalContext);

    const [ user, setUser ] = useState<any>();
    const [ userId, setUserId ] = useState<string>('');
    const [ request, setRequest ] = useState<any>();
    const [ requestId, setRequestId ] = useState<string>('');
    const [ chats, setChats ] = useState<any>();
    const [ ratings, setRatings ] = useState<any>();
    const [ new50Promo, setNew50Promo ] = useState<string>();
    const [ new100Promo, setNew100Promo ] = useState<string>();

    const [ confirmDeleteUser, setConfirmDeleteUser ] = useState<boolean>(false);
    const [ confirmDeleteRequest, setConfirmDeleteRequest ] = useState<boolean>(false);
    const [ showRequests, setShowRequests ] = useState<boolean>(false);
    const [ showProjects, setShowProjects ] = useState<boolean>(false);

    const [ userSaved, setUserSaved ] = useState<boolean>(false);
    const [ userNotFound, setUserNotFound ] = useState<boolean>(false);
    const [ requestNotFound, setRequestNotFound ] = useState<boolean>(false);
    
    const [ newMessageTarget, setNewMessageTarget ] = useState<"both" | "first" | "second">("both");
    const [ waitNewMessage, setWaitNewMessage ] = useState<boolean>(false);
    const [ newMessageError, setNewMessageError ] = useState<{
        type: "ok" | "error",
        text: string,
    } | undefined>();

    useEffect(() => {
        GetRole(
            () => {}, 
            () => { navigate(`/`) },
        )
    }, [])

    useEffect(() => {
        setUserSaved(false);
    }, [user?.hidden, user?.role, user?.subscription])

    useEffect(() => {
        if (user) {
            setUserNotFound(false);
        }
    }, [user])

    return (
        <div className={`body top desktop`}>    
            <TopBarDesktop func={() => {
                const newWindow: WindowProxy | null = window.open(`https://osmos.social?nologin=true`, '_blank');
                if (newWindow) {
                    newWindow.focus();
                }
            }} toTop={false} color={'light-gray'}
            className={`w-flex`} hideNotifications={true}/>

            <div className={`w-flex px-5 md-px-3`}>
                <p data-size={5} className={`mb`}>{`Next match time:`}</p>
                <p data-size={3} className={`semibold seminarrow mb-3`}>{DateTimeFromString(MatchTimestamp())}</p>
                <div className={`block w-flex b-3 p-3 mb-6`}> 
                    <div className={`row left nowrap w-flex`}>
                        <button className={`block btn-flex desktop w-flex centered mb-2`} data-color={`gray`} type={`button`} onClick={() => {
                                GenPromoCode(setNew50Promo);
                            }} disabled={true}>
                            <p>{`Generate 50% promotion code`}</p>
                        </button>
                        {!!new50Promo &&
                        <button className={`block p-2 b-1 row w-8 mb-2 btn-double`} data-color={'light-gray'} onClick={() => {
                                    navigator.clipboard.writeText(new50Promo ?? '');
                                }}>
                            <p data-size={5} data-color={`black`}>{new50Promo}</p>
                            <img src={CopyBlack} alt={`copy`} title={`Copy link`}></img>
                        </button>}
                    </div>

                    <div className={`row left nowrap w-flex`}>
                        <button className={`block btn-flex desktop w-flex centered mb-2`} data-color={`gray`} type={`button`} onClick={() => {
                                GenPromoCode(setNew100Promo, 100);
                            }}>
                            <p>{`Generate 100% off forever promotion code`}</p>
                        </button>
                        {!!new100Promo &&
                        <button className={`block p-2 b-1 row w-8 mb-2 btn-double`} data-color={'light-gray'} onClick={() => {
                                    navigator.clipboard.writeText(new100Promo ?? '');
                                }}>
                            <p data-size={5} data-color={`black`}>{new100Promo}</p>
                            <img src={CopyBlack} alt={`copy`} title={`Copy link`}></img>
                        </button>}
                    </div>

                    <form className={`row nowrap mb-2`} onSubmit={e => {
                        e.preventDefault();
                        setUser(undefined);
                        GetUser(userId, setUser, () => { setUserNotFound(true) });
                    }}>
                        <input autoComplete={`off`} className={`desktop`} placeholder={`Enter user ID or Email`} type={`text`}
                            id={`fname-input`} value={userId} onChange={e => { 
                                setUserNotFound(false);
                                setUserId(e.target.value); 
                            }}></input> 
                        <button className={`block btn-flex desktop w-8 centered`} data-color={`black`} type={`submit`}>
                            <p>{`Find user`}</p>
                        </button>
                    </form>
                    {!!user ? 
                    <div className={`row top left gap-2 mb-2 nowrap`}>
                        <div>
                            <NewAvatar name={user?.name} avatar={user?.avatar?.link} size={7} type={user?.avatar?.style}/>
                            <div className={`block label btn-circled-32 desktop clickable my-2`} data-border={`blue-2`}
                                    style={{position: `relative`}} onMouseEnter={e => {
                                    setShowRequests(true);
                                    }} onMouseLeave={e => { setShowRequests(false) }}>
                                <p>{`${user?.requests?.length} request(s)`}</p>
                                {showRequests && user?.requests?.length > 0 &&
                                <div className={`block b-1 px-1 pt-1`} style={{position: `absolute`, left: 0, bottom: 24, zIndex: 2}}
                                        data-color={`black-40-opacity`}>
                                    {user.requests.map((elem: any, index: number) => {return (
                                        <button className={`block btn-circled-24 desktop mb-1`} onClick={() => {
                                            setRequestId(elem?.id);
                                        }} key={index}>
                                            <p>{elem?.id}</p>
                                        </button>
                                    )})}
                                </div>}
                            </div>
                            <div className={`block label btn-circled-32 desktop clickable`} data-border={`green-2`}
                                    style={{position: `relative`}}onMouseEnter={e => {
                                    setShowProjects(true);
                                    }} onMouseLeave={e => { setShowProjects(false) }}>
                                <p>{`${user?.projects?.length} project(s)`}</p>
                                {showProjects && user?.projects?.length > 0 &&
                                <div className={`block b-1 px-1 pt-1`} style={{position: `absolute`, left: 0, top: 24, zIndex: 2}}
                                        data-color={`black-40-opacity`}>
                                    {user.projects.map((elem: any, index: number) => {return (
                                        <button className={`block btn-circled-24 desktop mb-1`} onClick={() => {
                                            ClipBoard(elem.url, () => { globalContext.setClipboardOk(true) })
                                        }} key={index}>
                                            <p>{elem?.url}</p>
                                        </button>
                                    )})}
                                </div>}
                            </div>
                        </div>
                        <div>
                            <p data-size={4} className={`semibold seminarrow`}>
                                {user?.name}
                            </p>
                            <p data-size={5} className={`mb-1`} data-color={`black-40-opacity`}>{user?.occupation}</p>
                            <p data-size={8} className={`mb-2`}>{user?.about}</p>

                            <div className={`row left bottom mb-3 nowrap`}>
                                <div>
                                    <p data-size={6} className={`mb-1`} data-color={`black-40-opacity`}>
                                        If the user is shown<br/>in recommendations:
                                    </p>
                                    <div className={`row left nowrap`}>
                                        <button className={`block btn-rectangle-32 desktop`} data-border={`green-2`}
                                                data-color={user?.hidden ? null : `green`} onClick={() => {
                                                    setUser((val: any) => SetDict(val, [`hidden`], false));
                                                }}>
                                            <p>{`Shown`}</p>
                                        </button>
                                        <button className={`block btn-rectangle-32 desktop`} data-border={`coral-2`}
                                                data-color={user?.hidden ? `coral` : null} onClick={() => {
                                                    setUser((val: any) => SetDict(val, [`hidden`], true));
                                                }}>
                                            <p>{`Hidden`}</p>
                                        </button>
                                    </div>
                                </div>

                                <div className={`mx-2`}></div>
                                
                                <div>
                                    <p data-size={6} className={`mb-1`} data-color={`black-40-opacity`}>
                                        Set user's permissions: 
                                    </p>
                                    <div className={`row left nowrap`}>
                                        <button className={`block btn-rectangle-32 desktop`} data-border={`green-2`}
                                                data-color={user?.role === "ADMINISTRATOR" ? `green` : null} onClick={() => {
                                                    setUser((val: any) => SetDict(val, [`role`], "ADMINISTRATOR"));
                                                }} disabled={cookie[`user_id_cookie`] === user?.id && user?.role !== "ADMINISTRATOR"}>
                                            <p>{`Admin`}</p>
                                        </button>
                                        <button className={`block btn-rectangle-32 desktop`} data-border={`blue-2`}
                                                data-color={user?.role === "EDITOR" ? `blue` : null} onClick={() => {
                                                    setUser((val: any) => SetDict(val, [`role`], "EDITOR"));
                                                }} disabled={cookie[`user_id_cookie`] === user?.id && user?.role !== "EDITOR"}>
                                            <p>{`Editor`}</p>
                                        </button>
                                        <button className={`block btn-rectangle-32 desktop`} data-border={`coral-2`}
                                                data-color={user?.role === "USER" ? `coral` : null} onClick={() => {
                                                    setUser((val: any) => SetDict(val, [`role`], "USER"));
                                                }} disabled={cookie[`user_id_cookie`] === user?.id && user?.role !== "USER"}>
                                            <p>{`User`}</p>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className={`row left bottom mb-3 nowrap`}>
                                <div hidden={!user?.subscription?.is_stripe_customer}>
                                    <p data-size={6} className={`mb-1`} data-color={`black-40-opacity`}>
                                        User's STRIPE status:
                                    </p>
                                    <div className={`row left nowrap`}>
                                        <div className={`label block btn-rectangle-32 desktop`} 
                                            data-border={user?.subscription?.is_stripe_customer ? 'green-2' : `gray-2`}>
                                            <p data-color={user?.subscription?.is_stripe_customer ? 'green' : `gray`} data-size={5}>
                                                {user?.subscription?.is_stripe_customer ? `Customer` : 'Not STRIPE customer'}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className={`mx-2`}></div>

                                <div>
                                    <p data-size={6} className={`mb-1`} data-color={`black-40-opacity`}>
                                        User's subscription status:<br/>
                                        Expire at <span data-color={`dark-gray`}>
                                            {DateTimeFromString(user?.subscription?.expire_at)}
                                        </span>
                                    </p>
                                    <div className={`row left nowrap`}>
                                        <button className={`block btn-rectangle-32 desktop`} data-border={`green-2`}
                                                data-color={!user?.subscription?.is_subscription_active ? null : `green`} onClick={() => {
                                                    setUser((val: any) => SetDict(val, [`subscription`, `is_subscription_active`], true));
                                                }}>
                                            <p>{`Active`}</p>
                                        </button>
                                        <button className={`block btn-rectangle-32 desktop`} data-border={`coral-2`}
                                                data-color={!user?.subscription?.is_subscription_active ? `coral` : null} onClick={() => {
                                                    setUser((val: any) => SetDict(val, [`subscription`, `is_subscription_active`], false));
                                                }}>
                                            <p>{`Disabled`}</p>
                                        </button>
                                    </div>
                                </div>

                                <div className={`mx-2`}></div>

                                <div>
                                    <p data-size={6} className={`mb-1`} data-color={`black-40-opacity`}>
                                        Save changes: 
                                    </p>
                                    <div className={`row left nowrap`}>                                
                                        <button className={`block btn-rectangle-32 desktop`} data-border={`black-2`}
                                                data-color={`black`} onClick={(e) => {
                                                    PatchUser(user?.id, {
                                                        role: user?.role,
                                                        hidden: user?.hidden,
                                                        subscription: user.subscription,
                                                    }, () => { 
                                                        setUserSaved(true);
                                                    })
                                                }}>
                                            <p>{`Save`}</p>
                                        </button>                                	
                                        <div className={`label block btn-rectangle-32 desktop`} data-border={`${userSaved ? `green` : `black-20-opacity`}-2`}>
                                            {userSaved ? 
                                            <p data-size={5} data-color={`green`} className={`semibold seminarrow`}>SAVED</p> :
                                            <p data-size={5} data-color={`black-20-opacity`}>NOT SAVED</p>}
                                        </div>
                                    </div>
                                </div>

                                <div className={`mx-2`}></div>

                                <button className={`block btn-rectangle-32 desktop`} data-color={`coral`}
                                        onClick={() => { setConfirmDeleteUser(true) }} data-border={`coral-2`}>
                                    <p>{`Delete user`}</p>
                                </button>
                            </div>

                            <div className={`row left bottom mb-3 nowrap`}>
                                <div>
                                    <p data-size={6} className={`mb-1`} data-color={`black-40-opacity`}>
                                        Activate user's subscription:
                                    </p>                                    

                                    <button className={`block btn-rectangle-32 desktop`} data-color={user?.subscription?.is_subscription_active ? `coral` : `green`}
                                            onClick={() => { 
                                                ActivateSubscription(
                                                    user?.id,
                                                    !user?.subscription?.is_subscription_active,
                                                    () => { setUser((user: any) => SetDict(user, ["subscription", "is_subscription_active"], !user?.subscription?.is_subscription_active)) },
                                                    () => {},
                                                )
                                            }} data-border={`green-2`}>
                                        <p>{user?.subscription?.is_subscription_active ? `Deactivate` : `Activate`}</p>
                                    </button>
                                </div>

                                <div className={`mx-2`}></div>
                            </div>
                        </div>
                    </div> : (userNotFound && <p data-size={3} data-color={`coral`} className={`mb-2`}>404: User not found</p>)}

                    <form className={`row nowrap mb-2`} onSubmit={e => {
                        e.preventDefault();
                        setRequest(undefined);
                        GetRequest(requestId, setRequest, () => { setRequestNotFound(true) });
                    }}>
                        <input autoComplete={`off`} className={`desktop`} placeholder={`Enter request ID`} type={`text`}
                            id={`fname-input`} value={requestId} onChange={e => { 
                                setRequestNotFound(false);
                                setRequestId(e.target.value);
                            }}></input> 
                        <button className={`block btn-flex desktop w-8 centered`} data-color={`black`} type={`submit`}>
                            <p>{`Find request`}</p>
                        </button>
                    </form>
                    {!!request ?
                    <div className={`row top left gap-2 mb-2 nowrap`}>
                        <NewAvatar name={request?.user?.name} avatar={request?.user?.avatar?.link} size={7} type={request?.user?.avatar?.style}/>
                        <div>
                            <p data-size={4} className={`semibold seminarrow mb-1`}>{request?.user?.name}</p>
                            <p data-size={8} className={`mb-2`}>{request?.answers[0]}</p>
                            <button className={`block btn-rectangle-32 desktop`} data-color={`coral`}
                                    onClick={() => { setConfirmDeleteRequest(true) }} data-border={`coral-2`}>
                                <p>{`Delete request`}</p>
                            </button>
                        </div>
                    </div> : (requestNotFound && <p data-size={3} data-color={`coral`} className={`mb-2`}>404: Request not found</p>)}

                    <form className={`w_flex mb-2`} onSubmit={e => {
                        e.preventDefault();
                        setWaitNewMessage(true);
                        setNewMessageError(undefined);
                        AddPlatformMessage(
                            (e.target as HTMLFormElement)?.first_user?.value ?? "",
                            (e.target as HTMLFormElement)?.second_user?.value ?? "",                            
                            (e.target as HTMLFormElement)?.platform_message?.value ?? "",
                            newMessageTarget,
                            () => { 
                                setNewMessageError({
                                    type: "ok",
                                    text: "Message sent"
                                });
                                setWaitNewMessage(false); 
                            },
                            (err) => { 
                                setNewMessageError({
                                    type: "error",
                                    text: err
                                });
                                setWaitNewMessage(false); 
                            }
                        )
                    }}>   
                        <p data-size={4} className='semibold seminarrow mb-1'>{"Send message from platform"}</p>                     
                        <div className='row gap-1 w_flex left nowrap mb-1'>
                            <div className={`checkbox-radio w-3 h-3 b centered noshrink`} onClick={() => {
                                    setNewMessageTarget(target => target === "both" ? "second" : target === "second" ? "both" : target);
                                }}
                                data-checked={["both", "first"].includes(newMessageTarget)}>
                                <img src={OkWhite} alt={`v`}></img>
                            </div>
                            <input autoComplete={`off`} className={`desktop`} placeholder={`Enter ID or email of first user`} 
                                type={`text`} required={true}
                                id={`f_user`} name="first_user"></input> 
                            <div className={`checkbox-radio w-3 h-3 b centered noshrink`} onClick={() => {
                                    setNewMessageTarget(target => target === "both" ? "first" : target === "first" ? "both" : target);
                                }} 
                                data-checked={["both", "second"].includes(newMessageTarget)}>
                                <img src={OkWhite} alt={`v`}></img>
                            </div>
                            <input autoComplete={`off`} className={`desktop`} placeholder={`Enter ID or email of second user`} 
                                type={`text`} required={true}
                                id={`s_user`} name="second_user"></input>
                        </div>
                        <textarea autoComplete='off' className='p-2 b-1 desktop' placeholder='Message' required={true}
                            name="platform_message"></textarea>                
                        <div className='row gap-4 w_flex left nowrap mb-1'>
                            <button className={`block btn-flex desktop w-8 centered`} data-color={`black`} type={`submit`}>
                                {waitNewMessage ? <Loading color="white" height={24} width={24}/> : <p>{`Send message`}</p>}
                            </button>
                            <p data-size={4} data-color={newMessageError?.type === "error" ? "coral" : "green"}>
                                {newMessageError?.text}
                            </p>
                        </div>
                    </form>
                    
                    <div className='row gap_2 w_flex left nowrap mb-2'>
                        <a className={`block btn-flex desktop w-flex row center`} data-color={`blue`} type={`button`}
                            target="_blank" href={`${process.env.REACT_APP_BACKEND_URL}administrator/match_table`} rel="noreferrer">
                            <p>{`Load Match Table`}</p>
                        </a>
                        <a className={`block btn-flex desktop w-flex row center`} data-color={`bright-blue`} type={`button`}
                            target="_blank" href={`${process.env.REACT_APP_BACKEND_URL}administrator/match_table/separate`} rel="noreferrer">
                            <p>{`Load Match Table (separately)`}</p>
                        </a>
                    </div>

                    <button className={`block btn-flex desktop w-flex centered mb-2`} data-color={`gray`} type={`button`} onClick={() => {
                            if (!chats) { GetChats(setChats) } else { setChats(undefined) }
                        }}>
                        <p>{`${!!chats ? `Hide` : `Load`} all chats`}</p>
                    </button>
                    {!!chats && chats.map((elem: any, index: number) => {return (<Fragment key={index}>
                        <div className={`label block btn-circled-32 desktop row nowrap mb-1 clickable`} data-color={`light-gray`}
                                onClick={() => {
                                    setChats((val: any) => SetDict(val, [index, `opened`], !val[index]?.opened));
                                }}>
                            <p className={`ellipsis`}>{elem.chat_id}</p>
                            <div className={`block b-3 h-3 centered noshrink px-1`}>
                                <p data-size={7}>{`${elem.message_number} messages`}</p>
                            </div>
                        </div>
                        {elem?.opened && elem.messages.map((elem_: any, index_: number) => {return (
                        <div className={`mb-2`} key={index_}>
                            <div className={`w-flex row nowrap px-2`}>
                                <p data-size={7} className={`semibold seminarrow ellipsis`}>{`${elem_?.from_user}:`}</p>
                                <div className={`row left nowrap`}>
                                    <div className={`label block btn-circled-24 desktop noshrink`} data-color={`light-blue`}>
                                        <p>{DateTimeFromString(elem_?.created_at ?? 100)}</p>
                                    </div>
                                    <img src={elem_?.is_read ? OkGreen : OkGray} alt={``} className={`w-2`}></img>
                                </div>
                            </div>
                            <p data-size={6} className={`mb pl-3`}>{`${elem_?.content}`}</p>
                        </div>)})}
                    </Fragment>)})}

                    <button className={`block btn-flex desktop w-flex centered mb-2`} data-color={`gray`} type={`button`} onClick={() => {
                            if (!ratings) { GetRatings(setRatings) } else { setRatings(undefined) }
                        }}>
                        <p>{`${!!ratings ? `Hide` : `Load`} all ratings`}</p>
                    </button>
                    {!!ratings && (!!ratings?.length ? ratings.map((elem: any, index: number) => {return (<Fragment key={index}>
                        <div className={`label block btn-circled-32 desktop row nowrap mb-1 clickable`} data-color={`light-gray`}
                                onClick={() => {
                                    setRatings((val: any) => SetDict(val, [index, `opened`], !val[index]?.opened));
                                }}>
                            <p className={`ellipsis`}>{elem.user_id}</p>
                            <div className={`block b-3 h-3 centered noshrink px-1`}>
                                <p data-size={7}>{`${elem.rating?.length} feedbacks`}</p>
                            </div>
                        </div>
                        {elem?.opened && elem.rating.map((elem_: any, index_: number) => {return (
                        <p key={index_} className={`mb-1`}>{elem_}</p>)})}
                    </Fragment>)}) : <p data-size={3} data-color={`coral`} className={`mb-2`}>No ratings found</p>)}

                    <div className={`neg-mt-2`}></div>
                </div>
            </div>

            {confirmDeleteUser &&
            <ConfirmPopup title={`Are you sure you want to delete ${user?.name}?`} show={confirmDeleteUser} 
                    setShow={setConfirmDeleteUser} _id={`yesno`}
                    color_1={'black'} buttonTitle_1={`No`} onClick_1={() => {}} black={true} desktop={true} white={true}
                    color_2={'light-gray'} buttonTitle_2={`Yes`} onClick_2={() => {
                        ProfileDelete(user?.links?.email, () => {
                            setUser(undefined);
                            setConfirmDeleteUser(false); 
                        }, () => {                            
                            setConfirmDeleteUser(false);
                        })
                    }} close={'first'}/>}

            {confirmDeleteRequest &&
            <ConfirmPopup title={`Are you sure you want to delete this request?`} show={confirmDeleteRequest} 
                    setShow={setConfirmDeleteRequest} _id={`yesno`}
                    color_1={'black'} buttonTitle_1={`No`} onClick_1={() => {}} black={true} desktop={true} white={true}
                    color_2={'light-gray'} buttonTitle_2={`Yes`} onClick_2={() => {
                        CardDelete(request.id, () => {
                            setRequest(undefined);
                            setConfirmDeleteRequest(false); 
                        }, () => {                            
                            setConfirmDeleteRequest(false);
                        })
                    }} close={'first'}/>}
        </div>
    )
}

export const AdminMobile = (props: any) => {
    const navigate = useNavigate();
    const [ cookie ] = useCookies([`user_id_cookie`]);
    const globalContext = useContext(GlobalContext);

    const [ user, setUser ] = useState<any>();
    const [ userId, setUserId ] = useState<string>('');
    const [ request, setRequest ] = useState<any>();
    const [ requestId, setRequestId ] = useState<string>('');
    const [ chats, setChats ] = useState<any>();
    const [ ratings, setRatings ] = useState<any>();
    const [ new50Promo, setNew50Promo ] = useState<string>();
    const [ new100Promo, setNew100Promo ] = useState<string>();

    const [ confirmDeleteUser, setConfirmDeleteUser ] = useState<boolean>(false);
    const [ confirmDeleteRequest, setConfirmDeleteRequest ] = useState<boolean>(false);
    const [ showRequests, setShowRequests ] = useState<boolean>(false);
    const [ showProjects, setShowProjects ] = useState<boolean>(false);

    const [ userSaved, setUserSaved ] = useState<boolean>(false);
    const [ userNotFound, setUserNotFound ] = useState<boolean>(false);
    const [ requestNotFound, setRequestNotFound ] = useState<boolean>(false);

    useEffect(() => {
        GetRole(
            () => {}, 
            () => { navigate(`/`) },
        )
    }, [])

    useEffect(() => {
        setUserSaved(false);
    }, [user?.hidden, user?.role, user?.subscription])

    useEffect(() => {
        if (user) {
            setUserNotFound(false);
        }
    }, [user])

    return (
        <div className={`body top desktop`} onClick={() => { 
            setShowRequests(false);
            setShowProjects(false);
        }}>    
            <TopBar func={() => {
                const newWindow: WindowProxy | null = window.open(`https://osmos.social?nologin=true`, '_blank');
                if (newWindow) {
                    newWindow.focus();
                }
            }} toTop={false} hideSettings={true} setProfile={() => {}} hideLogIn={true}
            className={`w-flex`} hideNotifications={true}/>

            <div className={`w-flex px-5 md-px-3`}>
                <div className={`block w-flex b-3 p-3 mb-3`}>
                    <div className={`w-flex`}>
                        <button className={`block btn-flex mobile w-flex centered mb-2`} data-color={`gray`} type={`button`} onClick={() => {
                                GenPromoCode(setNew50Promo);
                            }} disabled={true}>
                            <p>{`50% promocode`}</p>
                        </button>
                        {!!new50Promo &&
                        <button className={`block p-2 b-1 row w-flex mb-2 btn-double`} data-color={'light-gray'} onClick={() => {
                                    navigator.clipboard.writeText(new50Promo ?? '');
                                }}>
                            <p data-size={5} data-color={`black`}>{new50Promo}</p>
                            <img src={CopyBlack} alt={`copy`} title={`Copy link`}></img>
                        </button>}
                    </div>
                    
                    <div className={`w-flex`}>
                        <button className={`block btn-flex mobile w-flex centered mb-2`} data-color={`gray`} type={`button`} onClick={() => {
                                GenPromoCode(setNew100Promo, 100);
                            }} disabled={true}>
                            <p>{`100% forever`}</p>
                        </button>
                        {!!new100Promo &&
                        <button className={`block p-2 b-1 row w-flex mb-2 btn-double`} data-color={'light-gray'} onClick={() => {
                                    navigator.clipboard.writeText(new100Promo ?? '');
                                }}>
                            <p data-size={5} data-color={`black`}>{new100Promo}</p>
                            <img src={CopyBlack} alt={`copy`} title={`Copy link`}></img>
                        </button>}
                    </div>
                    
                    <form className={`row nowrap mb-2`} onSubmit={e => {
                        e.preventDefault();
                        setUser(undefined);
                        GetUser(userId, setUser, () => { setUserNotFound(true) });
                    }}>
                        <input autoComplete={`off`} className={`mobile`} placeholder={`Enter user ID or Email`} type={`text`}
                            id={`fname-input`} value={userId} onChange={e => { 
                                setUserNotFound(false);
                                setUserId(e.target.value); 
                            }} data-size={6}></input> 
                        <button className={`block btn-flex mobile centered`} data-color={`black`} type={`submit`}>
                            <p>{`Find`}</p>
                        </button>
                    </form>
                    {!!user ? <>
                    <div className={`row top left gap-2 mb-2 nowrap`}>
                        <div>
                            <NewAvatar name={user?.name} avatar={user?.avatar?.link} size={5} type={user?.avatar?.style}/>
                            <div className={`block label btn-circled-32 desktop centered my-2`} data-border={`blue-2`}
                                style={{position: `relative`}} onClick={e => {
                                    e.stopPropagation();
                                    setShowProjects(false);
                                    setShowRequests(true);
                                }}>
                                <p>{user?.requests?.length}</p>
                                {showRequests && user?.requests?.length > 0 &&
                                <div className={`block b-1 px-1 pt-1`} style={{position: `absolute`, left: 0, bottom: 24, zIndex: 2}}
                                    data-color={`black-40-opacity`}>
                                    {user.requests.map((elem: any, index: number) => {return (
                                        <button className={`block btn-circled-24 desktop mb-1`} onClick={() => {
                                            setRequestId(elem?.id);
                                        }} key={index}>
                                            <p>{elem?.id}</p>
                                        </button>
                                    )})}
                                </div>}
                            </div>
                            <div className={`block label btn-circled-32 desktop centered`} data-border={`green-2`}
                                style={{position: `relative`}} onClick={e => {
                                    e.stopPropagation();
                                    setShowRequests(false);
                                    setShowProjects(true);
                                }}>
                                <p>{user?.projects?.length}</p>
                                {showProjects && user?.projects?.length > 0 &&
                                <div className={`block b-1 px-1 pt-1`} style={{position: `absolute`, left: 0, top: 24, zIndex: 2}}
                                        data-color={`black-40-opacity`}>
                                    {user.projects.map((elem: any, index: number) => {return (
                                        <button className={`block btn-circled-24 desktop mb-1`} onClick={() => {
                                            ClipBoard(elem.url, () => { globalContext.setClipboardOk(true) })
                                        }} key={index}>
                                            <p>{elem?.url}</p>
                                        </button>
                                    )})}
                                </div>}
                            </div>
                        </div>
                        <div>
                            <p data-size={5} className={`semibold seminarrow`}>
                                {user?.name}
                            </p>
                            <p data-size={6} className={`mb-1`} data-color={`black-40-opacity`}>{user?.occupation}</p>
                            <p data-size={7} className={`mb-2`} data-lines={10}>{user?.about}</p>
                        </div>
                    </div> 
                    <div className={`mb-2`}>
                        <p data-size={6} className={`mb-1`} data-color={`black-40-opacity`}>
                            If the user is shown in recommendations:
                        </p>
                        <div className={`row left nowrap`}>
                            <button className={`block btn-rectangle-32 mobile`} data-border={`green-2`}
                                    data-color={user?.hidden ? null : `green`} onClick={() => {
                                        setUser((val: any) => SetDict(val, [`hidden`], false));
                                    }}>
                                <p>{`Shown`}</p>
                            </button>
                            <button className={`block btn-rectangle-32 mobile`} data-border={`coral-2`}
                                    data-color={user?.hidden ? `coral` : null} onClick={() => {
                                        setUser((val: any) => SetDict(val, [`hidden`], true));
                                    }}>
                                <p>{`Hidden`}</p>
                            </button>
                        </div>
                    </div>                    
                    <div className={`mb-2`}>
                        <p data-size={6} className={`mb-1`} data-color={`black-40-opacity`}>
                            Set user's permissions: 
                        </p>
                        <div className={`row left nowrap`}>
                            <button className={`block btn-rectangle-32 mobile`} data-border={`green-2`}
                                    data-color={user?.role === "ADMINISTRATOR" ? `green` : null} onClick={() => {
                                        setUser((val: any) => SetDict(val, [`role`], "ADMINISTRATOR"));
                                    }} disabled={cookie[`user_id_cookie`] === user?.id && user?.role !== "ADMINISTRATOR"}>
                                <p>{`Admin`}</p>
                            </button>
                            <button className={`block btn-rectangle-32 mobile`} data-border={`blue-2`}
                                    data-color={user?.role === "EDITOR" ? `blue` : null} onClick={() => {
                                        setUser((val: any) => SetDict(val, [`role`], "EDITOR"));
                                    }} disabled={cookie[`user_id_cookie`] === user?.id && user?.role !== "EDITOR"}>
                                <p>{`Editor`}</p>
                            </button>
                            <button className={`block btn-rectangle-32 mobile`} data-border={`coral-2`}
                                    data-color={user?.role === "USER" ? `coral` : null} onClick={() => {
                                        setUser((val: any) => SetDict(val, [`role`], "USER"));
                                    }} disabled={cookie[`user_id_cookie`] === user?.id && user?.role !== "USER"}>
                                <p>{`User`}</p>
                            </button>
                        </div>
                    </div> 
                    <div className={`mb-2`} hidden={!user?.subscription?.is_stripe_customer}>
                        <p data-size={6} className={`mb-1`} data-color={`black-40-opacity`}>
                            User's STRIPE status:
                        </p>
                        <div className={`row left nowrap`}>
                            <div className={`label block btn-rectangle-32 desktop`} 
                                data-border={user?.subscription?.is_stripe_customer ? 'green-2' : `gray-2`}>
                                <p data-size={6} data-color={user?.subscription?.is_stripe_customer ? 'green' : `gray`}>
                                    {user?.subscription?.is_stripe_customer ? `Customer` : 'Not STRIPE customer'}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={`mb-2`}>
                        <p data-size={6} className={`mb-1`} data-color={`black-40-opacity`}>
                            User's subscription status:<br/>
                            Expire at <span data-color={`dark-gray`}>
                                {DateTimeFromString(user?.subscription?.expire_at)}
                            </span>
                        </p>
                        <div className={`row left nowrap`}>
                            <button className={`block btn-rectangle-32 mobile`} data-border={`green-2`}
                                    data-color={!user?.subscription?.is_subscription_active ? null : `green`} onClick={() => {
                                        setUser((val: any) => SetDict(val, [`subscription`, `is_subscription_active`], true));
                                    }}>
                                <p>{`Active`}</p>
                            </button>
                            <button className={`block btn-rectangle-32 mobile`} data-border={`coral-2`}
                                    data-color={!user?.subscription?.is_subscription_active ? `coral` : null} onClick={() => {
                                        setUser((val: any) => SetDict(val, [`subscription`, `is_subscription_active`], false));
                                    }}>
                                <p>{`Disabled`}</p>
                            </button>
                        </div>
                    </div>
                    <div className={`mb-2`}>
                        <p data-size={6} className={`mb-1`} data-color={`black-40-opacity`}>
                            Activate user's subscription:
                        </p>                                    

                        <button className={`block btn-rectangle-32 mobile`} data-color={user?.subscription?.is_subscription_active ? `coral` : `green`}
                                onClick={() => { 
                                    ActivateSubscription(
                                        user?.id,
                                        !user?.subscription?.is_subscription_active,
                                        () => { setUser((user: any) => SetDict(user, ["subscription", "is_subscription_active"], !user?.subscription?.is_subscription_active)) },
                                        () => {},
                                    )
                                }} data-border={`green-2`}>
                            <p>{user?.subscription?.is_subscription_active ? `Deactivate` : `Activate`}</p>
                        </button>
                    </div>                   
                    <div className={`mb-2`}>
                        <p data-size={6} className={`mb-1`} data-color={`black-40-opacity`}>
                            Save changes: 
                        </p>
                        <div className={`row left nowrap`}>                                
                            <button className={`block btn-rectangle-32 mobile`} data-border={`black-2`}
                                    data-color={`black`} onClick={(e) => {
                                        PatchUser(user?.id, {
                                            role: user?.role,
                                            hidden: user?.hidden,
                                            subscription: user.subscription,
                                        }, () => { 
                                            setUserSaved(true);
                                        })
                                    }}>
                                <p>{`Save`}</p>
                            </button>                                	
                            <div className={`label block btn-rectangle-32 mobile`} data-border={`${userSaved ? `green` : `black-20-opacity`}-2`}>
                                {userSaved ? 
                                <p data-size={6} data-color={`green`} className={`semibold seminarrow`}>SAVED</p> :
                                <p data-size={6} data-color={`black-20-opacity`}>NOT SAVED</p>}
                            </div>
                        </div>
                    </div>
                    <button className={`block btn-rectangle-32 mobile mb-2`} data-color={`coral`}
                            onClick={() => { setConfirmDeleteUser(true) }} data-border={`coral-2`}>
                        <p>{`Delete user`}</p>
                    </button>
                    </> : (userNotFound && <p data-size={4} data-color={`coral`} className={`mb-2`}>404: User not found</p>)}

                    <form className={`row nowrap mb-2`} onSubmit={e => {
                        e.preventDefault();
                        setRequest(undefined);
                        GetRequest(requestId, setRequest, () => { setRequestNotFound(true) });
                    }}>
                        <input autoComplete={`off`} className={`mobile`} placeholder={`Enter request ID`} type={`text`}
                            id={`fname-input`} value={requestId} onChange={e => { 
                                setRequestNotFound(false);
                                setRequestId(e.target.value);
                            }} data-size={6}></input> 
                        <button className={`block btn-flex mobile centered`} data-color={`black`} type={`submit`}>
                            <p>{`Find`}</p>
                        </button>
                    </form>
                    {!!request ? <>
                    <div className={`row top left gap-2 mb-2 nowrap`}>
                        <NewAvatar name={request?.user?.name} avatar={request?.user?.avatar?.link} size={5} type={request?.user?.avatar?.style}/>
                        <div>
                            <p data-size={5} className={`semibold seminarrow mb-1`}>{request?.user?.name}</p>
                            <p data-size={7} className={`mb-2`}>{request?.answers[0]}</p>
                        </div>
                    </div>                    
                    <button className={`block btn-rectangle-32 mobile mb-2`} data-color={`coral`}
                                    onClick={() => { setConfirmDeleteRequest(true) }} data-border={`coral-2`}>
                        <p>{`Delete request`}</p>
                    </button> 
                    </> : (requestNotFound && <p data-size={4} data-color={`coral`} className={`mb-2`}>404: Request not found</p>)}

                    <button className={`block btn-flex mobile w-flex centered mb-2`} data-color={`gray`} type={`button`} onClick={() => {
                            if (!chats) { GetChats(setChats) } else { setChats(undefined) }
                        }}>
                        <p>{`${!!chats ? `Hide` : `Load`} all chats`}</p>
                    </button>
                    {!!chats && chats.map((elem: any, index: number) => {return (<Fragment key={index}>
                        <div className={`label block btn-circled-32 mobile row nowrap mb-1`} data-color={`light-gray`}
                                onClick={() => {
                                    setChats((val: any) => SetDict(val, [index, `opened`], !val[index]?.opened));
                                }}>
                            <p className={`ellipsis`}>{elem.chat_id}</p>
                            <div className={`block b-3 h-3 centered noshrink px-1`}>
                                <p data-size={7}>{`${elem.message_number} messages`}</p>
                            </div>
                        </div>
                        {elem?.opened && elem.messages.map((elem_: any, index_: number) => {return (
                        <div className={`mb-2`} key={index_}>
                            <div className={`w-flex row nowrap px-2`}>
                                <p data-size={7} className={`semibold seminarrow ellipsis`}>{`${elem_?.from_user}:`}</p>
                                <div className={`row left nowrap noshrink`}>
                                    <div className={`label block btn-circled-24 mobile noshrink`} data-color={`light-blue`}>
                                        <p>{DateTimeFromString(elem_?.created_at ?? 100)}</p>
                                    </div>
                                    <img src={elem_?.is_read ? OkGreen : OkGray} alt={``} className={`w-2`}></img>
                                </div>
                            </div>
                            <p data-size={6} className={`mb pl-3`}>{`${elem_?.content}`}</p>
                        </div>)})}
                    </Fragment>)})}                    

                    <button className={`block btn-flex mobile w-flex centered mb-2`} data-color={`gray`} type={`button`} onClick={() => {
                            if (!ratings) { GetRatings(setRatings) } else { setRatings(undefined) }
                        }}>
                        <p>{`${!!ratings ? `Hide` : `Load`} all ratings`}</p>
                    </button>
                    {!!ratings && (!!ratings?.length ? ratings.map((elem: any, index: number) => {return (<Fragment key={index}>
                        <div className={`label block btn-circled-32 mobile row nowrap mb-1 clickable`} data-color={`light-gray`}
                                onClick={() => {
                                    setRatings((val: any) => SetDict(val, [index, `opened`], !val[index]?.opened));
                                }}>
                            <p className={`ellipsis`}>{elem.user_id}</p>
                            <div className={`block b-3 h-3 centered noshrink px-1`}>
                                <p data-size={7}>{`${elem.rating?.length} feedbacks`}</p>
                            </div>
                        </div>
                        {elem?.opened && elem.rating.map((elem_: any, index_: number) => {return (
                        <p key={index_} className={`mb-1`}>{elem_}</p>)})}
                    </Fragment>)}) : <p data-size={4} data-color={`coral`} className={`mb-2`}>No ratings found</p>)}

                    <div className={`neg-mt-2`}></div>
                </div>
            </div>

            {confirmDeleteUser &&
            <ConfirmPopup title={`Are you sure you want to delete ${user?.name}?`} show={confirmDeleteUser} 
                    setShow={setConfirmDeleteUser} _id={`yesno`}
                    color_1={'black'} buttonTitle_1={`No`} onClick_1={() => {}} mobile={true}
                    color_2={'light-gray'} buttonTitle_2={`Yes`} onClick_2={() => {
                        ProfileDelete(user?.links?.email, () => {
                            setConfirmDeleteUser(false); 
                            setUser(undefined);
                        }, () => {                            
                            setConfirmDeleteUser(false);
                        })
                    }} close={'first'}/>}

            {confirmDeleteRequest &&
            <ConfirmPopup title={`Are you sure you want to delete this request?`} show={confirmDeleteRequest} 
                    setShow={setConfirmDeleteRequest} _id={`yesno`}
                    color_1={'black'} buttonTitle_1={`No`} onClick_1={() => {}} mobile={true}
                    color_2={'light-gray'} buttonTitle_2={`Yes`} onClick_2={() => {
                        CardDelete(request.id, () => {
                            setRequest(undefined);
                            setConfirmDeleteRequest(false); 
                        }, () => {                            
                            setConfirmDeleteRequest(false);
                        })
                    }} close={'first'}/>}
        </div>
    )
}

export const AdminEditArticle = (props: any) => {
    const [ blocks, setBlocks ] = useState<any[]>([
        {content: 1,},  
        {content: 2,},  
        {content: 3,},  
        {content: 4,},  
        {content: 5,}, 
    ])

    const ReplaceElement = (setArray: Function, target: number, newPosition: number) => {
        setArray((val: any[]) => val.reduce((array: any[], elem: any, index: number) => {
            if (index === newPosition) {
                return array.concat([val[target]]);
            } else if (index === target) {
                return array.concat([val[index + (newPosition > target ? 1 : -1)]]);
            } else if (index > Math.min(target, newPosition) && index < Math.max(target, newPosition)) {                
                return array.concat([val[index + (newPosition > target ? 1 : -1)]]);
            } else {
                return array.concat([elem]);
            }
        }, []))
    }

    const InsertElement = (setArray: Function, elem: any, position: number) => {
        setArray((val: any[]) => val.slice(0, position).concat([elem]).concat(val.slice(position)))
    }

    useEffect(() => {
        console.log(blocks)
    }, [blocks])

    return (<div className={`body top desktop`}> 
        <TopBarDesktop func={() => {
            const newWindow: WindowProxy | null = window.open(`https://osmos.social?nologin=true`, '_blank');
            if (newWindow) {
                newWindow.focus();
            }
        }} toTop={false} color={'light-gray'}
        className={`w-flex`} hideNotifications={true}/>

        <div className={`w-flex px-5 md-px-3`}>
            <div className={`row left gap-2 mb-6`}>
                {blocks.map((elem: any, index: number, array: any[]) => {return (
                    <div className={`block b-3 w-8 h-8 centered inner-hover`} data-color={`gray`} 
                         style={{position: `relative`}} key={index}
                         onMouseEnter={e => {
                            setBlocks(val => SetDict(val, [index, "active"], true));
                         }} onMouseLeave={e => {
                            setBlocks(val => SetDict(val, [index, "active"], false));
                         }}>
                        <p data-size={2}>{elem?.content}</p>

                        <div className={`row h-flex left inner-hover min-w-3`} style={{position: `absolute`, left: -22}}>
                            <button className={`block btn-rectangle-32 p-left p-right desktop row child-hover`} data-color={`black`}
                                    onClick={() => { 
                                        InsertElement(setBlocks, {content: `new`}, index); 
                                    }}>
                                <img src={PlusWhite} alt={`>`} className={`w-2 m`}></img>
                            </button>
                        </div>

                        <div className={`row left gap-0 child-hover`} style={{position: `absolute`, right: 14, bottom: 14}}>
                            <button className={`block btn-rectangle-32 p-left p-right desktop row gap-0`} data-color={`black`}
                                    onClick={() => { 
                                        if (index > 0) {
                                            ReplaceElement(setBlocks, index, 0);
                                        } 
                                    }}>
                                <img src={AngleRightWhite2} alt={`>`} className={`w-3`} style={{transform: `rotate(180deg)`}}></img>
                                <img src={AngleRightWhite2} alt={`>`} className={`w-3 neg-ml-3`} style={{transform: `rotate(180deg)`}}></img>
                            </button>
                            <button className={`block btn-rectangle-32 p-left p-right desktop row`} data-color={`black`}
                                    onClick={() => { 
                                        if (index > 0) {
                                            ReplaceElement(setBlocks, index, index - 1);
                                        } 
                                    }}>
                                <img src={AngleRightWhite2} alt={`>`} className={`w-3`} style={{transform: `rotate(180deg)`}}></img>
                            </button>
                            <button className={`block btn-rectangle-32 p-left p-right desktop row`} data-color={`black`}
                                    onClick={() => { 
                                        if (index + 1 < array?.length) {
                                            ReplaceElement(setBlocks, index, index + 1);
                                        } 
                                    }}>
                                <img src={AngleRightWhite2} alt={`>`} className={`w-3`}></img>
                            </button>
                            <button className={`block btn-rectangle-32 p-left p-right desktop row gap-0`} data-color={`black`}
                                    onClick={() => { 
                                        if (index + 1 < array?.length) {
                                            ReplaceElement(setBlocks, index, array?.length - 1);
                                        } 
                                    }}>
                                <img src={AngleRightWhite2} alt={`>`} className={`w-3`}></img>
                                <img src={AngleRightWhite2} alt={`>`} className={`w-3 neg-ml-3`}></img>
                            </button>
                        </div>
                    </div>
                )})}
            </div>
        </div>
    </div>)
}