import React from "react";

export const PrivacyPolicy = (props: any) => {
    return (<div className={`${props.desktop ? 'h-flex' : 'w-flex'} ${props.className}`}
                 onScroll={e => { if (props.onScroll) { props.onScroll(e) } }}>
        <h3 className={`mb-3`}>{`PRIVACY POLICY`}</h3>
        <p className={`text-5 mb-3`} style={{fontStyle: 'italic'}}>{`Effective date: August 1, 2023.`}</p>
        <p className={`text-5 mb-3`}>
            {`Welcome to Osmos, a software platform that enables users to meet with others around them that share interests and goals. The platform is managed by Match Global, Inc with physical mailing location  2810 NChurch St PMB 98437, Wilmington, Wilmington DE 19802, a software company that uses machine learning to create meaningful connections.`}
        </p>
        <p className={`text-5 mb-3`}>
            {`This Privacy Policy ("Our Policy", or the "Policy") explains how we collect, use, share and protect information in relation to our website (osmos.social), and any software provided on or in connection with our services (collectively, the "Service"), and your choices about the collection and use of your information.`}
        </p>
        <p className={`text-5 mb-3`}>
            {`Our Policy applies to all visitors, users, and others who access the Service ("Users").`}
        </p>
        <h4 className={`mb-3`}>
            {`1. INFORMATION WE COLLECT`}
        </h4>
        <p className={`text-5 semibold mb-3`}>
            {`Registration Information`}
        </p>
        <p className={`text-5 mb-3`}>
            {`We may collect information needed to register and sign into the Service, such as your e-mail address and phone number (if applicable).`}
        </p>
        <p className={`text-5 semibold mb-3`}>
            {`Profile Information`}
        </p>
        <p className={`text-5 mb-3`}>
            {`We may collect personally identifiable information such as your name, picture, country, city, and school name (if applicable).`}
        </p>
        <p className={`text-5 semibold mb-3`}>
            {`Location Information`}
        </p>
        <p className={`text-5 mb-3`}>
            {`We may collect location data while you are actively using the Service on a mobile device. This information is not shared externally and is used by the Service to determine connections within your vicinity who are also actively using the Service.`}
        </p>
        <p className={`text-5 semibold mb-3`}>
            {`Google Permissions`}
        </p>
        <p className={`text-5 mb-3`}>
            {`We may ask for some Google permissions allowing us to register and authenticate you on the Service using Google. By default, we retrieve certain information such as your Google id, email address, contacts, and calendar. This information is used to help us easily connect you to the Service and to help us find new connections for you to meet efficiently.`}
        </p>
        <p className={`text-5 semibold mb-3`}>
            {`Compliance with Google API Services User Data Policy`}
        </p>
        <p className={`text-5 mb-3`}>
            {`Osmos's use and transfer of information received from Google APIs will adhere to the `}
            <a href={`https://developers.google.com/terms/api-services-user-data-policy`} target={`_blank`}>
                <span data-color={`blue`}>{`Google API Services User Data Policy`}</span>
            </a>
            {`, including the Limited Use requirements. This ensures our commitment to safeguarding your data in accordance with Google's standards.`}
        </p>
        <p className={`text-5 semibold mb-3`}>
            {`Contacts List and Friends`}
        </p>
        <p className={`text-5 mb-3`}>
            {`In addition to your contacts made available to us via Google, we may ask for additional contacts, either from your device, email, or other third-party social media site.`}
        </p>
        <p className={`text-5 mb-3`}>
            {`If you provide us with permission to use your contacts list, the Service will access your contacts list to expand the social network of friends using Osmos. You understand and agree that you are responsible for any charges that apply to communications sent from your device.`}
        </p>
        <p className={`text-5 semibold mb-3`}>
            {`Logging Information`}
        </p>
        <p className={`text-5 mb-3`}>
            {`We log information reported by our servers or your device each time you use the Service. This information includes your Internet Protocol ("IP") address, browser type, device platform (iOS, Android), and user id. This information is used for diagnostics and to improve our Service.`}
        </p>
        <h4 className={`mb-3`}>
            {`2. HOW WE USE YOUR INFORMATION`}
        </h4>
        <p className={`text-5 mb-3`}>
            {`In addition to some of the specific uses of information we describe in this Privacy Policy, we may use information that we receive to:`}
        </p>        
        <ul className={`mb-3 ml-4`}>
            <li>
                <p className={`text-5 mb`}>
                    {`help you efficiently sign in and authenticate with the Service`}
                </p>
            </li>     
            <li>
                <p className={`text-5 mb`}>
                    {`provide personalized suggestions and information to you and others`}
                </p>
            </li>     
            <li>
                <p className={`text-5 mb`}>
                    {`provide, improve, test, and monitor the effectiveness of our Service`}
                </p>
            </li>     
            <li>
                <p className={`text-5 mb`}>
                    {`develop and test new products and features`}
                </p>
            </li>     
            <li>
                <p className={`text-5 mb`}>
                    {`for technical support and detecting bugs and/or crashes in our Service`}
                </p>
            </li>     
            <li>
                <p className={`text-5 mb`}>
                    {`monitor metrics such as total number of visitors, traffic, and geographic patterns`}
                </p>
            </li>
        </ul>
        <h4 className={`mb-3`}>
            {`3. SHARING OF YOUR INFORMATION`}
        </h4>
        <p className={`text-5 mb-3`}>
            {`We will not rent or sell your information to third parties without your consent, except as noted in this Policy.`}
        </p>
        <p className={`text-5 semibold mb-3`}>
            {`Assignment and/or Change of Control`}
        </p>
        <p className={`text-5 mb-3`}>
            {`If we incorporate, sell, or otherwise transfer part or the whole of the Service, User Content and any other information collected through the Service may be assigned and/or transferred to the new entity. The buyer, transferee, or new entity will have to honor the commitments we have made in this Privacy Policy.`}
        </p>
        <p className={`text-5 semibold mb-3`}>
            {`Instances where we may be required to share your information`}
        </p>
        <p className={`text-5 mb-3`}>
            {`The service will disclose your information where required to do so by law or by judicial process or if we reasonably believe that such action is necessary to:`}
        </p>
        <ul className={`mb-3 ml-4`}>
            <li>
                <p className={`text-5 mb`}>
                    {`comply with the law and the reasonable requests of law enforcement`}
                </p>
            </li>     
            <li>
                <p className={`text-5 mb`}>
                    {`enforce our Terms of Use`}
                </p>
            </li>     
            <li>
                <p className={`text-5 mb`}>
                    {`protect the security or integrity of our Service`}
                </p>
            </li>     
            <li>
                <p className={`text-5 mb`}>
                    {`exercise or protect the rights, property, or personal safety of Osmos, our Users or others`}
                </p>
            </li>
        </ul> 
        <h4 className={`mb-3`}>
            {`4. HOW WE STORE YOUR INFORMATION`}
        </h4>
        <p className={`text-5 semibold mb-3`}>
            {`Storage and Processing`}
        </p>
        <p className={`text-5 mb-3`}>
            {`Information collected through the Service may be stored and processed in the United States, or any other country in which the Service is made available. The information will be stored in tools, machines, databases and/or facilities provided and maintained by Osmos or Service Providers.`}
        </p>
        <p className={`text-5 mb-3`}>
            {`Anonymized user data may be accessible to certain types of persons involved with the operation of the Service (administration, sales, marketing, legal, system administration, developers, and/or designers) or Service Providers (such as third party technical service providers, mail carriers, hosting providers, IT companies, communications agencies) appointed, if necessary to transfer, back-up, or store the data.`}
        </p>
        <p className={`text-5 mb-3`}>
            {`Your privacy settings may also be affected by changes made by other social media services. We are not responsible for the functionality, privacy, or security measures of any other organization.`}
        </p>
        <h4 className={`mb-3`}>
            {`5. YOUR CHOICES ABOUT YOUR INFORMATION`}
        </h4>
        <p className={`text-5 semibold mb-3`}>
            {`Your account information and profile/privacy settings`}
        </p>        
        <ul className={`mb-3 ml-4`}>
            <li>
                <p className={`text-5 mb`}>
                    {`You may update your account, including privacy and permissions settings, at any time by accessing the Service and changing your profile settings.`}
                </p>
            </li>     
            <li>
                <p className={`text-5 mb`}>
                    {`You may unsubscribe from any email communications or promotions that are not Service-related (e.g., account verification, purchase and billing confirmations and reminders, changes/updates to features of the Service, technical and security notices are all Service-related).`}
                </p>
            </li>
        </ul>
        <p className={`text-5 semibold mb-3`}>
            {`How long we keep your User Content`}
        </p>
        <p className={`text-5 mb-3`}>
            {`Following termination or deactivation of your account, Osmos may retain information (including your profile information) and User Content for a commercially reasonable time for backup, archival, and/or audit purposes.`}
        </p>
        <h4 className={`mb-3`}>
            {`6. CHANGES TO OUR PRIVACY POLICY`}
        </h4>
        <p className={`text-5 mb-3`}>
            {`Osmos may modify or update this Privacy Policy from time to time, so please review it periodically. We may provide you additional forms of notice of modifications or updates as appropriate under the circumstances. Your continued use of Osmos or the Service after any modification to this Privacy Policy will constitute your acceptance of such modification.`}
        </p>
        <p data-size={5} className={`mb-3`}>
            {`Copyright © 2023 Match Global, Inc Osmos `}
            <a href={`mailto:support@osmos.social`}>
                <span data-color={`blue`}>{`support@osmos.social`}</span>
            </a>
            {`.`}
        </p>
    </div>)
}

export const UserAgreement = (props: any) => {
    return (<div className={`${props.desktop ? 'h-flex' : 'w-flex'} ${props.className}`}
                 onScroll={e => { if (props.onScroll) { props.onScroll(e) } }}>
        <h3 className={`mb-3`}>{`TERMS OF SERVICE`}</h3>
        <h4 className={`mb-3`}>
            {`Osmos is matchmaking service for professional networking.`}
        </h4>
        <p data-size={5} className={`mb-3`} style={{fontStyle: 'italic'}}>{`Effective date: June 25, 2024.`}</p>
        <p data-size={5} className={`mb-3`}>
            {`These Terms of Service are made to explain how Osmos works. For convenience, We use the following terms:`}
        </p>        
        <p data-size={5} className={`semibold seminarrow mb-1`}>
            {`Osmos website`}
        </p>
        <p data-size={5} className={`mb-3`}>
            {`Here is the link: `}
            <a href={`https://osmos.social`} target={`_blank`}>
                <span data-color={`blue`}>{`https://osmos.social`}</span>
            </a>
            {`, means all its content and links (together called the "Website");`}
        </p>      
        <p data-size={5} className={`semibold seminarrow mb-1`}>
            {`Services`}
        </p>
        <p data-size={5} className={`mb-3`}>
            {`Website includes accessing, browsing, registering, support correspondence, chats, product and services information;`}
        </p>     
        <p data-size={5} className={`semibold seminarrow mb-1`}>
            {`We, Osmos, Our, Us`}
        </p>
        <p data-size={5} className={`mb-3`}>
            {`Refers to "Match Global, Inc", EIN 35-2822216 , registered under the address: 2110 Artesia Boulevard СA 90278 US, Suite B 109, Redondo Beach, CA, 90278, Delaware incorporated;`}
        </p>  
        <p data-size={5} className={`semibold seminarrow mb-1`}>
            {`User, You`}
        </p>
        <p data-size={5} className={`mb-3`}>
            {`The User, registered within the Services and uses Services.`}
        </p> 
        <h4 className={`mb-3 regular`}>
            {`We encourage You to read these `}
            <span className={`semibold`}>{`Terms of Service`}</span>
            {` carefully before using the Services.`}
        </h4>
        <p data-size={5} className={`mb-3`}>
            {`We encourage You to read these Terms of Service carefully before using the Services. Users can access, print, download and save these Terms of Service at any time. The Terms of Service will be permanently accessible on the Website. The use of the Services by Users implies acceptance of all conditions contained in these Terms of Service. By using any of the Services You confirm that You accept these Terms of Service and that You agree to comply with them. If You do not agree, You must not use the Services.`}
        </p> 
        <p data-size={5} className={`mb-3`}>
            {`Osmos legally reserves the right to deny access, at any time and without prior notice, to those Users who strictly do not comply with these Terms of Service.`}
        </p> 
        <p data-size={5} className={`mb-3`}>
            {`In case You have any questions relating to the conditions, contained in these Terms of Service, You can contact Us by email to `}
            <a href={`mailto:support@osmos.social`}>
                <span data-color={`blue`}>{`support@osmos.social`}</span>
            </a>            
            {`. Your question will be responded during 7 (seven) days.`}
        </p> 
        <p data-size={5} className={`mb-3`}>
            {`Feel free to contact Us at any time convenient for You with any queries. We are here to help!`}
        </p> 
        <h4 className={`mb-3`}>
            {`Use of the Services`}
        </h4>
        <p data-size={5} className={`mb-3`}>
            {`Use of the Services is entirely voluntary and under the sole and exclusive responsibility of the User, provided the User do not violate intellectual property or damage the Services offered. To use the Services, You need to register and to purchase a subscription.`}
        </p>
        <h4 className={`mb-3`}>
            {`Osmos Subscription`}
        </h4>
        <p data-size={5} className={`mb-3`}>
            {`When subscribed, the User gets the opportunity to use all Osmos features, post requests and browse other people’s requests, get matches, сonnect with other users. If You have any questions or You would like to give some feedback on subscription plans, please email Us at `}
            <a href={`mailto:support@osmos.social`}>
                <span data-color={`blue`}>{`support@osmos.social`}</span>
            </a>
            {`.`}
        </p>
        <p data-size={5} className={`mb-3`}>
            {`We offer monthly paid subscription plans.`}
        </p>
        <p data-size={5} className={`mb-3`}>
            {`Please note that when you purchase a subscription, the sale is final, and we will not provide a refund. It is fully and solely the User’s responsibility to decide whether the User wants to proceed with the payment.`}
        </p>
        <p data-size={5} className={`mb-3`}>
            {`If You have any questions regarding subscriptin, please do not hesitate to contact Us via email to `}
            <a href={`mailto:support@osmos.social`}>
                <span data-color={`blue`}>{`support@osmos.social`}</span>
            </a>
            {`.`}
        </p>
        <h4 className={`mb-3`}>
            {`Payments`}
        </h4>
        <p data-size={5} className={`mb-3`}>
            {`If You decide to subscribe, You have to follow the payment obligation rules: the payment to be done each month. You can cancel it anytime, before prolongation of the subscription for new period.`}
        </p>
        <p data-size={5} className={`mb-3`}>
            {`For Your convenience, subscription automatically renews unless you cancel your subscription at least 24 (twenty four) hours before the end of the current period. Your account will be charged for renewal within 24 (twenty four) hours prior to the end of the current period. You can cancel your subscription in Account Settings after purchase anytime.`}
        </p>
        <p data-size={5} className={`mb-3`}>
            {`If the User decides to leave from the Service at his own request, We do not refund the subscription funds. If the User was deleted by the Service due to a violation of the Terms, Osmos also does not refund subscription payment.`}
        </p>
        <p data-size={5} className={`mb-3`}>
            {`If Your bank details have changed, You must update billing information in Your account so We can renew Your subscription in due time.`}
        </p>
        <h4 className={`mb-3`}>
            {`Users Registration`}
        </h4>
        <p data-size={5} className={`mb-3`}>
            {`We always ask You to provide actual and true information about You when You register, thus You and other users have an inspiring conversation, not a frustrating one. To register in Osmos, You have to provide the following data:`}
        </p>
        <ul className={`mb-3 ml-4`}>
            <li>
                <p className={`text-5 mb`}>
                    {`your first and last name`}
                </p>
            </li>     
            <li>
                <p className={`text-5 mb`}>
                    {`your industry`}
                </p>
            </li>     
            <li>
                <p className={`text-5 mb`}>
                    {`company and role`}
                </p>
            </li>     
            <li>
                <p className={`text-5 mb`}>
                    {`your intro (personal introduction)`}
                </p>
            </li>     
            <li>
                <p className={`text-5 mb`}>
                    {`your location`}
                </p>
            </li>     
            <li>
                <p className={`text-5 mb`}>
                    {`expectations from meetings (You can choose one of the options)`}
                </p>
            </li>     
            <li>
                <p className={`text-5 mb`}>
                    {`business request`}
                </p>
            </li>     
            <li>
                <p className={`text-5 mb`}>
                    {`your photo`}
                </p>
            </li>     
            <li>
                <p className={`text-5 mb`}>
                    {`your email address`}
                </p>
            </li>
        </ul>
        <p data-size={5} className={`mb-3`}>
            {`All the information must be truthful, accurate, updated and complete. For this purpose, each User guarantees the authenticity of all data provided when You create the account. If You have any questions on how We process Your Personal Data, please see the `}
            <a href={`/privacy`}>
                <span data-color={`blue`}>{`Privacy Policy`}</span>
            </a>
            {`.`}
        </p>
        <p data-size={5} className={`mb-3`}>
            {`This information is stored and protected by email verification, and access to Your account information always requires email verification. If You provide any information that is untrue, inaccurate, not current or incomplete, or We have reasonable grounds to suspect provided data as such, We have the right to suspend or terminate Your account and restrict any and all current or future use of the Services. Osmos reserves the right to disable any account at any time, if the User has failed to comply with any of the provisions of these Terms of Service.`}
        </p>
        <h4 className={`mb-3`}>
            {`Data Protection`}
        </h4>
        <p data-size={5} className={`mb-3`}>
            {`We understand that We collect personal data from Our Users, so We have made the best efforts to secure Your data and to comply with applicable laws. These are stated in Our `}
            <a href={`/privacy`}>
                <span data-color={`blue`}>{`Privacy Policy`}</span>
            </a>
            {`. You will not be requested to provide Your Personal Data until You decide to register with Osmos. You must read and accept the `}
            <a href={`/privacy`}>
                <span data-color={`blue`}>{`Privacy Policy`}</span>
            </a>
            {` before providing Personal Data.`}
        </p>
        <p data-size={5} className={`mb-3`}>
            {`Please treat the access to Your email confidential and do not disclose it to any third party, as You are entirely responsible for the security and confidentiality of Your email account. No assignment of access to Your email account, even temporarily, to third parties is permitted. In the event that the User knows or suspects the loss, theft or use of his/her password by others or that anyone other than the User knows his/her password, then that the User must promptly bring this to the attention of Osmos at `}
            <a href={`mailto:support@osmos.social`}>
                <span data-color={`blue`}>{`support@osmos.social`}</span>
            </a>
            {`.`}
        </p>
        <h4 className={`mb-3`}>
            {`Communications`}
        </h4>
        <p data-size={5} className={`mb-3`}>
            {`When You visit the Services or send emails to Us, You agree to receive communications from Us electronically. We will communicate with You via email or by posting notices via Services. A notice will be deemed to have been duly given on the 4th day after it was sent.`}
        </p>
        <h4 className={`mb-3`}>
            {`User Generated Content`}
        </h4>
        <p data-size={5} className={`mb-3`}>
            {`Osmos takes no responsibility and assumes no liability for any User generated content that You or any other User or third party posts or transmits using our Services.`}
        </p>
        <p data-size={5} className={`mb-3`}>
            {`You understand and agree that You may not upload to, distribute, or otherwise publish any content, information, or other material that (a) violates or infringes the copyrights, patents, trademarks, service mark, trade secrets, or other proprietary rights of any person; (b) is libelous, threatening, defamatory, obscene, indecent, pornographic, or could give rise to any civil or criminal liability under applicable law; or (c) includes any bugs, viruses, worms, trap doors, Trojan horses or other harmful code or properties.`}
        </p>
        <p data-size={5} className={`mb-3`}>
            {`We reserve the right to remove or modify User content, or change the way it's used in Osmos, for any reason. This includes User content that we believe violates these Terms of Service, `}
            <a href={`/privacy`}>
                <span data-color={`blue`}>{`Privacy Policy`}</span>
            </a>
            {` or any other policies.`}
        </p>
        <p data-size={5} className={`mb-3`}>
            {`If there was some abusive or unwanted content from another User inform us at `}
            <a href={`mailto:support@osmos.social`}>
                <span data-color={`blue`}>{`support@osmos.social`}</span>
            </a>
            {`. We will respond to Your request within 24 hours by removing the content or ejecting the User who provided the offending content.`}
        </p>
        <h4 className={`mb-3`}>
            {`Intellectual Property Rights`}
        </h4>
        <p data-size={5} className={`mb-3`}>
            {`Osmos is not liable for the use by the Users of information that includes, but is not limited to photographs of third parties (without their consent) in the User’s profile, for using texts/ phrases without specifying the author, as this is a violation of copyright. You may not post material belonging to a third party without permission from the copyright holder. This includes but is not limited to photographs, images and text. Should You believe that material, which belongs to a third party has been posted on Osmos  You are requested to contact Us with full details of the alleged copyright infringement including the User name.`}
        </p>
        <p data-size={5} className={`mb-3`}>
            {`None of the rights or obligations conferred or assumed under these Terms of Use will be construed as an assignment or license, in whole or in part, of the intellectual property rights, trademarks over the Services. It is expressly prohibited to reproduce, distribute, modify, transfer, communicate or make available to the public, extract, reuse, resend and in any other manner use by any means the Services or any of the parts or elements thereof (including, but not limited to text, design, graphics, information, data of Users, Trademark etc.). It is expressly prohibited to access or obtain the information, materials or content of the Services through means or procedures other than those which, if any, have been made available to Users or have been indicated for this purpose. You acknowledge that no title to the intellectual property in the Services is transferred to You. You further acknowledge that title and full ownership rights to the Services will remain the exclusive property of Osmos.`}
        </p>
        <h4 className={`mb-3`}>
            {`User Generated Content`}
        </h4>
        <p data-size={5} className={`mb-3`}>
            {`Only You are responsible for any content or information You provide on Your profile and any communication on Osmos.`}
        </p>
        <p data-size={5} className={`mb-3`}>
            {`No responsibility is assumed by Osmos for the publication of any User’s information, including pictures published by Users. While it is incumbent on the Users to decide on what information the Users wishes to make available to other Users.`}
        </p>
        <p data-size={5} className={`mb-3`}>
            {`Osmos does not verify the accuracy or truth of any information published by Users. We hope and are convinced that You’re a real person, although, We can never guarantee this to Our Users. We recommend You to be careful dealing with other Users and do not distribute Personal Data to strangers.`}
        </p>
        <p data-size={5} className={`mb-3`}>
            {`Osmos cannot control what happens between Users once they decide to meet in person.`}
        </p>
        <p data-size={5} className={`mb-3`}>
            {`Osmos does not review or censor other message content, but it reserves the right to remove contact details, or any content deemed to cause offense from introductory messages.`}
        </p>
        <h4 className={`mb-3`}>
            {`"As is"`}
        </h4>
        <p data-size={5} className={`mb-3`}>
            {`Osmos will not become involved in any domestic or private disputes between Users and does not provide any arbitration or settlement Services should a dispute arise between Users.`}
        </p>
        <p data-size={5} className={`mb-3`}>
            {`We do not represent or warrant to You that: (1) Your use of Osmos will meet Your requirements and expectations; (2) Your use of Osmos will be uninterrupted, timely, secure, or error-free; (3) any information or content obtained on or through Osmos will be accurate, complete, current, reliable, secure, or lawful; (4) any results of or in connection with Your use of Osmos will meet Your requirements or expectations; and (5) that defects in the operation or functionality of Osmos will be corrected.`}
        </p>
        <p data-size={5} className={`mb-3`}>
            {`Osmos undertakes to comply with the security legal obligations and to adopt all security measures that are mandatory under applicable law. However, Osmos cannot and does not warrant that internet transmissions are completely private or secure, that any information may not be read or intercepted by others, or that there will not be any viruses, other harmful elements in the content. Correlatively Osmos cannot accept any liability deriving therefrom.`}
        </p>
        <p data-size={5} className={`mb-3`}>
            {`You accept that we expressly disclaim all warranties and conditions of any kind, whether express or implied, including without limitations the implied warranties and conditions of Merchantability, Fitness for a Particular Purpose, and Non-Infringement.`}
        </p>
        <h4 className={`mb-3`}>
            {`Limitation of Liability`}
        </h4>
        <p data-size={5} className={`mb-3`}>
            {`Osmos excludes liability for the content that Users send to Osmos via email or any other means, therefore, the Users being liable for content or contributions sent by them. No matter how You provide information about Yourself, Osmos is not responsible for false information or unsuccessful meetings. Similarly, Osmos is not liable for the use that Users make of the content of the Services or their account, and any other material contained on the Services that potentially constitutes a violation of any regulations, national or international, of the rights of intellectual property or other rights of third parties. Similarly, Osmos will not be held liable for any possible security errors that may occur through the use of outdated versions of browsers or operating systems, or for any consequences that may arise from either by improper configuration, computer virus or any other cause not directly related to Osmos.`}
        </p>
        <p data-size={5} className={`mb-3`}>
            {`IN NO EVENT SHALL OSMOS BE LIABLE WHATSOEVER FOR ANY CONSEQUENTIAL, SPECIAL, INCIDENTAL, AND/OR LOSS OF PROFIT, DIRECT OR INDIRECT DAMAGES OF ANY KIND ARISING OUT OF THE DELIVERY, PERFORMANCE, OR USE OF THE SERVICES TO THE EXTENT PERMITTED BY APPLICABLE LAW. OSMOS'S LIABILITY FOR ANY CLAIM, WHETHER IN CONTRACT OR TORT, WILL NOT EXCEED THE GREATER OF U.S.1.00 OR THE TOTAL AMOUNT PAID BY THE USER IN THE TWELVE (12) MONTHS PRECEDING THE LAST EVENT GIVING RISE TO LIABILITY.`}
        </p>
        <h4 className={`mb-3`}>
            {`Liability of the User`}
        </h4>
        <p data-size={5} className={`mb-3`}>
            {`You may not publish content that contains advertising or commercial messages. You may not send messages of a commercial nature to Your Osmos companion via the site. Only You are responsible for ensuring that Your interaction with other Users is lawful.`}
        </p>
        <p data-size={5} className={`mb-3`}>
            {`The content of Your private photos may not contain illegal, inflammatory or grossly obscene material. It will be at Osmos’s discretion to delete content if so deemed. You are responsible for ensuring that message content is appropriate.`}
        </p>
        <p data-size={5} className={`mb-3`}>
            {`You are entirely responsible for all activities that occur under Your account. You agree to immediately notify Us of any unauthorized use of Your account or any other breach of security of which You become aware. You are responsible for taking precautions and providing security measures best suited for Your situation and intended use of the Services.`}
        </p>
        <h4 className={`mb-3`}>
            {`Changes to these Terms of Service and Services`}
        </h4>
        <p data-size={5} className={`mb-3`}>
            {`Osmos reserves the right to amend the content of these Terms of Service from time to time. Consequently, Osmos recommends that Users review the terms applicable at that time when they use the Services. Osmos may update the Website, change the content, design, and/or the configuration at any time without prior notice. It is Your responsibility to be aware of and abide by the current version of the Terms of Service.`}
        </p>
        <h4 className={`mb-3`}>
            {`Change of Ownership`}
        </h4>
        <p data-size={5} className={`mb-3`}>
            {`We may assign these Terms of Service in their entirety, without Your consent, to a corporate affiliate or in connection with a merger, acquisition, corporate reorganization, or sale of all or substantially all of Our assets. In case of change of ownership all the obligations before the Users, including subscription terms remain actual.`}
        </p>
        <h4 className={`mb-3`}>
            {`Severability`}
        </h4>
        <p data-size={5} className={`mb-3`}>
            {`These Terms of Service will be enforced to the fullest extent permitted under applicable law. If any provision of the Terms of Service are held by a court of competent jurisdiction to be contrary to law, the provision will be modified by the court and interpreted so as best to accomplish the objectives of the original provision to the fullest extent permitted by law, and the remaining provisions of the Terms of Service will be unchanged.`}
        </p>
        <h4 className={`mb-3`}>
            {`Governing Law`}
        </h4>
        <p data-size={5} className={`mb-3`}>
            {`These Terms of Service are governed by and construed in accordance with the laws of the State of Delaware. We may, at Our sole discretion, revise these Terms of Service and periodically update this document with the changes.`}
        </p>
        <h4 className={`mb-3 regular`}>
            {`We encourage You to review Our Terms of Service to `}
            <span className={`semibold`}>{`stay informed`}</span>
            {`.`}
        </h4>
        <h4 className={`mb-3 regular`}>
            {`If We make changes that materially alter Your rights, We will provide additional notice via email or through the Services.`}
        </h4>
        <p data-size={5} className={`mb-3`}>
            {`Copyright © 2024 Match Global, Inc Osmos `}
            <a href={`mailto:support@osmos.social`}>
                <span data-color={`blue`}>{`support@osmos.social`}</span>
            </a>
            {`.`}
        </p>
    </div>)
}