import { LOCALES } from "./locales";

export const messages = {
    [LOCALES.ENGLISH]: {
        reg_create_account: "Create your account",
        reg_continue_with: "Sign in with",
        reg_continue_with_email: "Continue with email",
    },
    [LOCALES.RUSSIAN]: {
        reg_create_account: "Создать аккаунт",
        reg_continue_with: "Продолжить через",
        reg_continue_with_email: "Продолжить через почту",
    }
}