import React, { useState, useEffect } from "react";

import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";

import {  
    SetDict,
    OnlyValid,
    PushHistory,
    LogOut,
    UUIDRegExp,
    SetMetaData,
    IsThisProfile,
    UniqueIdRegExp,
    IsClosedBlock,
} from "../elements/Data";

import { 
    CardRecommendation, 
    ChatsRequest, 
    NewOffer, 
    NotificationsRequest, 
    OffersRequest, 
    ProfileRequest, 
    RefreshId, 
    SubscriptionsRequest
} from "../Requests";
import {   
    LargeAvatar,  
    TopBar,
} from "../elements/Elements.jsx";
import { 
    ConfirmPopup, 
    AddRequestPopup,
    PopupHintMobile,
    NewMatchPopupMobile,
    AddProjectPopup,
    ProfilePopupMobile,
    PopupSuggestAFriendMobile,
    PopupUpdatePrivacyMobile,
    PopupCanHelpMobile,
    PopupSubscriptionMobile,
    PopupWelcomeMobile,
    PopupBecomeAMemberMobile2,
} from "../elements/Popups";
import { EditProfileMobile } from "./EditProfileMobile";
import posthog from "posthog-js";
import Smartlook from 'smartlook-client';
import { 
    ProfileChatsMobile, 
    ProfileFeedMobile, 
    ProfileInvitesMobile, 
    ProfileMatchMobile, 
    ProfilePageMobile, 
    ProfileRequestsMobile, 
    ProfileSettingsMobile 
} from "./ProfilePagesMobile";
import { ProfileContext } from "../elements/Contexts";
import { BottomNavBar } from "../elements/Elems";

const ProfileMobile = (props: any) => {
    const navigate = useNavigate();   
    const [ cookie ] = useCookies(['user_id_cookie']);
    const [ searchParams, setSearchParams ] = useSearchParams();
    const { id } = useParams();

    const [ loaded, setLoaded ] = useState<boolean>(false);
    const [ target, setTarget ] = useState<string | null>(null);
    const [ canAddRequest, setCanAddRequest ] = useState<boolean>(true);
    const [ addRequestPopup, setAddRequestPopup ] = useState<boolean>(false);
    const [ editRequest, setEditRequest ] = useState<any>();
    const [ addProjectPopup, setAddProjectPopup ] = useState<boolean | number>(false); 
    const [ largeAvatarPopup, setLargeAvatarPopup ] = useState<any>(null); 
    const [ updatePrivacyPopup, setUpdatePrivacyPopup ] = useState<boolean>(false);  
    const [ profilePopup, setProfilePopup ] = useState<any | null>(null);
    const [ suggestAFriendPopup, setSuggestAFriendPopup ] = useState<any>(null);
    const [ wishToHelpRequest1, setWishToHelpRequest1 ] = useState<any>(null);
    const [ wishToHelpPopup1, setWishToHelpPopup1 ] = useState<boolean>(false);
    const [ newChatOffer, setNewChatOffer ] = useState<any>();

    const [ showConfirmLeavePopup, setShowConfirmLeavePopup ] = useState<boolean>(false); 
    const [ isEdit, setIsEdit ] = useState<boolean>(false);  
    const [ leaveFunc, setLeaveFunc ] = useState<{page?: number, tab?: number, back?: boolean}>({}); 
    const [ activateSubscriptionPopup, setActivateSubscriptionPopup ] = useState<boolean>(false);
    const [ isSubscriptionActive, setIsSubscriptionActive ] = useState<boolean>(true);
    const [ welcomePopup, setWelcomePopup ] = useState<boolean>(false);
    const [ becomeAMemberPopup, setBecomeAMemberPopup ] = useState<boolean>(false);

    const [ tab, setTabRaw ] = useState<number>(0); 
    const setTab = (tab: number, withPage?: number) => {
        if (IsClosedBlock(withPage ?? page, tab) && !isSubscriptionActive) {
            setActivateSubscriptionPopup(true);
        } else {
            if (isEdit) {
                setLeaveFunc(val => SetDict(
                    SetDict(val, [`tab`], tab),
                    [`back`], 
                    false
                ));
                setShowConfirmLeavePopup(true);
            } else {
                setTabRaw(tab);
            }
        }
    } 
    const [ page, setPageRaw ] = useState<number>(Infinity); 
    const setPage = (page: number, withTab?: number) => {
        if (IsClosedBlock(page, withTab ?? tab) && !isSubscriptionActive) {
            setActivateSubscriptionPopup(true);
        } else {
            if (isEdit) {
                setLeaveFunc(val => SetDict(
                    SetDict(val, [`page`], page),
                    [`back`], 
                    false
                ));
                setShowConfirmLeavePopup(true);
            } else {
                setPageRaw(page);
            }
        }
    }

    const [ profile, setProfile ] = useState<any>();
    const [ subscriptions, setSubscriptions ] = useState<{
        followers: any[],
        follow: any[],
    }>()
    const [ recommendationsCommon, setRecommendationsCommon ] = useState<any[]>([]);
    const [ otherRecommendationsCommon, setOtherRecommendationsCommon ] = useState<any[]>([]);
    
    const [ chats, setChats ] = useState<any[] | null>(null);
    const [ offers, setOffers ] = useState<any[] | null>(null);
    const [ notifications, setNotifications ] = useState<any[]>([]);  
    const [ newMatch, setNewMatch ] = useState<any | null>();
    const [ requested, setRequested ] = useState<any[] | null>(null);
    const [ search, setSearch ] = useState<string>("");

    const [ hintPopup, setHintPopup ] = useState<{
        show: boolean,
        title?: string,
        description?: any,
        buttonTitle?: string,
        onClick?: Function,
        close?: boolean,
    }>();

    const [ otherProfile, setOtherProfile ] = useState<any>();

    const getId = (myId: string = cookie['user_id_cookie']) => {
        if (UUIDRegExp.test(id ?? '') || UniqueIdRegExp.test(id ?? '')) {
            return (id ? id : myId)
        } else {
            return myId
        }
    }
    
    const getPath = (
        page: number = 0,
        tab: number = 0
    ): string | null => {
        switch (page) {
            case 0: 
                switch (tab) {
                    case 0: // /profile/uuid
                        return profile?.unique_id ?? getId(cookie[`user_id_cookie`]);
                    case 1: // /profile/home
                        return profile?.unique_id ?? getId(cookie[`user_id_cookie`]);
                    case 2: // /profile/requests
                        return profile?.unique_id ?? getId(cookie[`user_id_cookie`]);
                    case 3: // /profile/edit
                        return `edit`;
                    case 4: // /profile/settings
                        return `settings`;
                    default:
                        return null
                }
            case 1:
                switch (tab) {
                    case 0: // /profile/feed
                        return `feed`; 
                    case 1: // /profile/explore
                        return `explore`;
                    default:
                        return `feed`;
                }
            case 2:                 
                switch (tab) {
                    case 0: // /profile/chat
                        return `chat`;
                    case 1: // /profile/offer
                        return `offer`;
                    case 2: // /profile/requested
                        return `requested`;
                    default:
                        return `chat`; 
                }
            case 3: // /profile/invites
                return `invites`; 
            case 4: // /profile/requests
                return `requests`;
            case 5: // /profile/match
                return `match`;
            default:
                return null;
        }
    }

    const getLoc = (
        path: string | undefined
    ): number[] => {
        switch(path) {
            case undefined:
                return [5, 0];
            case 'home':
                return [5, 0];
            case 'requests':
                return [0, 0];
            case 'edit':
                return [0, 3];
            case 'settings':
                return [0, 4]
            case 'feed':
                return [1, 1];                
            case 'explore':
                return [1, 1]
            case 'chat':
                return [2, 0];
            case 'offer':
                return [2, 1];
            case 'requested':
                return [2, 0];
            case 'invites':
                return [3, 0];
            case 'me':
                return [0, 0]               
            case 'match':
                return [5, 0]
            default:
                if (!UUIDRegExp.test(path ?? '') && !UniqueIdRegExp.test(path ?? ``)) {
                    navigate(`/404`);
                }
                return [0, 0];
        }
    }

    const UpdateData = (cookie: {user_id_cookie?: any}): void => {
        if (cookie['user_id_cookie']) {
            ProfileRequest(cookie['user_id_cookie'], setProfile, () => {
                LogOut(encodeURIComponent(`/profile/${id ?? 'home'}`));
            }, () => {
                navigate(`/403`);
            });                     
        } 

        if (getId(cookie['user_id_cookie']) !== cookie['user_id_cookie']) {
            ProfileRequest(getId(cookie['user_id_cookie']), setOtherProfile, () => {
                navigate(`/404`);
            }, () => {
                navigate(`/403`);
            });
        }
    }

    useEffect(() => {
        const pair = (notifications ?? []).find(val => val?.type === 1 && !val?.is_read);
        const this_pair = (notifications ?? []).find(val => (
            val?.type === 1 && 
            !val?.is_read && 
            val?.from_user === otherProfile?.id
        ));
        if (
            pair && 
            (IsThisProfile(getId(cookie[`user_id_cookie`]), profile) || this_pair) && 
            id !== `chat`
        ) {
            setNewMatch((val: any) => val === undefined ? pair : val);
        }
    }, [notifications])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: `instant`,
        });
        PushHistory(getPath(page, tab));
    }, [page, tab])

    useEffect(() => {
        if (profile) {
            sessionStorage.setItem('profile', JSON.stringify(profile));
            
            if (IsThisProfile(getId(cookie[`user_id_cookie`]), profile)) {
                SetMetaData(profile);
            } 

            if (cookie[`user_id_cookie`]) {
                setUpdatePrivacyPopup(!(profile?.first_login ?? [`update_privacy`]).includes('update_privacy'))
            }
        }
    }, [profile]);

    useEffect(() => {
        if (profile?.subscription?.is_subscription_active === false) {
            if (profile?.subscription?.first_flow) {
                navigate("/profile/match");
            } else {
                setActivateSubscriptionPopup(page !== 5);
            }
            setIsSubscriptionActive(false);
        } else if (profile?.subscription?.is_stripe_customer === false) {
            setBecomeAMemberPopup(true);
        }
    }, [profile?.subscription])

    useEffect(() => {
        if (otherProfile && searchParams.get(`request_id`)) {
            const targetRequest = OnlyValid(otherProfile?.requests ?? []).find((val: any) => val?.id === searchParams.get(`request_id`));
            if (targetRequest) {
                setProfilePopup({
                    ...otherProfile,
                    onlyRequest: targetRequest,
                    hideUser: true,
                })
            }
            setSearchParams({});
        }
        if (otherProfile) {            
            if (IsThisProfile(getId(cookie[`user_id_cookie`]), otherProfile)) {
                SetMetaData(otherProfile);
            }
        }
    }, [otherProfile])

    useEffect(() => {
        if (otherProfile?.about) {
            setOtherRecommendationsCommon([]);
            CardRecommendation(
                otherProfile?.id, 
                otherProfile?.about,
                setOtherRecommendationsCommon,
            )
        } else if (otherProfile?.industry) {
            setOtherRecommendationsCommon([]);
            CardRecommendation(
                otherProfile?.id, 
                otherProfile?.industry,
                setOtherRecommendationsCommon,
            )
        }
    }, [otherProfile?.about])

    useEffect(() => {
        if (profile?.about) {
            setRecommendationsCommon([]);
            CardRecommendation(
                cookie[`user_id_cookie`], 
                profile?.about,
                setRecommendationsCommon,
            )
        } else if (profile?.industry) {
            setRecommendationsCommon([]);
            CardRecommendation(
                cookie[`user_id_cookie`], 
                profile?.industry,
                setRecommendationsCommon,
            )
        }
    }, [profile?.about])

    useEffect(() => {
        if (profile?.requests) {
            sessionStorage.setItem('requests', JSON.stringify(profile?.requests));
            

            if (searchParams.get('new_chat_offer') === `true` && sessionStorage.getItem("new_chat_offer")) {
                setNewChatOffer(JSON.parse(sessionStorage.getItem("new_chat_offer") ?? '{}'));
                sessionStorage.removeItem("new_chat_offer");
            }
        }
        setCanAddRequest(profile?.requests?.length === OnlyValid(profile?.requests).length);
    }, [profile?.requests, otherProfile?.requests, target, loaded]);


    useEffect(() => {      
        if (target === 'projects') {
            const projectBlock = document.querySelector(`#profile-projects-block`);
            setTimeout(() => {
                if (projectBlock) {
                    window.scrollTo({
                        top: projectBlock.getBoundingClientRect().top + window.scrollY - 24,
                        behavior: `smooth`,
                    })
                    setTarget(null);
                }
            }, 200)
        }
        if (profile?.projects) {
            sessionStorage.setItem('projects', JSON.stringify(profile?.projects));
        }
    }, [profile?.projects, otherProfile?.projects, target, loaded]);

    useEffect(() => {
        if (
            // (profile || !IsThisProfile(getId(cookie[`user_id_cookie`]), profile)) && 
            // (otherProfile || !IsThisProfile(getId(cookie[`user_id_cookie`]), otherProfile))            
            otherProfile || (profile && IsThisProfile(getId(cookie[`user_id_cookie`]), profile))
        ) {
            setLoaded(true);
        }
    }, [profile, otherProfile])  

    useEffect(()=> {        
        if (!getId(cookie[`user_id_cookie`])) {
            navigate(`/?redirect=${encodeURIComponent(`/profile${id ? `/${id}` : ``}`)}`);
        } 

        if (searchParams.get('can_help_text') && searchParams.get('can_help_request_id')) {
            NewOffer({
                user_id: cookie[`user_id_cookie`],
                pair_id: searchParams.get('can_help_pair_id') ?? '',
                card_id: searchParams.get('can_help_request_id') ?? '',
                text: searchParams.get('can_help_text') ?? "",
            })
        }  

        setTarget(searchParams.get('target'));

        setWelcomePopup(searchParams.get('welcome') === `true`);

        setLeaveFunc(val => SetDict(val, [`back`], true));

        // if (searchParams.get(`first_login`) === `true`) {
        //     document.documentElement.style.overflowY = 'hidden';
        // }

        RefreshId(() => {}, () => {
            LogOut(
                encodeURIComponent(window.location.pathname ?? `/profile/home`), 
                UUIDRegExp.test(id ?? '') || UniqueIdRegExp.test(id ?? ``),
            )
        }) 

        UpdateData(cookie);
        ChatsRequest(cookie['user_id_cookie'], setChats);
        OffersRequest(cookie[`user_id_cookie`], setOffers, setRequested);
        NotificationsRequest(cookie[`user_id_cookie`], setNotifications);
        SubscriptionsRequest(cookie[`user_id_cookie`], setSubscriptions);
        const notificationTimer = setInterval(() => {
            NotificationsRequest(cookie[`user_id_cookie`], setNotifications);
        }, 180000);

        const loc = getLoc(id);
        setPageRaw(loc[0]);
        setTabRaw(loc[1]);

        if (searchParams.get(`first_login`) === `true`) {
            sessionStorage.setItem(`first_login`, `true`);
            sessionStorage.setItem(`first_login_requests`, `true`);
        }

        return () => {
            clearInterval(notificationTimer);
        }
    },[id, cookie]) 

    useEffect(() => {
        window.onpopstate = null; 
        window.onpopstate = e => {
            if (UUIDRegExp.test(e.state.pageName) || UniqueIdRegExp.test(e.state.pageName)) {
                if (e.state.pageName === cookie[`user_id_cookie`] || IsThisProfile(e.state.pageName, profile)) {
                    setPageRaw(0);
                    setTabRaw(0);
                } else {
                    navigate(`/profile/${e.state.pageName}`)
                }
            } else if (e.state.pageName) {
                const loc = getLoc(e.state.pageName);
                setPageRaw(loc[0]);
                setTabRaw(loc[1]);
            } else {
                navigate(-1);
            }
        }

        return () => {
            window.onpopstate = null;
        }
    }, [cookie])

    useEffect(() => {
        if (profile?.links?.email) {
            Smartlook.identify(profile?.id, { 
                email: profile?.links?.email, 
                uuid: profile?.id,
                linkedin: profile?.links?.linkedin,
            })

            posthog.identify(profile?.id, { 
                email: profile?.links?.email, 
                uuid: profile?.id,
                linkedin: profile?.links?.linkedin,
            })
        }
    }, [profile?.links?.email ?? null])
    
    return(<ProfileContext.Provider value={{
            setAddProjectPopup: setAddProjectPopup,
            setAddRequestPopup: setAddRequestPopup,
            setTab: setTab, setPage: setPage, setTarget: setTarget,
            setTabRaw: setTabRaw, setPageRaw: setPageRaw,
            tab: tab, page: page, target: target,
            profile: profile, setProfile: setProfile, UpdateData: () => { UpdateData(cookie) },
            ownerProfile: profile, setOwnerProfile: setProfile,
            notifications: notifications, setNotifications: setNotifications,
            setIsEdit: setIsEdit,
            recommendationsCommon: recommendationsCommon,
            setRecommendationsCommon: setRecommendationsCommon,
            setProfilePopup: setProfilePopup,
            setSuggestAFriendPopup: setSuggestAFriendPopup,
            subscriptions: subscriptions, setSubscriptions: setSubscriptions,
            setWishToHelpPopup1: setWishToHelpPopup1,
            setWishToHelpRequest1: setWishToHelpRequest1,
            globalSearch: search, setGlobalSearch: setSearch,
            newChatOffer: newChatOffer, setNewChatOffer: setNewChatOffer,
            setActivateSubscriptionPopup: setActivateSubscriptionPopup,
            isSubscriptionActive: isSubscriptionActive,
        }}>
        <div className={`body top desktop`}>
            <TopBar profile={profile} setProfile={setProfile} hideSettings={!cookie['user_id_cookie']}
                    hideNotifications={!cookie['user_id_cookie']}
                    func={() => {
                        navigate(cookie["user_id_cookie"] ? `/profile/match` : `/?nologin=true`);
                    }} toTop={false} className={`w-flex`} color={`light-gray`}/>
            
            {(IsThisProfile(getId(cookie[`user_id_cookie`]), profile) || (cookie[`user_id_cookie`] && !profile?.id)) ?      
            <div className={`w-flex`}>
                {!page && <>  
                    {tab === 0 && 
                    <ProfilePageMobile loaded={loaded}/>}

                    {!(tab - 3) && <>
                    <EditProfileMobile setShowConfirmBack={setShowConfirmLeavePopup} 
                                       profile={profile} setLeaveFunc={setLeaveFunc}
                                       setShowConfirmLeavePopup={setShowConfirmLeavePopup}/> 
                    
                    {showConfirmLeavePopup &&
                    <ConfirmPopup title={`This action can’t be undone`} subtitle={`Exit without saving changes?`} show={showConfirmLeavePopup} setShow={setShowConfirmLeavePopup} _id={`yesno`}
                                    color_2={'light-gray'} buttonTitle_2={`Cancel`} onClick_2={() => {}} mobile={true}
                                    color_1={'black'} buttonTitle_1={`Exit`} onClick_1={() => {
                                        setShowConfirmLeavePopup(false); 
                                        if (leaveFunc.back) {
                                            navigate(-1);
                                        } else {
                                            setTabRaw(leaveFunc.tab ?? 0);
                                            setPageRaw(leaveFunc.page ?? 0);
                                        }
                                    }} close={'first'}/>}

                    </>}                                         

                    {!(tab - 4) &&
                    <ProfileSettingsMobile profile={profile} setProfile={setProfile}/>}
                </>}

                {!(page - 1) &&
                <ProfileFeedMobile profile={profile} setProfile={setProfile} setTarget={setTarget}
                                   canAddRequest={canAddRequest} 
                                   recommendationsCommon={recommendationsCommon}
                                   setRecommendationsCommon={setRecommendationsCommon}/>} 

                {!(page - 2) && 
                <ProfileChatsMobile setPage={setPageRaw} profile={profile} requested={requested} setRequested={setRequested} 
                                    chats={chats} setChats={setChats} offers={offers} setOffers={setOffers}
                                    setTarget={setTarget} setLargeAvatar={setLargeAvatarPopup}/>}

                {!(page - 3) && 
                <ProfileInvitesMobile profile={profile} setProfile={setProfile}/>} 
                

                {page === 4 && 
                <ProfileRequestsMobile loaded={loaded}/>}

                {page === 5 && 
                <ProfileMatchMobile />}
                
                {addRequestPopup && isSubscriptionActive &&  
                <AddRequestPopup show={addRequestPopup} setShow={setAddRequestPopup} canClose={true}
                                onSubmit={() => {
                                    UpdateData(cookie);
                                }} onDelete={() => {
                                    UpdateData(cookie);
                                }} onClose={() => { 
                                    setAddRequestPopup(false);
                                    setEditRequest(null);
                                }} canDelete={profile?.requests?.length > 1 && editRequest}
                                headerId={`add-request-popup-content`} request={editRequest}/>}                                                                     
                    
                {addProjectPopup && isSubscriptionActive && 
                <AddProjectPopup show={addProjectPopup} setShow={setAddProjectPopup} canClose={true}
                                onSubmit={() => {
                                    UpdateData(cookie);
                                }} onDelete={() => {
                                    UpdateData(cookie);
                                }} onClose={() => { setAddProjectPopup(false) }}
                                headerId={`add-project-popup-content`}/>}

                {!(page === 0 && tab === 3) &&
                <BottomNavBar profile={profile}/>}

                {becomeAMemberPopup &&
                <PopupBecomeAMemberMobile2 show={becomeAMemberPopup} setShow={setBecomeAMemberPopup} redirect={`/profile/${id}`}/>}
                
                {updatePrivacyPopup &&
                <PopupUpdatePrivacyMobile show={updatePrivacyPopup} setShow={setUpdatePrivacyPopup}/>}
            </div> : 
            <div className={`w-flex`}> 
                <ProfileContext.Provider value={{
                    profile: otherProfile, setProfile: setOtherProfile, 
                    ownerProfile: profile, setOwnerProfile: setProfile,                     
                    recommendationsCommon: otherRecommendationsCommon,
                    setRecommendationsCommon: setOtherRecommendationsCommon,
                    setProfilePopup: setProfilePopup,
                    setSuggestAFriendPopup: setSuggestAFriendPopup,
                    notifications: notifications, setNotifications: setNotifications,
                    setWishToHelpPopup1: setWishToHelpPopup1,
                    setWishToHelpRequest1: setWishToHelpRequest1,
                    globalSearch: search, setGlobalSearch: setSearch,
                    newChatOffer: newChatOffer, setNewChatOffer: setNewChatOffer,
                    setActivateSubscriptionPopup: setActivateSubscriptionPopup,
                    isSubscriptionActive: isSubscriptionActive,
                }}> 
                    <ProfilePageMobile loaded={loaded} other={true}/>  
                </ProfileContext.Provider> 
                
                {!!cookie[`user_id_cookie`] && 
                <BottomNavBar profile={profile} other={true}/>}
            </div>}
        </div>       

        <LargeAvatar avatar={largeAvatarPopup} setAvatar={setLargeAvatarPopup}/> 

        {!!profilePopup &&
        <ProfilePopupMobile user={profilePopup} setUser={setProfilePopup} 
                            onSubscribe={() => {}}/>} 
        
        {!!newMatch &&
        <NewMatchPopupMobile show={!!newMatch} setShow={(show: boolean) => { setNewMatch((val: any) => show ? val : null) }} pair={newMatch}
                             toChat={() => { navigate(`/messenger/${newMatch?.target_id}`) }} 
                             toProfile={() => { navigate(`/profile/${newMatch?.from_user}`) }}/>}    

        {hintPopup?.show &&
        <PopupHintMobile show={hintPopup?.show} setShow={(show: boolean) => { setHintPopup(val => SetDict(val, [`show`], show)) }}
                         title={hintPopup?.title} description={hintPopup?.description} buttonTitle={hintPopup?.buttonTitle} close={hintPopup?.close}/>}     

        {wishToHelpPopup1 &&
        <PopupCanHelpMobile show={wishToHelpPopup1} setShow={setWishToHelpPopup1} mobile={true} 
                            request={wishToHelpRequest1} onRequested={(id: string) => {
                                setProfile((val: any) => SetDict(val, [`is_requested`], val.is_requested.concat([id])));
                            }}/>}    

        {activateSubscriptionPopup && 
        <PopupSubscriptionMobile show={activateSubscriptionPopup} setShow={setActivateSubscriptionPopup}/>}

        {welcomePopup && !activateSubscriptionPopup && 
        <PopupWelcomeMobile show={welcomePopup} setShow={setWelcomePopup}/>}

        {!!suggestAFriendPopup &&
        <PopupSuggestAFriendMobile show={!!suggestAFriendPopup} request={suggestAFriendPopup}
                                   setShow={(show: boolean) => { setSuggestAFriendPopup((val: any) => show ? val : null) }}/>}
              
    </ProfileContext.Provider>)
}

export {
    ProfileMobile
}